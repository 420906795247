import videojs from "video.js";

const PlaybackRateMenuButton = videojs.getComponent("PlaybackRateMenuButton");

class PlaybackRateMenuButtonDisableTap extends PlaybackRateMenuButton {
    handleClick(event) {
        const currentRate = this.player().playbackRate();
        const rates = [1, 1.5, 2];
        const currentIndex = rates.indexOf(currentRate);
        const newIndex = (currentIndex + 1) % rates.length;
        this.player().playbackRate(rates[newIndex]);
    }

    updateARIAAttributes() {}
}

export default PlaybackRateMenuButtonDisableTap;
