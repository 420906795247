import { useEffect, useState } from "react";
import { Divider, FormControl, IconButton, List, ListItemButton, ListItemText, MenuItem, Select } from "@mui/material";
import { IPostAttributes } from "@emovid/payloads-library";
import { SUPPORTED_LANGUAGES_OBJECTS } from "../../Common/Constants";
import CloseIcon from "@mui/icons-material/Close";
import Popup from "../../Common/Popup";
import HttpService from "../../Services/HttpService";

export interface ISelectLanguageProps {
    selectedLanguageObject: { key: string; value: string } | undefined;
    onSelectLanguage: Function;
    defaultOpen: boolean;
    showDefaultLabelOriginal?: boolean;
    selectedPost?: IPostAttributes;
    isUserPreference?: boolean;
}

const SelectLanguage = (props: ISelectLanguageProps) => {
    const [open, setOpen] = useState(false);
    const [languagesObjects, setLanguageObjects] = useState<Array<{ key: string; value: string }>>();
    const [moreLanguageObjects, setMoreLanguageObjects] = useState<Array<{ key: string; value: string }>>();

    useEffect(() => {
        updateLanguageObjects();
    }, []);

    const updateLanguageObjects = (selectedLanguage: string = "") => {
        let languageObjects: Array<{ key: string; value: string }> = [];
        let userData: any = null;
        if (localStorage.getItem("userData")) {
            userData = JSON.parse(localStorage.getItem("userData") || "");
        }
        // only applicable for user preferences UI or non logged in user to show the selected language at top
        if (selectedLanguage) {
            const selectedLanguageObject = SUPPORTED_LANGUAGES_OBJECTS.find(languageObject => languageObject.key === selectedLanguage);
            if (selectedLanguageObject) {
                languageObjects.push(selectedLanguageObject);
            }
        } else if (userData?.preferences?.language !== "Original") {
            // add default language if exist
            const selectedLanguageObject = SUPPORTED_LANGUAGES_OBJECTS.find(languageObject => languageObject.key === userData?.preferences?.language);
            if (selectedLanguageObject && languageObjects && !languageObjects.find(languageObject => languageObject?.key === selectedLanguageObject.key)) {
                languageObjects.push(selectedLanguageObject);
            }
        }

        // add selected translated languages
        if (userData?.preferences?.translated_languages) {
            (userData?.preferences?.translated_languages || []).forEach((language: String) => {
                const selectedLanguageObject = SUPPORTED_LANGUAGES_OBJECTS.find(languageObject => languageObject.key === language);
                if (selectedLanguageObject && languageObjects && !languageObjects.find(languageObject => languageObject?.key === selectedLanguageObject.key)) {
                    languageObjects.push(selectedLanguageObject);
                }
            });
        }

        // add supported languages if translatedlanguages are less than 10
        if (languageObjects.length < 10) {
            const remaingSupportedLanguages = SUPPORTED_LANGUAGES_OBJECTS.filter(language => language.key !== "Original").slice(0, 10 - languageObjects.length);
            remaingSupportedLanguages.forEach(language => {
                if (!languageObjects.find(languageObject => languageObject?.key === language.key)) {
                    languageObjects.push(language);
                }
            });
        }

        languageObjects = languageObjects.splice(0, 10);
        setLanguageObjects(languageObjects);
        // update more languages
        const moreLanguages = SUPPORTED_LANGUAGES_OBJECTS.filter(
            language => language.key !== "Original" && !languageObjects.find(languageObject => languageObject?.key === language.key)
        );
        setMoreLanguageObjects(moreLanguages);
    };

    const onSelectLanguage = (language: String) => {
        if (language === "More") {
            setOpen(true);
        } else {
            props.onSelectLanguage(language, props.selectedPost);
        }
    };

    const handleMoreSelectItemClick = async (selectedLanguage: string) => {
        setOpen(false);
        if (localStorage.getItem("userData") && !props.isUserPreference) {
            const userData = JSON.parse(localStorage.getItem("userData") || "");
            if (userData?.preferences?.language !== selectedLanguage) {
                // don't update if selected language is default language
                let translatedLanguages = (userData?.preferences?.translated_languages || []).filter((language: any) => language !== selectedLanguage);
                if (translatedLanguages) {
                    translatedLanguages = [selectedLanguage, ...translatedLanguages];
                    translatedLanguages = translatedLanguages.slice(0, 10); // save only 10 languages in preferences
                    await HttpService.putFormData(
                        `/v1/user/${userData?.userId}`,
                        {
                            ...userData?.preferences,
                            translated_languages: translatedLanguages
                        },
                        false,
                        true
                    );
                    userData.preferences = {
                        ...userData?.preferences,
                        translated_languages: translatedLanguages
                    };
                    localStorage.setItem("userData", JSON.stringify(userData));
                }
            }
            updateLanguageObjects();
        } else {
            updateLanguageObjects(selectedLanguage);
        }
        props.onSelectLanguage(selectedLanguage, props.selectedPost);
    };

    if (!props.selectedLanguageObject) {
        return null;
    }

    return (
        <>
            <FormControl variant="standard" sx={{ m: 0, minWidth: 120 }}>
                {languagesObjects && (
                    <Select
                        id="language-select"
                        className="language-select"
                        value={props.selectedLanguageObject?.key}
                        onChange={event => onSelectLanguage(event.target.value)}
                        defaultOpen={props.defaultOpen}
                        label="Language"
                    >
                        <MenuItem key="Original" value="Original">
                            {props.showDefaultLabelOriginal ? "Original" : "Translate"}
                        </MenuItem>
                        <Divider sx={{ marginTop: "2px !important", marginBottom: "2px !important" }} />
                        {languagesObjects.map(language => (
                            <MenuItem key={language.key} value={language.key}>
                                {language.value}
                            </MenuItem>
                        ))}
                        <Divider sx={{ marginTop: "2px !important", marginBottom: "2px !important" }} />
                        <MenuItem key="More" value="More">
                            More...
                        </MenuItem>
                    </Select>
                )}
            </FormControl>
            <Popup isOpen={open} className="more-popup" onClose={() => setOpen(false)}>
                <div className="language-list">
                    <List component="nav" aria-label="language select">
                        {moreLanguageObjects &&
                            moreLanguageObjects.map(languageSupportedObject => (
                                <ListItemButton key={languageSupportedObject?.key} onClick={() => handleMoreSelectItemClick(languageSupportedObject?.key)}>
                                    <ListItemText primary={languageSupportedObject?.value} />
                                </ListItemButton>
                            ))}
                    </List>
                </div>
                <h3 className="text-dark w-100 p-4 text-white text-center position-absolute" style={{ top: 0, left: 0, background: "#333" }}>
                    Languages
                </h3>
                <IconButton className="close-icon text-white" aria-label="Close" onClick={() => setOpen(false)} style={{ top: "25px" }}>
                    <CloseIcon sx={{ fontSize: 30 }} />
                </IconButton>
            </Popup>
        </>
    );
};

export default SelectLanguage;
