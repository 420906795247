import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CAN_REDO_COMMAND, CAN_UNDO_COMMAND, REDO_COMMAND, UNDO_COMMAND, SELECTION_CHANGE_COMMAND } from "lexical";
import { FORMAT_TEXT_COMMAND, FORMAT_ELEMENT_COMMAND, $getSelection, $isRangeSelection, $getNodeByKey, $createTextNode } from "lexical";
import { $isLinkNode, $createLinkNode, toggleLink } from "@lexical/link";
import { $isAtNodeEnd, $patchStyleText, $getSelectionStyleValueForProperty } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND, $isListNode, ListNode } from "@lexical/list";
import { createPortal } from "react-dom";
import { $isHeadingNode } from "@lexical/rich-text";
import { $isCodeNode, getDefaultCodeLanguage, getCodeLanguages } from "@lexical/code";
import { CircularProgress, Dialog, DialogTitle, DialogActions, IconButton } from "@mui/material";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import FormatListNumberedRoundedIcon from "@mui/icons-material/FormatListNumberedRounded";
import FormatSizeRoundedIcon from "@mui/icons-material/FormatSizeRounded";
import { FONT_FAMILY_OPTIONS, FONT_SIZE_OPTIONS } from "./Options";
import ColorPicker from "../ui/ColorPicker";
import { URL_MATCHER } from "./AutoLink";

const LowPriority = 1;

function Divider() {
    return <div className="divider" />;
}

function LinkEditorDialog({ isLink, currentLinkText, currentLinkUrl, onCancelClick, onSaveClick, onVisibilityChanged }) {
    const [linkText, setLinkText] = useState(currentLinkText || "");
    const [linkUrl, setLinkUrl] = useState(currentLinkUrl || "https://");
    const [isLinkInputDisabled, setIsLinkInputDisabled] = useState(false);

    useEffect(() => {
        const match = URL_MATCHER.exec(linkText);
        setIsLinkInputDisabled(match && (match[0] || {}).length);
    }, [linkText]);

    useEffect(() => {
        setTimeout(() => document.getElementById("link-input")?.focus(), 100);
    }, []);

    const saveLink = () => {
        onVisibilityChanged && onVisibilityChanged(false);
        onSaveClick && onSaveClick(linkText, linkUrl);
    };

    const cancelClick = () => {
        onVisibilityChanged && onVisibilityChanged(false);
        onCancelClick && onCancelClick();
    };

    return (
        <Dialog open={true} maxWidth="sm" fullWidth>
            <div className="d-flex justify-space-between">
                <DialogTitle>{isLink ? "Edit" : "Add"} Link</DialogTitle>
                <IconButton className="text-white position-absolute" style={{ width: "40px", right: 0, top: "10px" }} onClick={() => cancelClick()}>
                    <i className="fas fa-times"></i>
                </IconButton>
            </div>
            <div className="p-4 pt-0">
                <div className="position-relative">
                    <div style={{ minWidth: "120px", padding: "5px 0" }}>Text to display:</div>
                    <input className="form-control" value={linkText} placeholder="Enter text" onChange={event => setLinkText(event.target.value)} />
                    {!!linkText && (
                        <IconButton style={{ position: "absolute", right: 0, bottom: "2px" }} onClick={() => setLinkText("")}>
                            <CancelRoundedIcon fontSize="small" />
                        </IconButton>
                    )}
                </div>
                <div className="position-relative mt-3">
                    <div style={{ minWidth: "120px", padding: "5px 0" }}>Link to:</div>
                    <input
                        autoFocus
                        id="link-input"
                        className="form-control"
                        value={linkUrl}
                        disabled={isLinkInputDisabled}
                        placeholder="Enter web address"
                        onChange={event => setLinkUrl(event.target.value)}
                    />
                    {!!linkUrl && !isLinkInputDisabled && (
                        <IconButton style={{ position: "absolute", right: 0, bottom: "2px" }} onClick={() => setLinkUrl("")}>
                            <CancelRoundedIcon fontSize="small" />
                        </IconButton>
                    )}
                </div>
            </div>
            <DialogActions className="justify-content-between ps-4 pe-4">
                <button className="btn btn-outline-secondary pt-1 pb-1" onClick={() => cancelClick()}>
                    Cancel
                </button>
                <button className="btn btn-primary pt-1 pb-1" onClick={() => saveLink()}>
                    Save
                </button>
            </DialogActions>
        </Dialog>
    );
}

function Select({ onChange, className, options, value }) {
    return (
        <select className={className} onChange={onChange} value={value}>
            <option hidden={true} value="" />
            {options.map(option => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}

function getSelectedNode(selection) {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
        return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
    }
}

function FontDropDown({ toolbarRef, editor, setShowFontOptionsDropDown, value, fontOption, disabled = false }) {
    const dropDownRef = useRef(null);

    useEffect(() => {
        const toolbar = toolbarRef.current;
        const dropDown = dropDownRef.current;

        if (toolbar !== null && dropDown !== null) {
            const { top, left } = toolbar.getBoundingClientRect();
            dropDown.style.top = `${top + 40}px`;
            dropDown.style.left = `${left}px`;
        }
    }, [dropDownRef, toolbarRef]);

    useEffect(() => {
        const dropDown = dropDownRef.current;
        const toolbar = toolbarRef.current;

        if (dropDown !== null && toolbar !== null) {
            const handle = event => {
                const target = event.target;
                if (!dropDown.contains(target) && !toolbar.contains(target)) {
                    setShowFontOptionsDropDown(false);
                }
            };
            document.addEventListener("click", handle);
            return () => {
                document.removeEventListener("click", handle);
            };
        }
    }, [dropDownRef, setShowFontOptionsDropDown, toolbarRef]);

    const handleClick = useCallback(
        option => {
            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    $patchStyleText(selection, {
                        [fontOption]: option
                    });
                    setShowFontOptionsDropDown(false);
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "fontSize");
                }
            });
        },
        [editor, fontOption, setShowFontOptionsDropDown]
    );

    return (
        <div className="dropdown" ref={dropDownRef}>
            {(fontOption === "font-family" ? FONT_FAMILY_OPTIONS : FONT_SIZE_OPTIONS).map(option => (
                <button key={option.value} disabled={disabled} className="item" onClick={() => handleClick(option.value)}>
                    {option.icon}
                    &nbsp;
                    <span className="text">{option.name}</span>
                    {value === option.value && <span className="active" />}
                </button>
            ))}
        </div>
    );
}

export default function ToolbarPlugin(props) {
    const [editor] = useLexicalComposerContext();
    const toolbarRef = useRef(null);
    const hideFontColorOptions = props.hideFontColorOptions;
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [fontColor, setFontColor] = useState("#aaaaaa");
    const [bgColor, setBgColor] = useState("transparent");
    const [blockType, setBlockType] = useState("paragraph");
    const [selectedElementKey, setSelectedElementKey] = useState(null);
    // const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] = useState(false);
    const [showFontOptionsDropDown, setShowFontOptionsDropDown] = useState(false);
    const [fontSize, setFontSize] = useState("16px");
    const [codeLanguage, setCodeLanguage] = useState("");
    // const [isRTL, setIsRTL] = useState(false);
    const [linkText, setLinkText] = useState("");
    const [linkUrl, setLinkUrl] = useState("https://");
    const [showLinkEditorDialog, setShowLinkEditorDialog] = useState(false);
    const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isCode, setIsCode] = useState(false);

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            const element = anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow();
            const elementKey = element.getKey();
            const elementDOM = editor.getElementByKey(elementKey);
            if (elementDOM !== null) {
                setSelectedElementKey(elementKey);
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                    const type = parentList ? parentList.getTag() : element.getTag();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element) ? element.getTag() : element.getType();
                    setBlockType(type);
                    if ($isCodeNode(element)) {
                        setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
                    }
                }
            }
            // Update text format
            setIsBold(selection.hasFormat("bold"));
            setIsItalic(selection.hasFormat("italic"));
            setIsUnderline(selection.hasFormat("underline"));
            setIsStrikethrough(selection.hasFormat("strikethrough"));
            setIsCode(selection.hasFormat("code"));
            let currFontSize = $getSelectionStyleValueForProperty(selection, "font-size", "1rem");
            let selectedFontSize = "-";
            FONT_SIZE_OPTIONS.forEach(option => {
                if (currFontSize === option.value) selectedFontSize = option.name;
            });
            setFontSize(selectedFontSize);
            let currTextColor = $getSelectionStyleValueForProperty(selection, "color", "#aaaaaa");
            if (currTextColor) setFontColor(currTextColor);
            let currTextBgColor = $getSelectionStyleValueForProperty(selection, "background-color", "transparent");
            if (currTextBgColor) setBgColor(currTextBgColor);
            // setIsRTL($isParentElementRTL(selection));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
                let node = getSelectedNode(selection);
                setLinkDetailsForSelection(selection);
                let showDialog =
                    (selection?.focus?.offset === selection?.anchor?.offset && selection?.focus?.offset !== 0 && selection?.focus?.offset !== node.getTextContent().length) ||
                    selection?.focus?.offset !== selection?.anchor?.offset;
                if (showDialog) {
                    setShowLinkEditorDialog(true);
                    props.onVisibilityChanged && props.onVisibilityChanged(true);
                }
            } else {
                setIsLink(false);
            }
        }
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload, newEditor) => {
                    updateToolbar();
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                payload => {
                    setCanUndo(payload);
                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                payload => {
                    setCanRedo(payload);
                    return false;
                },
                LowPriority
            )
        );
    }, [editor, updateToolbar]);

    const codeLanguges = useMemo(() => getCodeLanguages(), []);
    const setLinkDetailsForSelection = selection => {
        let node = getSelectedNode(selection);
        const parent = node.getParent();
        if ($isLinkNode(parent)) {
            setLinkUrl(parent.getURL());
            setLinkText(parent.getTextContent().trim());
        } else if ($isLinkNode(node)) {
            setLinkUrl(node.getURL());
            setLinkText(node.getTextContent().trim());
        } else {
            setLinkText(selection.getTextContent().trim());
            setLinkUrl("https://");
        }
    };
    const onCodeLanguageSelect = useCallback(
        e => {
            editor.update(() => {
                if (selectedElementKey !== null) {
                    const node = $getNodeByKey(selectedElementKey);
                    if ($isCodeNode(node)) node.setLanguage(e.target.value);
                }
            });
        },
        [editor, selectedElementKey]
    );

    const onFontColorSelect = useCallback(
        value => {
            setFontColor(value);
            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    $patchStyleText(selection, { color: value });
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "color");
                }
            });
        },
        [editor]
    );

    const onBgColorSelect = useCallback(
        value => {
            setBgColor(value);
            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection)) {
                    $patchStyleText(selection, { "background-color": value });
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "backgroundColor");
                }
            });
        },
        [editor]
    );

    const insertLink = useCallback(() => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) setLinkDetailsForSelection(selection);
            setShowLinkEditorDialog(true);
            props.onVisibilityChanged && props.onVisibilityChanged(true);
        });
    }, [editor, isLink]);

    const formatBulletList = () => {
        if (blockType !== "ul") {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND);
        }
    };

    const formatNumberedList = () => {
        if (blockType !== "ol") {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND);
        }
    };

    const updateLink = (text, link) => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                const { anchor, focus } = selection;
                if (anchor.offset === focus.offset && !isLink) {
                    selection.insertText(text || link); // insert the link text at the current selection
                    // select the inserted text
                    anchor.offset -= text.length;
                    focus.offset = anchor.offset + text.length;
                    toggleLink(link); // transform selection into a link
                } else if (!isLink) {
                    const newLinkNode = $createLinkNode(link, { target: "_blank" });
                    newLinkNode.append($createTextNode(text || link));
                    selection.insertNodes([newLinkNode]);
                } else if (isLink && !!link) {
                    const node = getSelectedNode(selection);
                    const parent = node.getParent();
                    let linkNode = node;
                    if ($isLinkNode(parent)) linkNode = parent;
                    while (linkNode.getChildren().length > 0) {
                        const child = linkNode.getChildren()[0];
                        child.remove();
                    } // remove link node
                    const newLinkNode = $createLinkNode(link, { target: "_blank" });
                    newLinkNode.append($createTextNode(text || link));
                    selection.insertNodes([newLinkNode]);
                } else if (isLink && !!text && !link) {
                    toggleLink(null);
                }
                const node = getSelectedNode(selection);
                const parent = node.getParent();
                let linkNode = node;
                if ($isLinkNode(parent)) linkNode = parent;
                if ($isLinkNode(linkNode))
                    linkNode.getChildren().forEach(child => {
                        child.setStyle("");
                    });
                // unselect the inserted/updated text
                anchor.offset = 0;
                focus.offset = 0;
            }
            setTimeout(() => {
                setShowLinkEditorDialog(false);
                props.onVisibilityChanged && props.onVisibilityChanged(false);
            }, 200);
        });
    };

    return (
        <div className="toolbar position-relative" ref={toolbarRef}>
            {props.loading && (
                <div className="position-absolute" style={{ bottom: "10px", left: "5px", fontSize: "0.7rem", background: "black", padding: "4px 8px", borderRadius: "4px" }}>
                    <CircularProgress size="0.7rem" className="me-1" />
                    Autosaving
                </div>
            )}
            <button
                disabled={!canUndo}
                onClick={() => {
                    editor.dispatchCommand(UNDO_COMMAND);
                }}
                className="toolbar-item spaced"
                title="Undo"
                aria-label="Undo"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="toolbar-button-icon">
                    <title>Undo</title>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path>
                </svg>
            </button>
            <button
                disabled={!canRedo}
                onClick={() => {
                    editor.dispatchCommand(REDO_COMMAND);
                }}
                className="toolbar-item"
                title="Redo"
                aria-label="Redo"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="toolbar-button-icon">
                    <title>Redo</title>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3"></path>
                </svg>
            </button>
            <Divider />
            <button className="toolbar-item block-controls" onClick={() => setShowFontOptionsDropDown(!showFontOptionsDropDown)} aria-label="Font Size Options">
                <FormatSizeRoundedIcon fontSize="small" />
                <span className="text ms-2">{fontSize}</span>
                <i className="fa-solid fa-chevron-down" style={{ width: "20px", lineHeight: "20px" }} />
            </button>
            {showFontOptionsDropDown &&
                createPortal(
                    <FontDropDown
                        editor={editor}
                        toolbarRef={toolbarRef}
                        setShowFontOptionsDropDown={setShowFontOptionsDropDown}
                        fontOption={"font-size"}
                        value={fontSize}
                        disabled={props.disabled}
                    />,
                    document.body
                )}
            <Divider />
            {!hideFontColorOptions && (
                <>
                    <ColorPicker
                        buttonClassName="toolbar-item color-picker"
                        buttonAriaLabel="Formatting text color"
                        buttonIconClassName="icon color-picker-icon font-color"
                        color={fontColor}
                        onChange={onFontColorSelect}
                        onVisibilityChanged={props.onVisibilityChanged}
                        title="text color"
                    />
                    <ColorPicker
                        buttonClassName="toolbar-item color-picker"
                        buttonAriaLabel="Formatting background color"
                        buttonIconClassName="icon color-picker-icon bg-color"
                        color={bgColor}
                        onVisibilityChanged={props.onVisibilityChanged}
                        onChange={onBgColorSelect}
                        title="bg color"
                    />
                    <Divider />
                </>
            )}
            {blockType === "code" ? (
                <Select className="toolbar-item code-language" onChange={onCodeLanguageSelect} options={codeLanguges} value={codeLanguage} />
            ) : (
                <>
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                        }}
                        className={"toolbar-item spaced " + (isBold ? "active" : "")}
                        title="Format Bold"
                        aria-label="Format Bold"
                    >
                        <svg viewBox="0 0 100 100" focusable="false" role="img" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="toolbar-button-icon">
                            <title>Bold</title>
                            <path d="M62.73 49.109c5.347-1.103 9.76-5.94 9.76-12.985 0-7.553-5.517-14.428-16.295-14.428H29.011a2.604 2.604 0 0 0-2.604 2.604v51.399a2.604 2.604 0 0 0 2.604 2.604h28.118c10.863 0 16.464-6.79 16.464-15.361.001-7.043-4.752-12.9-10.863-13.833zM38.458 32.305h15.107c4.074 0 6.62 2.461 6.62 5.94 0 3.649-2.546 5.941-6.62 5.941H38.458V32.305zm15.615 35.39H38.458v-12.9h15.616c4.668 0 7.214 2.886 7.214 6.45 0 4.074-2.716 6.45-7.215 6.45z"></path>
                        </svg>
                    </button>
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                        }}
                        className={"toolbar-item spaced " + (isItalic ? "active" : "")}
                        title="Format Italics"
                        aria-label="Format Italics"
                    >
                        <svg viewBox="0 0 100 100" focusable="false" role="img" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="toolbar-button-icon">
                            <title>Italic</title>
                            <path d="M60.571 24.301a2.604 2.604 0 0 0-2.604-2.604h-4.594a2.598 2.598 0 0 0-2.59 2.463l-.014-.001-11.276 50.978-.015.066-.011.048h.006a2.55 2.55 0 0 0-.045.449 2.595 2.595 0 0 0 2.406 2.584v.02h4.792a2.595 2.595 0 0 0 2.577-2.336l.013.001 11.257-50.972-.008-.001a2.58 2.58 0 0 0 .106-.695z"></path>
                        </svg>
                    </button>
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                        }}
                        className={"toolbar-item spaced " + (isUnderline ? "active" : "")}
                        title="Format Underline"
                        aria-label="Format Underline"
                    >
                        <svg viewBox="0 0 100 100" focusable="false" role="img" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="toolbar-button-icon">
                            <title>Underline</title>
                            <path d="M77.5 75.545c-.036 0-.068.009-.103.01v-.01h-55v.01c-1.608.056-2.897 1.368-2.897 2.99s1.288 2.934 2.897 2.99v.01h55v-.01c.035.001.068.01.103.01a3 3 0 0 0 0-6zM50 72.12c15.829 0 23.581-9.057 23.581-22.521V21.383a2.928 2.928 0 0 0-2.929-2.928h-3.864a2.928 2.928 0 0 0-2.929 2.928c0 .04.01.076.012.116v27.856c0 8.649-4.814 14.28-13.871 14.28s-13.871-5.631-13.871-14.28V21.49c.001-.036.011-.071.011-.107a2.928 2.928 0 0 0-2.928-2.928h-3.865a2.929 2.929 0 0 0-2.929 2.928v28.216c0 13.464 7.834 22.521 23.582 22.521z"></path>
                        </svg>
                    </button>
                    {!(props.options || {}).hideStrikeThrough && (
                        <button
                            onClick={() => {
                                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
                            }}
                            className={"toolbar-item spaced " + (isStrikethrough ? "active" : "")}
                            title="Format Strikethrough"
                            aria-label="Format Strikethrough"
                        >
                            <svg viewBox="0 0 100 100" focusable="false" role="img" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="toolbar-button-icon">
                                <title>Strikethrough</title>
                                <path d="M77.5 49.719c-.035 0-.068.009-.103.01v-.01h-55v.01a2.995 2.995 0 0 0-2.897 2.99 2.996 2.996 0 0 0 2.897 2.99v.01h55v-.01c.035.001.068.01.103.01a3 3 0 1 0 0-6zm-6.572 9.75h-7.14v.011c-.035-.002-.069-.011-.105-.011-.863 0-1.562.699-1.562 1.562 0 .126.019.247.047.365h-.018c.092.393.157.802.157 1.249 0 3.819-3.14 7.808-11.288 7.808-7.741 0-13.842-3.592-17.678-7.653a1.555 1.555 0 0 0-1.237-.617 1.56 1.56 0 0 0-1.275.664l-.001-.002-.01.015a.48.48 0 0 0-.019.029l-3.425 5.212.003.001a1.55 1.55 0 0 0-.398 1.033c0 .515.253.969.637 1.253 5.091 5.205 12.61 8.891 22.978 8.891 15.191 0 21.896-8.147 21.896-17.568 0-.172-.011-.335-.018-.501.007-.06.018-.118.018-.179 0-.863-.699-1.562-1.562-1.562zm-39.06-13.792c.269.471.77.792 1.351.792h23.542a.502.502 0 0 0 0-1.004v-.008c-8.471-2.48-17.2-3.403-17.2-8.866 0-4.159 3.734-7.044 9.505-7.044 5.941 0 11.967 2.037 16.465 6.535l.006-.008c.272.231.62.375 1.005.375.491 0 .923-.231 1.21-.584l.002.003.028-.039c.028-.037.056-.074.081-.114l3.409-4.788-.003-.001a1.55 1.55 0 0 0 .398-1.033c0-.473-.215-.892-.547-1.178l.011-.015C65.956 23.606 58.742 20.72 50 20.72c-12.476 0-20.623 7.214-20.623 16.634 0 3.499.939 6.195 2.491 8.323z"></path>
                            </svg>
                        </button>
                    )}
                    {!(props.options || {}).hideHyperlink && (
                        <button onClick={insertLink} className={"toolbar-item spaced " + (isLink ? "active" : "")} aria-label="Insert Link">
                            <svg viewBox="0 0 24 24" focusable="false" role="img" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="toolbar-button-icon">
                                <title>Link</title>
                                <path d="m17.657 14.828-1.414-1.414L17.657 12A4 4 0 1 0 12 6.343l-1.414 1.414-1.414-1.414 1.414-1.414a6 6 0 0 1 8.485 8.485l-1.414 1.414zm-2.829 2.829-1.414 1.414a6 6 0 1 1-8.485-8.485l1.414-1.414 1.414 1.414L6.343 12A4 4 0 1 0 12 17.657l1.414-1.414 1.414 1.414zm0-9.9 1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z"></path>
                            </svg>
                        </button>
                    )}
                    {showLinkEditorDialog && (
                        <LinkEditorDialog
                            isLink={isLink}
                            currentLinkText={linkText}
                            currentLinkUrl={linkUrl}
                            onCancelClick={() => setShowLinkEditorDialog(false)}
                            onSaveClick={(text, link) => updateLink(text, link)}
                            onVisibilityChanged={props.onVisibilityChanged}
                        />
                    )}
                    {!(props.options || {}).hideInlineCode && (
                        <button
                            onClick={() => {
                                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
                            }}
                            className={"toolbar-item spaced " + (isCode ? "active" : "")}
                            title="Insert Code"
                            aria-label="Insert Code"
                        >
                            <svg viewBox="0 0 16 16" focusable="false" role="img" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="toolbar-button-icon">
                                <title>Inline Code</title>
                                <path d="M5.854 4.854a.5.5 0 1 0-.708-.708l-3.5 3.5a.5.5 0 0 0 0 .708l3.5 3.5a.5.5 0 0 0 .708-.708L2.707 8l3.147-3.146zm4.292 0a.5.5 0 0 1 .708-.708l3.5 3.5a.5.5 0 0 1 0 .708l-3.5 3.5a.5.5 0 0 1-.708-.708L13.293 8l-3.147-3.146z"></path>
                            </svg>
                        </button>
                    )}
                    <Divider />
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
                        }}
                        className="toolbar-item spaced"
                        title="Left Align"
                        aria-label="Left Align"
                    >
                        <i className="fa-solid fa-align-left" style={{ width: "20px", lineHeight: "20px" }} />
                    </button>
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
                        }}
                        className="toolbar-item spaced"
                        title="Center Align"
                        aria-label="Center Align"
                    >
                        <i className="fa-solid fa-align-center" style={{ width: "20px", lineHeight: "20px" }} />
                    </button>
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
                        }}
                        className="toolbar-item spaced"
                        title="Right Align"
                        aria-label="Right Align"
                    >
                        <i className="fa-solid fa-align-right" style={{ width: "20px", lineHeight: "20px" }} />
                    </button>
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
                        }}
                        className="toolbar-item"
                        title="Justify Align"
                        aria-label="Justify Align"
                    >
                        <i className="fa-solid fa-align-justify" style={{ width: "20px", lineHeight: "20px" }} />
                    </button>
                    <Divider />
                    <button onClick={formatBulletList} className={"toolbar-item spaced " + (blockType === "ul" ? "active" : "")} title="Bullet List" aria-label="Bullet List">
                        <FormatListBulletedRoundedIcon fontSize="small" />
                    </button>
                    <button onClick={formatNumberedList} className={"toolbar-item " + (blockType === "ol" ? "active" : "")} title="Numbered List" aria-label="Numbered List">
                        <FormatListNumberedRoundedIcon fontSize="small" />
                    </button>
                </>
            )}
        </div>
    );
}
