import videojs from "video.js";

const FullscreenToggle = videojs.getComponent("FullscreenToggle");

class CustomFullScreenToggle extends FullscreenToggle {
    handleClick(event) {
        this.player().trigger("fullscreenToggle");
    }
}

export default CustomFullScreenToggle;
