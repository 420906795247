import { FC } from "react";
import { PrivacySettingsState } from "./ViewEmovid";
import { useNavigate } from "react-router";
import { Paths } from "../../Common/AppRoutes";

interface UnauthorizeVideoProps {
    privacySettingsState: PrivacySettingsState;
    switchAccounts: any;
    showLoginPopup?: any;
}

const UnauthorizeVideo: FC<UnauthorizeVideoProps> = ({ privacySettingsState, switchAccounts, showLoginPopup }: UnauthorizeVideoProps) => {
    const navigate = useNavigate();

    return (
        <div className="container p-4">
            {privacySettingsState && privacySettingsState === PrivacySettingsState.RECIPIENTS_CAN_VIEW_POST_LOGGED_IN && (
                <>
                    <div style={{ marginTop: "7rem" }} className="text-center">
                        <div className="alert-icon w-100 p-4 d-flex justify-content-center mb-3">
                            <img className="icon" src="/icons/alert.svg" alt="alert" />
                        </div>
                        <div className="fw-bold mb-4 text-center">This account is not authorized to view this video.</div>
                        <div className="mb-4 text-center">Please contact the sender to be added.</div>
                    </div>
                    <div className="mt-4 d-flex justify-content-center">
                        <button className="btn btn-outline-primary text-white mr-3" data-tip="Cancel" onClick={() => navigate(Paths.myInbox)}>
                            Cancel
                        </button>
                        <button className="btn btn-primary text-white ml-3" data-tip="Switch accounts" onClick={() => switchAccounts()}>
                            Switch Accounts
                        </button>
                    </div>
                </>
            )}
            {privacySettingsState && privacySettingsState !== PrivacySettingsState.RECIPIENTS_CAN_VIEW_POST_LOGGED_IN && (
                <>
                    <div style={{ marginTop: "7rem" }} className="text-center">
                        <div className="alert-icon w-100 p-4 d-flex justify-content-center mb-3">
                            <img className="icon" src="/icons/alert.svg" alt="alert" />
                        </div>
                        <div className="fw-bold mb-4 text-center">The Emovid (thread) is only accessible to logged-in users. </div>
                    </div>
                    <div className="mt-4 d-flex justify-content-center">
                        <button className="btn btn-primary text-white ml-3" data-tip="Login" onClick={showLoginPopup}>
                            Login
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default UnauthorizeVideo;
