import { $createListItemNode, ListItemNode, SerializedListItemNode } from "@lexical/list";
import { DOMConversionMap, DOMConversionOutput, ElementFormatType, isHTMLElement } from "lexical";

export class ExtendedListItemNode extends ListItemNode {
    static getType(): string {
        return "extended-listitem";
    }

    static clone(node: ExtendedListItemNode): ExtendedListItemNode {
        return new ExtendedListItemNode(node.__value, node.__checked, node.__key);
    }

    static importDOM(): DOMConversionMap | null {
        const importers = ListItemNode.importDOM();
        return {
            ...importers,
            li: () => ({
                conversion: convertListItemElement,
                priority: 1
            })
        };
    }

    static importJSON(serializedNode: SerializedListItemNode): ListItemNode {
        return ListItemNode.importJSON(serializedNode);
    }

    exportJSON(): SerializedListItemNode {
        return {
            ...super.exportJSON(),
            type: "extended-listitem",
            version: 1
        };
    }
}

function convertListItemElement(domNode: HTMLElement): DOMConversionOutput {
    const checked = isHTMLElement(domNode) && domNode.getAttribute("aria-checked") === "true";
    let newNode = $createListItemNode(checked);
    const textAlign = domNode.style.textAlign;
    newNode.setFormat(textAlign as ElementFormatType);
    return { node: newNode };
}
