import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import KeyboardDoubleArrowUpRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowUpRounded";

export const FONT_FAMILY_OPTIONS = [
    ["Arial", "Arial"],
    ["Courier New", "Courier New"],
    ["Georgia", "Georgia"],
    ["Times New Roman", "Times New Roman"],
    ["Trebuchet MS", "Trebuchet MS"],
    ["Verdana", "Verdana"]
];

export const FONT_SIZE_OPTIONS = [
    // Using `em` instead of `rem` to be able to scale text in teleprompter, always set rem font size of parent when using html as-is
    { name: "Largest", value: "1.875em", icon: <KeyboardDoubleArrowUpRoundedIcon /> },
    { name: "Larger", value: "1.5em", icon: <KeyboardArrowUpRoundedIcon /> },
    { name: "Large", value: "1.25em", icon: <KeyboardArrowUpRoundedIcon /> },
    { name: "Normal", value: "1em", icon: <RemoveRoundedIcon /> }
    // { name: "Small", value: "0.875em", icon: <KeyboardArrowDownRoundedIcon /> },
    // { name: "Smaller", value: "0.75em", icon: <KeyboardArrowDownRoundedIcon /> },
    // { name: "Smallest", value: "0.625em", icon: <KeyboardDoubleArrowDownRoundedIcon /> }

    // ["0.625em", "10px"],
    // ["0.75em", "12px"],
    // ["0.875em", "14px"],
    // ["1em", "16px"],
    // ["1.125em", "18px"],
    // ["1.25em", "Large"],
    // ["1.375em", "22px"],
    // ["1.5em", "24px"],
    // ["1.625em", "26px"],
    // ["1.75em", "28px"],
    // ["1.875em", "30px"]
];
