import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Paths, RoutesWithFooterEnabled } from "./AppRoutes";

interface FooterProps {
    children?: React.ReactNode;
    version?: string;
}

const Links = styled.div`
    display: flex;
    font-size: 14px;
    justify-content: center;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: white;
    :hover {
        text-decoration: underline;
        color: white;
    }
`;

const Footer: FC<FooterProps> = (props: FooterProps) => {
    let location = useLocation();
    const [showFooter, setShowFooter] = useState<boolean>(true);

    useEffect(() => {
        setShowFooter(RoutesWithFooterEnabled.includes(location.pathname));
    }, [location]);

    if (showFooter)
        return (
            <footer className="footer bg-dark">
                <div className="row container m-auto pt-5 pb-5">
                    <div className={"col-md-8 col-sm-12 text-white" + (isMobile ? "" : " pt-5 ")}>
                        <div className="d-flex">
                            <img className="logo-footer" alt="" src="images/logo-footer.svg" />
                            <div className="vertical-divider ms-3 me-3"></div>
                            <div className="footer-logo-text">Video messaging for business is finally here</div>
                        </div>
                    </div>
                    {/* <div className="col-md-4 col-sm-12">
                    <h3 className="text-primary text-large mb-3">Join our newsletter</h3>
                    <div className="text-white mb-2">Get the latest Emovid news and updates</div>
                    <div className="d-flex">
                        <input type="email" className="form-control unset-border-radius" placeholder="Enter your email" />
                        <button className="btn btn-primary ms-2">Submit</button>
                    </div>
                    {authContext?.user && (
                        <div className="d-flex mt-3">
                            <button className="btn btn-link">
                                <img className="social-btn-icon" alt="" src="images/facebook-icon.svg" />
                            </button>
                            <button className="btn btn-link">
                                <img className="social-btn-icon" alt="" src="images/instagram-icon.svg" />
                            </button>
                            <button className="btn btn-link">
                                <img className="social-btn-icon" alt="" src="images/twitter-icon.svg" />
                            </button>
                            <button className="btn btn-link">
                                <img className="social-btn-icon" alt="" src="images/linkedin-icon.svg" />
                            </button>
                        </div>
                    )}
                </div> */}
                </div>
                <div className="text-white pt-2 pb-2 text-small text-center">Copyright © 2024 Emovid Inc., All Rights Reserved.</div>
                <Links>
                    <div className="p-1 text-small">
                        <StyledLink to={Paths.privacyPolicy}>Privacy</StyledLink>
                    </div>
                    <div className="p-1 ml-3 text-small">
                        <StyledLink to={Paths.terms}>Terms</StyledLink>
                    </div>
                    <div className="p-1 ml-3 text-small">
                        <StyledLink to={Paths.getSupport}>Support</StyledLink>
                    </div>
                </Links>
                <div className="p-1 pb-3 text-small text-center">{props.version}</div>
            </footer>
        );
    else return null;
};

export default Footer;
