import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ListItemIcon, MenuItem } from "@mui/material";
import { Paths, replaceEventIdInPath } from "./AppRoutes";
import HttpService from "../Services/HttpService";
import ToastService from "./ToastService";
import CopyIcon from "../Icons/CopyIcon";
import { ICreatedEvents } from "../Interfaces/ICreatedEvents";

interface CopyEmovidMenuButtonProps {
    postForMenu: ICreatedEvents;
    onLoadingChange?: Function;
    onClick: Function;
}

const CopyEmovidMenuButton: FC<CopyEmovidMenuButtonProps> = ({ postForMenu, onClick, onLoadingChange }: CopyEmovidMenuButtonProps) => {
    const navigate = useNavigate();

    const copyEmovidClicked = async () => {
        onClick && onClick();
        onLoadingChange && onLoadingChange(true);
        try {
            let { event } = await HttpService.postFormData(`/v1/event/copy`, { postId: postForMenu.post_id });
            onLoadingChange && onLoadingChange(false);
            navigate(replaceEventIdInPath(Paths.preview, event.id));
        } catch (error: any) {
            onLoadingChange && onLoadingChange(false);
            ToastService.alertableError(error.message || "Error creating new Emovid", error);
        }
    };

    return (
        <>
            <MenuItem onClick={copyEmovidClicked}>
                <ListItemIcon>
                    <CopyIcon />
                </ListItemIcon>
                Make a Copy
            </MenuItem>
        </>
    );
};

export default CopyEmovidMenuButton;
