import { FC, useState, useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { IEventAttributes, IMemberAttributes, IPostAttributes, IPostUrl, PostState, PostStatus } from "@emovid/payloads-library";
import { CircularProgress, ClickAwayListener, Dialog, IconButton, LinearProgress, Skeleton, Switch, Tooltip } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { useParams } from "react-router";
import { isDesktop, isIOS, isMobile, isTablet } from "react-device-detect";
import { Paths, replaceEventIdInPath } from "../../Common/AppRoutes";
import ToastService from "../../Common/ToastService";
import VideoPlayer from "../../VideoLib/Components/VideoPlayer";
import { deleteVideo, getEventById, logError, nameForUser, pushTagEvent, updatePostById, postShared, getHTMLForSharing } from "../../Common/Helper";
import { getUserDetails, setStorageItem } from "../../Common/Helper";
import ViewEmovid, { EmailRecipientUserDetailsStorageKey, EmailRecipientWhitelistUserStorageKey } from "../ViewEmovid/ViewEmovid";
import Progress from "../../Common/Progress";
import HttpService from "../../Services/HttpService";
import NavBar from "../Nav/NavBar";
import { AuthContext } from "../../Contexts/AuthContext";
import Editor from "../../Common/Editor/Editor";
import EmovidRecipients from "./EmovidRecipients";
import ConfirmDialog from "../../Common/ConfirmDialog";
import { CopyOptionProps, SHARE_OPTIONS } from "../Dashboard/ShareEventDialog";
import ShareInfo from "./ShareInfo";
import ShareIconsStrip from "./ShareIconsStrip";
import { Desktop, Mobile } from "../../Common/Responsive";
import InfoTooltip from "../../Common/InfoTooltip";

export enum PrivacySetting {
    ORIGINAL_RECIPIENTS_CAN_VIEW = "ORIGINAL_RECIPIENTS_CAN_VIEW",
    SIGNED_IN_USERS_CAN_VIEW = "SIGNED_IN_USERS_CAN_VIEW",
    PRIVATE_REPLIES = "PRIVATE_REPLIES"
}

interface CreateEmovidProps {}

const CreateEmovid: FC<CreateEmovidProps> = (props: CreateEmovidProps) => {
    const { eventId } = useParams();
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const [eventDataFetched, setEventDataFetched] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [copyInProgress, setCopyInProgress] = useState<boolean>(false);
    const [emovidTitle, setEmovidTitle] = useState<string>("");
    const [defaultEmovidTitle, setDefaultEmovidTitle] = useState<string>("");
    const [event, setEvent] = useState<IEventAttributes>();
    const [creatorPost, setCreatorPost] = useState<IPostAttributes>();
    const [emailEditMode, setEmailEditMode] = useState<boolean>(false);
    const [showEmovidPreview, setShowEmovidPreview] = useState<boolean>(false);
    const [toEmails, setToEmails] = useState<IMemberAttributes[]>([]);
    const [videoUrl, setVideoUrl] = useState<string>("");
    const [thumbnailUrl, setThumbnailUrl] = useState<string>("");
    const [creatorFirstName, setCreatorFirstName] = useState<string>("");
    const [eventNote, setEventNote] = useState<string>("");
    const [eventUrl, setEventUrl] = useState<string>("");
    const [optionsDrawerOpen, setOptionsDrawerOpen] = useState<boolean>(false);
    const [originalRecipientsCanView, setOriginalRecipientsCanView] = useState<boolean>(false);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [duration, setDuration] = useState<string>("");
    const [signedInUsersCanView, setSignedInUsersCanView] = useState<boolean>(false);
    const [privateReplies, setPrivateReplies] = useState<boolean>(false);
    const [showRetakeConfirmation, setShowRetakeConfirmation] = useState<boolean>(false);
    const [hideCreateEmovidButton, setHideCreateEmovidButton] = useState<boolean>(true);

    const { refetch } = useQuery({
        queryKey: ["event"],
        refetchOnWindowFocus: false,
        retry: false,
        queryFn: () => getEventById(eventId!),
        onError: (error: Error) => {
            ToastService.alertableError(error.message || "Invalid event", error);
            logError("CreateEmovid.tsx useQuery getEventById", error);
        },
        onSuccess: (data: IEventAttributes) => {
            if (!eventUrl) generateShortURL();
            setupFromEventData(data);
        }
    });

    useEffect(() => {
        if (!eventDataFetched) return;
        if (!creatorPost?.id) return;
        const updateRequest = setTimeout(() => {
            setLoading(true);
            HttpService.putFormData(`/v1/post/update/${creatorPost?.id}/notes`, { note: eventNote }, true)
                .then(data => {
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    ToastService.alertableError(error.message, error);
                });
        }, 700);
        return () => clearTimeout(updateRequest);
    }, [eventNote, creatorPost?.id]);

    useEffect(() => {
        if (!eventDataFetched) return;
        const updateRequest = setTimeout(() => {
            setLoading(true);
            HttpService.postFormData(`/v1/event/${eventId}/update/title`, { title: emovidTitle }, false, true)
                .then(data => {
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    ToastService.alertableError(error.message || "Unable to update emovid title", error);
                });
        }, 700);
        return () => clearTimeout(updateRequest);
    }, [emovidTitle, eventId]);

    const generateDefaultTitle = (data: IEventAttributes) => {
        if (data?.title) {
            // set default title received in email subject
            setDefaultEmovidTitle(data?.title);
            setEmovidTitle(data?.title);
        } else {
            // generate title from creator name and number of recipients if any
            let title = `${nameForUser(data?.Creator!)} has sent you a video message`;
            const numberOfMembers = (data?.Members || []).length;
            if (numberOfMembers > 1) {
                title = `${nameForUser(data?.Creator!)} sent a video message to you and ${numberOfMembers - 1} ${numberOfMembers - 1 === 1 ? "recipient" : "recipients"}`;
            }
            setDefaultEmovidTitle(title);
            setEmovidTitle(title);
            HttpService.postFormData(`/v1/event/${eventId}/update/title`, { title }, false, true)
                .then(data => {})
                .catch(error => {});
        }
    };

    const setupFromEventData = (data: IEventAttributes) => {
        setEvent(data);
        // prevent going to this screen if the event is already sent
        if (data?.event_status === "SENT") {
            navigate(replaceEventIdInPath(Paths.viewEmovid, eventId!));
        } else {
            if (authContext.user?.userId && data.creator_id !== authContext.user?.userId) {
                authContext.signOut(); // SE-504, email link user and logged in user do not match
            }
            setupMembers(data?.Members || []);
            generateDefaultTitle(data);
            setCreatorFirstName(data?.Creator?.name?.split(" ")[0] || "");
            let creatorPostData = (data?.CreatorPosts || [])[0];
            if (!creatorPost) {
                setCreatorPost(creatorPostData);
                pushTagEvent("e_preview_video", {
                    event_id: eventId,
                    post_id: creatorPostData.id,
                    record_flow_type: "new"
                });
                if (creatorPostData.post_status && ![PostStatus.DRAFT_PREVIEW, PostStatus.DRAFT_SHARABLE_LINK].includes(creatorPostData.post_status))
                    HttpService.postFormData(`/v1/post/${creatorPostData?.id}/update`, { post_status: PostStatus.DRAFT_PREVIEW }, false, true); // update status
                if (!videoUrl) {
                    const videoUrlTemp: IPostUrl | undefined = (creatorPostData?.urls || []).find((url: IPostUrl) => url.url_type === "FILE");
                    if (videoUrlTemp) {
                        setVideoUrl(videoUrlTemp.url ? videoUrlTemp.url : "");
                        setDuration(videoUrlTemp.metadata?.duration?.toString() || "");
                    }
                    const thumbnailUrlTemp: IPostUrl | undefined = (creatorPostData?.urls || []).find((url: IPostUrl) => url.url_type === "THUMBNAIL");
                    if (thumbnailUrlTemp) setThumbnailUrl(thumbnailUrlTemp.url ? thumbnailUrlTemp.url : "");
                }
                setEventNote(creatorPostData?.note || "");
                if (!emailEditMode && !isIOS) {
                    setTimeout(() => document.getElementById("title-input")?.focus(), 500);
                }
                setOriginalRecipientsCanView(creatorPostData?.PrivacySettings?.recipients_can_view_post || false);
                setSignedInUsersCanView(creatorPostData?.PrivacySettings?.signed_in_users_can_view_post || false);
                setPrivateReplies(creatorPostData?.PrivacySettings?.keep_replies_private || false);
                setTimeout(() => setEventDataFetched(true), 500);
                getCreatorWhitelistStatus(data.creator_id || "");
            }
            if (!isTranscriptGenerated(creatorPostData)) setTimeout(() => refetch(), 3000);
            else setCreatorPost(creatorPostData);
        }
    };

    const getCreatorWhitelistStatus = async (creatorId: string) => {
        let creatorDetails = await getUserDetails(creatorId);
        setStorageItem(EmailRecipientUserDetailsStorageKey, JSON.stringify(creatorDetails));
        const whitelistResponse = await HttpService.getWithAPIKey(`/v1/whitelisted/status/${creatorDetails.email}`);
        setStorageItem(EmailRecipientWhitelistUserStorageKey, whitelistResponse.is_whitelisted ? "true" : "false");
    };

    const showLockIcon = (): boolean => {
        return originalRecipientsCanView || signedInUsersCanView || privateReplies;
    };

    const setupMembers = (members: IMemberAttributes[]) => {
        setToEmails((members || []).filter((member: IMemberAttributes) => member.member_type === "TO"));
    };

    const handleStepClick = (step: number) => {
        if (step === 1) {
            if (videoUrl) setShowRetakeConfirmation(true);
            else navigate(replaceEventIdInPath(Paths.recordEmovid, eventId!));
        }
        if (step === 2) navigate(replaceEventIdInPath(Paths.editEmovidVideo, eventId!)); // step 3 to step 2
    };

    const emovidViewPath = (queryParams = {}): string => {
        return `${window.location.origin}${replaceEventIdInPath(Paths.viewEmovid, eventId!)}?${new URLSearchParams(queryParams).toString()}`;
    };

    const generateShortURL = async () => {
        try {
            const shortUrlResponse = await HttpService.postFormData(`/v1/post/short/url`, { url: emovidViewPath() }, false, true);
            setEventUrl(shortUrlResponse.short_url);
        } catch (error) {
            console.error("Error generating short url: ", error);
            setEventUrl(emovidViewPath());
        }
    };

    const submitEmovid = async () => {
        if (emailEditMode) return; // btn has disabled look but clicks allowed
        if (emovidHasErrors()) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }
        setLoading(true);
        try {
            // update status to SENT
            await HttpService.putFormData(`/v1/event/update/${eventId}/status`, { status: "SENT" }, true);
            await HttpService.postFormData(`/v1/post/${creatorPost?.id}/update`, { is_sent: true }, false, true); // update is_sent
            // send emails
            await HttpService.postFormData(`/v1/event/notification/${eventId}/contributors`, null, false, true);
            pushTagEvent("e_share_email_sent", {
                event_id: eventId,
                post_id: creatorPost?.id,
                num_recipients: event?.Members?.length,
                sec_only_original: originalRecipientsCanView ? "on" : "off",
                sec_only_signed_in: signedInUsersCanView ? "on" : "off",
                sec_req_password: "off",
                sec_delete_after: "off"
            });
            navigate(replaceEventIdInPath(Paths.sent, eventId!));
        } catch (error: any) {
            setLoading(false);
            ToastService.alertableError(error.message || "Something went wrong while processing", error);
        }
    };

    const emovidHasErrors = (): boolean => {
        if (!emovidTitle) {
            ToastService.userError("Please provide a subject/title for this Emovid");
            return true;
        }
        if (emovidTitle.length > 255) {
            setEmovidTitle(emovidTitle.slice(0, 255));
            ToastService.userError("Please limit the subject to 255 characters");
            return true;
        }
        if (!videoUrl) {
            ToastService.userError("Please record a video before sending Emovid");
            return true;
        }
        const creatorPost = (event?.CreatorPosts || [])[0];
        if (toEmails.length === 0 && !creatorPost.send_copy) {
            ToastService.userError("Please add at least one recipient in the To field");
            return true;
        }
        return false;
    };

    const sendCopyToSelfChanged = (newValue: boolean) => {
        const creatorPost = (event?.CreatorPosts || [])[0];
        creatorPost.send_copy = newValue;
    };

    const titleChanged = (e: any) => {
        let currentTitle = e.target.value;
        if (currentTitle.length > 255) {
            currentTitle = currentTitle.slice(0, 255);
            setEmovidTitle(currentTitle);
            e.target.value = currentTitle;
            ToastService.userError("Please limit the subject to 255 characters");
        } else setEmovidTitle(currentTitle);
    };

    const updatePrivacySettings = (privacySetting: PrivacySetting, value: boolean) => {
        setLoading(true);
        let signedInUsersCanViewPost = signedInUsersCanView,
            recipientsCanViewPost = originalRecipientsCanView,
            privateRepliesSetting = privateReplies;
        if (privacySetting === PrivacySetting.ORIGINAL_RECIPIENTS_CAN_VIEW) {
            signedInUsersCanViewPost = value;
            recipientsCanViewPost = value;
            setSignedInUsersCanView(signedInUsersCanViewPost);
            setOriginalRecipientsCanView(value);
        } else if (privacySetting === PrivacySetting.SIGNED_IN_USERS_CAN_VIEW) {
            signedInUsersCanViewPost = value;
            setSignedInUsersCanView(signedInUsersCanViewPost);
        } else if (privacySetting === PrivacySetting.PRIVATE_REPLIES) {
            privateRepliesSetting = value;
            setPrivateReplies(privateRepliesSetting);
        }
        HttpService.putFormData(
            `/v1/post/${creatorPost?.id}/privacy/settings`,
            {
                recipients_can_view_post: recipientsCanViewPost,
                signed_in_users_can_view_post: signedInUsersCanViewPost,
                keep_replies_private: privateRepliesSetting
            },
            true,
            true
        )
            .then(() => {
                pushTagEvent("e_share_security_toggle", {
                    event_id: eventId,
                    post_id: creatorPost?.id,
                    security_option: privacySetting === PrivacySetting.ORIGINAL_RECIPIENTS_CAN_VIEW ? "only_email" : "only_signed_in",
                    security_option_value: value ? "on" : "off"
                });
                setLoading(false);
            })
            .catch(error => {
                ToastService.alertableError(error.message || "Unable to update privacy settings", error);
                setLoading(false);
            });
    };

    const noteChanged = (noteHtml: string) => {
        setEventNote(noteHtml);
    };

    const retakeVideoConfirmed = async () => {
        try {
            let videoKey = new URL(videoUrl).pathname.substring(1);
            await deleteVideo(videoKey);
            let postStatus = PostStatus.DRAFT;
            if (creatorPost?.ai_prompt || creatorPost?.ai_suggestions || creatorPost?.my_notes) postStatus = PostStatus.DRAFT_SPEAKING_NOTES;
            await HttpService.postFormData(
                `/v1/post/${creatorPost?.id}/update`,
                { original_urls: null, urls: [], post_status: postStatus, states: null, hls_metadata: null },
                false,
                true
            ); // update message
            navigate(replaceEventIdInPath(Paths.recordEmovid, eventId!));
        } catch (exception) {} // fail silently
    };

    const cancelEmovidEditConfirmed = () => {
        pushTagEvent("e_video_draft_saved", {
            event_id: eventId,
            post_id: creatorPost?.id,
            record_flow_type: "new"
        });
        navigate(authContext.user ? Paths.createdVideos : Paths.login);
    };

    const onTitleFocus = () => {
        setEmailEditMode(false);
    };

    const getHTML = async (url: any) => {
        const html = await getHTMLForSharing(
            url,
            originalRecipientsCanView || signedInUsersCanView,
            eventUrl,
            emovidViewPath({ show_post: creatorPost?.id, scroll_to: "transcript" }),
            duration,
            creatorPost?.ai_summary || "",
            creatorFirstName || "",
            eventNote ? emovidViewPath({ show_post: creatorPost?.id, scroll_to: "notes" }) : ""
        );
        return new Blob([html], { type: "text/html" });
    };

    const shareLinkCopyEvent = (asHtml: boolean, linkType: string) => {
        pushTagEvent("e_share_link_copied", {
            event_id: eventId,
            post_id: creatorPost?.id,
            sec_only_email: originalRecipientsCanView ? "on" : "off",
            sec_only_signed_in: signedInUsersCanView ? "on" : "off",
            link_type: linkType,
            as_html: asHtml
        });
    };

    const copyHTML = async () => {
        setShowOptions(true);
        setCopyInProgress(true);
        const emailThumbnailUrl: IPostUrl | undefined = (creatorPost?.urls || []).find((url: IPostUrl) => url.url_type === "EMAIL_THUMBNAIL");
        if (emailThumbnailUrl) {
            try {
                await navigator.clipboard.write([new ClipboardItem({ "text/html": getHTML(emailThumbnailUrl?.url) })]);
                setHideCreateEmovidButton(false);
                if (authContext.user?.userId) postShared(authContext.user?.userId, creatorPost?.id || "", parseInt(duration || "0"), "email");
                ToastService.success("Copied for Email!");
            } catch (error) {
                console.log("Error: ", error);
            }
            try {
                shareLinkCopyEvent(true, SHARE_OPTIONS[0].id);
            } catch (error) {
                console.error("Failed to track share link copy event:", error);
            }
            if (creatorPost?.id && creatorPost.post_status !== PostStatus.DRAFT_SHARABLE_LINK) updatePostById(creatorPost.id, { post_status: PostStatus.DRAFT_SHARABLE_LINK });
        } else copyText(SHARE_OPTIONS[1]);
        setCopyInProgress(false);
    };

    const copyText = async (option: CopyOptionProps) => {
        setShowOptions(true);
        setCopyInProgress(true);
        try {
            await navigator.clipboard.write([new ClipboardItem({ "text/plain": new Blob([`${eventUrl}`], { type: "text/plain" }) })]);
            setHideCreateEmovidButton(false);
            if (authContext.user?.userId) postShared(authContext.user?.userId, creatorPost?.id || "", parseInt(duration || "0"), option.id);
            ToastService.success(option.copy_message);
        } catch (error) {
            console.log("Error: ", error);
        }
        setCopyInProgress(false);
        try {
            shareLinkCopyEvent(false, option.id);
        } catch (error) {
            console.error("Failed to track share link copy event:", error);
        }
        if (creatorPost?.id && creatorPost.post_status !== PostStatus.DRAFT_SHARABLE_LINK) updatePostById(creatorPost.id, { post_status: PostStatus.DRAFT_SHARABLE_LINK });
    };

    const togglePrivacyOptionsClicked = () => {
        setOptionsDrawerOpen(true);
        pushTagEvent("e_btn_record_share_privacy", { event_id: eventId, post_id: creatorPost?.id });
    };

    const isTranscriptGenerated = (creatorPostData = creatorPost): boolean => {
        return (creatorPostData?.states || []).includes(PostState.TRANSCRIPTION_UPDATED);
    };

    const showPreview = () => {
        if (isTranscriptGenerated()) {
            pushTagEvent("e_btn_preview_emovid", { event_id: eventId, post_id: creatorPost?.id });
            if (isDesktop) setShowEmovidPreview(true);
            else if (eventId) navigate(replaceEventIdInPath(Paths.previewAndEdit, eventId));
        } else ToastService.info("Preview will be enabled shortly when the transcription is complete ...");
    };

    const handleSummaryAndTranscriptUpdated = (summary: string, transcript: string) => {
        if (creatorPost) {
            creatorPost.ai_summary = summary;
            creatorPost.transcription_text = transcript;
        }
    };

    const handleActivityInTextEditor = () => {
        setEmailEditMode(false);
    };

    if (!event) {
        return <Progress />;
    }

    return (
        <>
            <NavBar
                theme="dark"
                breadcrumb={3}
                maxBreadcrumb={3}
                onStepClicked={handleStepClick}
                hideCreateEmovidButton={hideCreateEmovidButton}
                hideCreateEmovidButtonInMenu={true}
            />
            {eventUrl && (
                <div className="d-flex pt-4 w-100 flex-wrap justify-content-center">
                    <h5 className={"p-1 mt-1 label text-right " + (isDesktop || isTablet ? "pt-4" : "")}>
                        Copy Shareable Links <ShareInfo />
                    </h5>
                    <div>
                        <div className="d-flex w-100 justify-content-center flex-wrap">
                            <div className={"w-100 " + (copyInProgress ? "" : "opacity-0")}>
                                <LinearProgress />
                            </div>
                            <ShareIconsStrip
                                transcriptAvailable={isTranscriptGenerated()}
                                disabled={copyInProgress}
                                onCopyHTML={copyHTML}
                                onCopyText={copyText}
                                maxWidth="calc(100vw - 40px)"
                            />
                        </div>
                        <div className="text-truncate text-center p-2 m-auto label" style={{ maxWidth: "300px" }}>
                            {eventUrl}
                        </div>
                    </div>
                    {(isDesktop || isTablet) && (
                        <div className="pt-2 mt-2 d-flex justify-content-center" style={{ minWidth: isTablet ? "80px" : "120px", height: "48px" }}>
                            <Tooltip title="Privacy Settings">
                                <IconButton className="text-white" disabled={loading} onClick={togglePrivacyOptionsClicked}>
                                    {showLockIcon() ? <LockIcon /> : <LockOpenIcon />}
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                </div>
            )}
            <div className="container container-max-width p-4 pt-1 pb-5 mb-5">
                {!eventUrl && (
                    <>
                        <Desktop>
                            <div className="d-flex w-100 justify-content-evenly">
                                <Skeleton className="m-1" variant="rounded" width={200} animation="wave" />
                                <Skeleton className="m-1" variant="rounded" width={200} animation="wave" />
                                <Skeleton className="m-1" variant="rounded" width={200} animation="wave" />
                            </div>
                        </Desktop>
                        <Mobile>
                            <div className="d-flex w-100 flex-column align-items-center">
                                <Skeleton className="m-2" variant="rounded" width={300} animation="wave" />
                                <Skeleton className="m-2" variant="rounded" width={300} animation="wave" />
                            </div>
                        </Mobile>
                    </>
                )}
                <div className="transition-height mt-3" style={{ height: showOptions ? "40px" : "0" }}>
                    <div className="w-100 d-flex justify-content-between">
                        <div className=""></div>
                        <button className="btn btn-primary" disabled={loading} onClick={cancelEmovidEditConfirmed}>
                            Save & Exit
                        </button>
                    </div>
                </div>
                <hr className="mt-2 darker" />
                <div className="pb-2 mb-2 d-flex">
                    <span className="label" style={{ padding: isMobile ? "7px 2px 5px 0" : "7px 4px 7px 0" }}>
                        Subject:
                    </span>
                    <div className="input-editable w-100">
                        <input
                            autoFocus={!isIOS}
                            id="title-input"
                            className={"show-focus h4 w-100" + (event?.title ? "" : " flash-twice ")}
                            defaultValue={defaultEmovidTitle}
                            onChange={titleChanged}
                            onFocus={onTitleFocus}
                            placeholder="Enter Subject [required]"
                            autoComplete="off"
                        />
                    </div>
                </div>
                <ClickAwayListener onClickAway={() => setTimeout(() => setEmailEditMode(false), 200)}>
                    <div>
                        {event && creatorPost && (
                            <EmovidRecipients
                                event={event}
                                post={creatorPost}
                                privateReplies={privateReplies}
                                emailEditMode={emailEditMode}
                                eventUpdated={refetch}
                                editable={true}
                                enableEmailEditMode={() => setEmailEditMode(true)}
                                sendCopyToSelfChanged={sendCopyToSelfChanged}
                                onBccModeToggle={() => updatePrivacySettings(PrivacySetting.PRIVATE_REPLIES, !privateReplies)}
                            />
                        )}
                    </div>
                </ClickAwayListener>
                <hr className="mt-3 darker mb-0" />
                <Editor
                    placeholder="Add optional note or hyperlinks"
                    defaultValue={eventNote}
                    onChange={noteChanged}
                    onUserActivity={handleActivityInTextEditor}
                    loading={loading}
                />
                <hr className="darker m-0" />
                {videoUrl && (
                    <div className="m-auto mt-3" style={{ maxWidth: "600px", width: "100%" }}>
                        <VideoPlayer fluid={true} url={videoUrl} thumbnail={thumbnailUrl} customFullscreenButton={isDesktop} />
                    </div>
                )}
            </div>
            <ConfirmDialog
                show={showRetakeConfirmation}
                title="Retake Video?"
                message="Are you sure you want to record your video again? Current video will be deleted."
                cancelButtonText="No"
                confirmButtonText="Yes, Retake"
                onConfirmClick={retakeVideoConfirmed}
                onCancelClick={() => setShowRetakeConfirmation(false)}
            />
            <div className="fixed-bottom">
                <div className="container container-max-width p-3">
                    <div className="w-100 d-flex justify-content-between">
                        <div className="d-flex">
                            <Tooltip title="Go to dashboard">
                                <button className="btn btn-outline-light gray-btn" onClick={cancelEmovidEditConfirmed}>
                                    Save & Exit
                                </button>
                            </Tooltip>
                            <Desktop>
                                <div className="p-2 ms-2">Video messaging for business is finally here</div>
                            </Desktop>
                        </div>
                        {loading && <CircularProgress />}
                        <div className="d-flex">
                            <Tooltip title="Privacy Settings">
                                <IconButton className="text-white me-2" disabled={loading} onClick={togglePrivacyOptionsClicked}>
                                    {showLockIcon() ? <LockIcon /> : <LockOpenIcon />}
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title={isTranscriptGenerated() ? "Preview Emovid before sending out" : "Preview will be enabled shortly when the transcription is complete ..."}
                            >
                                <button className={"btn btn-outline-secondary me-2 " + (!isTranscriptGenerated() ? "disabled" : "")} onClick={showPreview}>
                                    Preview
                                </button>
                            </Tooltip>
                            <Tooltip title={emailEditMode ? "You can send this Video after adding recipients" : "Send Video"}>
                                <button className={"btn btn-primary " + (emailEditMode ? "disabled" : "")} disabled={loading} onClick={submitEmovid}>
                                    Send Now
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog onClose={() => setShowEmovidPreview(false)} open={showEmovidPreview} maxWidth={false} fullWidth={true}>
                {showEmovidPreview && (
                    <div className="created-emovids-views-dialog">
                        <h2 className="w-100 text-center">Preview</h2>
                        <IconButton className="text-white position-absolute" style={{ top: "20px", right: "20px", width: "40px" }} onClick={() => setShowEmovidPreview(false)}>
                            <i className="fas fa-times"></i>
                        </IconButton>
                        <ViewEmovid
                            eventId={eventId}
                            postId={creatorPost?.id}
                            previewMode={isDesktop || isTablet}
                            summaryAndTranscriptUpdated={handleSummaryAndTranscriptUpdated}
                        />
                    </div>
                )}
            </Dialog>
            <Drawer anchor="bottom" open={optionsDrawerOpen} onClose={() => setOptionsDrawerOpen(false)}>
                <div className="privacy-settings w-100 d-flex pt-3 pb-2" style={{ background: "rgba(0, 0, 0, 0.8)" }}>
                    <div className="privacy-settings-label pt-1">Privacy & Security Settings</div>
                    <Tooltip title="Close settings">
                        <button className="btn btn-link p-0 close-button text-white me-3" onClick={() => setOptionsDrawerOpen(false)}>
                            <CloseIcon />
                        </button>
                    </Tooltip>
                </div>
                <div className="privacy-settings-controls d-flex justify-content-evenly w-100 pt-2 pb-4" style={{ background: "rgba(0, 0, 0, 0.8)" }}>
                    <div className="d-flex flex-wrap justify-content-center controls">
                        <div className="d-flex justify-content-between p-1">
                            <span className="mt-2">
                                Only email recipients can view&nbsp;
                                <InfoTooltip text={`The Emovid video and replies will be private and can only be accessed by recipients listed in the "To" field.`} />
                            </span>
                            <Switch
                                className="me-4"
                                checked={originalRecipientsCanView}
                                onChange={() => updatePrivacySettings(PrivacySetting.ORIGINAL_RECIPIENTS_CAN_VIEW, !originalRecipientsCanView)}
                                inputProps={{ "aria-label": "only email recipients can view" }}
                            />
                        </div>
                        <div className="d-flex justify-content-between p-1">
                            <span className="mt-2">
                                Only signed-in users can view&nbsp;
                                <InfoTooltip text={`The Emovid video and replies will be accessible only to signed-in users on the Emovid platform.`} />
                            </span>
                            <Switch
                                className="me-4"
                                checked={signedInUsersCanView}
                                onChange={() => updatePrivacySettings(PrivacySetting.SIGNED_IN_USERS_CAN_VIEW, !signedInUsersCanView)}
                                disabled={originalRecipientsCanView === true}
                                inputProps={{ "aria-label": "only signed-in users can view" }}
                            />
                        </div>
                        <div className="d-flex justify-content-between p-1">
                            <span className="mt-2">
                                Bcc mode (Private)&nbsp;
                                <InfoTooltip
                                    text={`Recipients added in the Bcc mode are invisible to all others and will NOT receive any reply emails from the other recipients.`}
                                />
                            </span>
                            <Switch
                                className="me-4"
                                checked={privateReplies}
                                onChange={() => updatePrivacySettings(PrivacySetting.PRIVATE_REPLIES, !privateReplies)}
                                inputProps={{ "aria-label": "Bcc mode (Private)" }}
                            />
                        </div>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default CreateEmovid;
