import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $copyNode, $getNodeByKey, $isTextNode } from "lexical";

export function cssStringToMap(cssString: string): Map<string, string> {
    const cssMap = new Map<string, string>();
    const declarations = cssString.split(";");
    for (const declaration of declarations) {
        const [property, value] = declaration.split(":");
        if (property && value) {
            cssMap.set(property.trim(), value.trim());
        }
    }
    return cssMap;
}

export function mapToCssString(cssMap: Map<string, string>): string {
    let cssString = "";
    let cssMapEntries = Array.from(new Set(cssMap.entries()));
    for (const [property, value] of cssMapEntries) {
        cssString += `${property}: ${value};`;
    }
    return cssString;
}

const SUPPORTED_NODE_TYPES = ["text", "extended-text"];

// This plugin deletes text color and background color styles from pasted text
export function ContentPastePlugin() {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        return editor.registerUpdateListener(listener => {
            if (!listener.tags.has("paste")) return;
            editor.update(() => {
                listener.dirtyLeaves.forEach(key => {
                    const node = $getNodeByKey(key);
                    if (!node || !SUPPORTED_NODE_TYPES.includes(node?.getType() ?? "")) return;
                    const copy = $copyNode(node);
                    if ($isTextNode(copy)) {
                        const cssMap = cssStringToMap((node as any).getStyle() ?? "");
                        const stylesLength = cssMap.size;
                        if (cssMap.has("color")) cssMap.delete("color");
                        if (cssMap.has("background")) cssMap.delete("background");
                        if (cssMap.has("border")) cssMap.delete("border");
                        if (cssMap.has("border-color")) cssMap.delete("border-color");
                        if (cssMap.has("text-shadow")) cssMap.delete("text-shadow");
                        if (cssMap.has("background-color")) cssMap.delete("background-color");
                        if (stylesLength !== cssMap.size) {
                            copy.setStyle(mapToCssString(cssMap));
                            node.replace(copy);
                        }
                    }
                });
            });
        });
    }, [editor]);

    return null;
}
