import { Avatar, CircularProgress, IconButton, Menu, MenuItem, Tab, Table, TableBody, TableCell } from "@mui/material";
import { tableCellClasses, TableContainer, TableHead, TableRow, TableSortLabel, Tabs, Tooltip } from "@mui/material";
import { FC, useState, useEffect, useContext } from "react";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import HeightRoundedIcon from "@mui/icons-material/HeightRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import { IPostMetadataAttributes, IUserAttributes } from "@emovid/payloads-library";
import { Desktop, Mobile } from "../../Common/Responsive";
import { formattedDateTime, formattedDateTimeSinceViewed, formattedStatus, getEmailTrackingInfoForPost, menuProps, pushTagEvent } from "../../Common/Helper";
import HttpService from "../../Services/HttpService";
import { ICreatedEvents } from "../../Interfaces/ICreatedEvents";
import ToastService from "../../Common/ToastService";
import { AuthContext } from "../../Contexts/AuthContext";
import { IEmailTrackingInfo } from "../../Interfaces/IEmailTrackingInfoInterface";

interface PostViewsProps {
    post: ICreatedEvents;
    views: IPostMetadataAttributes[];
    creator?: IUserAttributes;
}

export enum ViewOptionsMenus {
    VIDEO_DETAILS = 0,
    EMAIL_DETAILS = 1
}

const PostViews: FC<PostViewsProps> = ({ views, post, creator }: PostViewsProps) => {
    const authContext = useContext(AuthContext);
    const viewOptionsTabs: string[] = ["Video Details", "E-mail Details"];
    const [selectedMenu, setSelectedMenu] = useState<ViewOptionsMenus>(ViewOptionsMenus.VIDEO_DETAILS);
    const [allViews, setAllViews] = useState<IPostMetadataAttributes[]>([]);
    const [sortedViews, setSortedViews] = useState<IPostMetadataAttributes[]>([]);
    const [sortField, setSortField] = useState<"name" | "plays" | "views" | "last_viewed" | "last_played">("plays");
    const [sortDescending, setSortDescending] = useState<boolean>(true);
    const [resendEmailToUserId, setResendEmailToUserId] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [fetchingEmailStats, setFetchingEmailStats] = useState<boolean>(false);
    const [emailTrackingInfo, setEmailTrackingInfo] = useState<IEmailTrackingInfo[]>([]);
    const [sortedEmailTrackingInfo, setSortedEmailTrackingInfo] = useState<IEmailTrackingInfo[]>([]);
    const [emailSortField, setEmailSortField] = useState<"name" | "last_opened" | "delivered">("last_opened");
    const [sortEmailDescending, setSortEmailDescending] = useState<boolean>(true);
    const viewMenuOpen = Boolean(anchorEl);

    useEffect(() => {
        let viewsCopy = JSON.parse(JSON.stringify(views));
        let recipients = JSON.parse(JSON.stringify(post?.recipients || []));
        if (creator) {
            let isCreatorPresent = false;
            recipients.forEach((recipient: any) => {
                if (recipient.email === creator.email) isCreatorPresent = true;
            });
            if (!isCreatorPresent) recipients.push({ id: creator.id || "", name: creator.name || "", email: creator.email });
        }
        recipients = recipients.filter((member: any) => authContext.user?.userId !== member.id); // hide views for logged-in users
        recipients.forEach((recipient: any) => {
            let isViewed = viewsCopy.filter((view: any) => view.user_id === recipient.id).length > 0;
            if (!isViewed)
                viewsCopy.push({
                    user_id: recipient.id,
                    User: { email: recipient.email, id: recipient.id, name: recipient.name }
                });
        });
        if (post?.unknown_views) {
            viewsCopy.push({
                user_id: "unknown",
                User: { email: "", id: "unknown", name: "Unknown" },
                last_played: post?.unknown_views.total?.last_played,
                last_viewed: post?.unknown_views.total?.last_viewed,
                views: post?.unknown_views.total?.views,
                plays: post?.unknown_views.total?.plays
            });
        }
        viewsCopy.forEach((view: any) => (view.avatarBgColor = view.avatarBgColor || randomBgColor()));
        setAllViews(viewsCopy);
    }, [views, post?.recipients, post?.unknown_views]);

    useEffect(() => {
        setFetchingEmailStats(true);
        getEmailTrackingInfoForPost(post.post_id)
            .then(trackingInfo => {
                // remove logged in user and null tracking status entries from list
                trackingInfo = trackingInfo.filter((trackingEmail: IEmailTrackingInfo) => trackingEmail.tracking_status && trackingEmail.user.id !== authContext.user?.userId);
                trackingInfo.forEach((trackingEmail: IEmailTrackingInfo) => {
                    trackingEmail.delivered = !!trackingEmail.delivered_on || ["OPEN", "DELIVERED"].includes(trackingEmail.tracking_status);
                    if (trackingEmail.failed_on) trackingEmail.failed_on_date = formattedDateTime(trackingEmail.failed_on, true) || "";
                    trackingEmail.avatarBgColor = trackingEmail.avatarBgColor || randomBgColor();
                });
                setEmailTrackingInfo(trackingInfo);
                setFetchingEmailStats(false);
            })
            .catch(error => {
                setFetchingEmailStats(false);
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let viewsCopy = JSON.parse(JSON.stringify(allViews));
        if (sortField === "plays") viewsCopy.sort((a: any, b: any) => ((a.plays || 0) - (b.plays || 0)) * (sortDescending ? -1 : 1));
        else if (sortField === "views") viewsCopy.sort((a: any, b: any) => ((a.views || 0) - (b.views || 0)) * (sortDescending ? -1 : 1));
        else if (sortField === "last_viewed")
            viewsCopy.sort((a: any, b: any) => (new Date(a.last_viewed || 0).getTime() - new Date(b.last_viewed || 0).getTime()) * (sortDescending ? -1 : 1));
        else if (sortField === "last_played")
            viewsCopy.sort((a: any, b: any) => (new Date(a.last_played || 0).getTime() - new Date(b.last_played || 0).getTime()) * (sortDescending ? -1 : 1));
        else if (sortField === "name") viewsCopy.sort((a: any, b: any) => (a.User.name || a.User.email).localeCompare(b.User.name || a.User.email) * (sortDescending ? -1 : 1));
        setSortedViews(viewsCopy);
    }, [sortField, sortDescending, allViews, post?.recipients]);

    useEffect(() => {
        let emailTrackingInfoCopy = JSON.parse(JSON.stringify(emailTrackingInfo));
        if (emailSortField === "last_opened")
            emailTrackingInfoCopy.sort((a: any, b: any) => (new Date(a.opened_on || 0).getTime() - new Date(b.opened_on || 0).getTime()) * (sortEmailDescending ? -1 : 1));
        else if (emailSortField === "delivered") emailTrackingInfoCopy.sort((a: any, b: any) => (a.delivered - b.delivered) * (sortEmailDescending ? -1 : 1));
        else if (emailSortField === "name")
            emailTrackingInfoCopy.sort((a: any, b: any) => (a.user.name || a.user.email).localeCompare(b.user.name || a.user.email) * (sortEmailDescending ? -1 : 1));
        setSortedEmailTrackingInfo(emailTrackingInfoCopy);
    }, [emailSortField, sortEmailDescending, emailTrackingInfo]);

    const sortByName = () => {
        if (selectedMenu === ViewOptionsMenus.EMAIL_DETAILS) {
            setSortEmailDescending(emailSortField === "name" ? !sortEmailDescending : sortEmailDescending);
            setEmailSortField("name");
        } else {
            setSortDescending(sortField === "name" ? !sortDescending : sortDescending);
            setSortField("name");
        }
    };

    const sortByPlays = () => {
        setSortDescending(sortField === "plays" ? !sortDescending : sortDescending);
        setSortField("plays");
    };

    const sortByViews = () => {
        setSortDescending(sortField === "views" ? !sortDescending : sortDescending);
        setSortField("views");
    };

    const sortByLastPlayed = () => {
        setSortDescending(sortField === "last_played" ? !sortDescending : sortDescending);
        setSortField("last_played");
    };

    const sortByPageLastViewed = () => {
        setSortDescending(sortField === "last_viewed" ? !sortDescending : sortDescending);
        setSortField("last_viewed");
    };

    // const sortByLastOpened = () => {
    //     setSortEmailDescending(emailSortField === "last_opened" ? !sortEmailDescending : sortEmailDescending);
    //     setEmailSortField("last_opened");
    // };

    const sortByDelivered = () => {
        setSortEmailDescending(emailSortField === "delivered" ? !sortEmailDescending : sortEmailDescending);
        setEmailSortField("delivered");
    };

    const handleMoreOptionsAllRows = (ev: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(ev.currentTarget);
        setResendEmailToUserId("");
    };

    const handleMoreOptionsForUser = (ev: React.MouseEvent<HTMLElement>, userId: string) => {
        setAnchorEl(ev.currentTarget);
        setResendEmailToUserId(userId);
    };

    const sendEmailToUser = async () => {
        try {
            if (!!resendEmailToUserId) {
                pushTagEvent("e_created_vids_resend", { post_id: post?.post_id, event_id: post?.emovid_id, userIid: resendEmailToUserId });
                await HttpService.postFormData(`/v1/event/notification/${post?.emovid_id}/recipient/${resendEmailToUserId}`, null, false);
            } else {
                pushTagEvent("e_created_vids_resend_all", { post_id: post?.post_id, event_id: post?.emovid_id });
                await HttpService.postFormData(`/v1/event/notification/${post?.emovid_id}/contributors`, null, false);
            }
            ToastService.success("Email sent" + (!!resendEmailToUserId ? "" : " to all recipients"));
        } catch (error: any) {
            ToastService.alertableError(error.message || "Unable to send email(s)", error);
        }
    };

    const tabChanged = (event: React.SyntheticEvent, newValue: number) => {
        pushTagEvent(newValue === ViewOptionsMenus.VIDEO_DETAILS ? "e_btn_views_video_details" : "e_btn_views_email_details", {
            event_id: post?.emovid_id || "",
            post_id: post?.post_id || ""
        });
        setSelectedMenu(newValue);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const randomBgColor = (): string => {
        var x = Math.floor(Math.random() * 192) + 64;
        var y = Math.floor(Math.random() * 256) + 64;
        var z = Math.floor(Math.random() * 256) + 64;
        return "rgb(" + x + "," + y + "," + z + ")";
    };

    const canResendEmailForAll = () => {
        let canResendEmailForAll = true;
        (sortedEmailTrackingInfo || []).forEach(element => (canResendEmailForAll = canResendEmailForAll && element.delivered));
        return canResendEmailForAll;
    };

    const userEmailReceived = (user?: IUserAttributes) => {
        let emailReceived = true; // enable by default
        (emailTrackingInfo || []).forEach((emailTracking: IEmailTrackingInfo) => {
            if (!!user && emailTracking.user.email === user.email) emailReceived = emailTracking.delivered;
        });
        return emailReceived;
    };

    return (
        <>
            <Tabs className="plain-tabs drawer-tabs m-auto" centered value={selectedMenu} onChange={tabChanged}>
                {viewOptionsTabs.map((tab: string, i: number) => (
                    <Tab label={tab} key={i} sx={{ padding: "4px" }} />
                ))}
            </Tabs>
            {selectedMenu === ViewOptionsMenus.VIDEO_DETAILS && (allViews || []).length === 0 && <div className="text-center p-5">No details available</div>}
            {selectedMenu === ViewOptionsMenus.VIDEO_DETAILS && (
                <TableContainer sx={{ maxHeight: "400px", overflowX: "auto", overflowY: "auto" }}>
                    <Table
                        stickyHeader
                        size="medium"
                        sx={{
                            width: "100%",
                            maxWidth: "100%",
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                                padding: "8px"
                            }
                        }}
                        aria-labelledby="tableTitle"
                        className="post-views-table"
                    >
                        {(allViews || []).length > 0 && (
                            <TableHead className="post-views-table-head">
                                <TableRow>
                                    <Mobile>
                                        <>
                                            <TableCell className="recipient p-0">
                                                <TableSortLabel
                                                    active={sortField === "name"}
                                                    IconComponent={sortField === "name" ? ArrowDownwardRoundedIcon : HeightRoundedIcon}
                                                    direction={sortDescending ? "desc" : "asc"}
                                                    onClick={sortByName}
                                                >
                                                    Recipient{allViews.length > 1 ? "s" : ""}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell className="date p-0">
                                                <TableSortLabel
                                                    active={sortField === "views"}
                                                    IconComponent={sortField === "views" ? ArrowDownwardRoundedIcon : HeightRoundedIcon}
                                                    direction={sortDescending ? "desc" : "asc"}
                                                    onClick={sortByViews}
                                                >
                                                    Views
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell className="date p-0">
                                                <TableSortLabel
                                                    active={sortField === "plays"}
                                                    IconComponent={sortField === "plays" ? ArrowDownwardRoundedIcon : HeightRoundedIcon}
                                                    direction={sortDescending ? "desc" : "asc"}
                                                    onClick={sortByPlays}
                                                >
                                                    Plays
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell className="options p-0">
                                                {post?.is_creator && canResendEmailForAll() && (
                                                    <IconButton onClick={(ev: any) => handleMoreOptionsAllRows(ev)}>
                                                        <MoreVertRoundedIcon />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        </>
                                    </Mobile>
                                    <Desktop>
                                        <>
                                            <TableCell className="recipient">
                                                <TableSortLabel
                                                    active={sortField === "name"}
                                                    IconComponent={sortField === "name" ? ArrowDownwardRoundedIcon : HeightRoundedIcon}
                                                    direction={sortDescending ? "desc" : "asc"}
                                                    onClick={sortByName}
                                                >
                                                    Recipient{allViews.length > 1 ? "s" : ""}
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell className="views text-end">
                                                <TableSortLabel
                                                    active={sortField === "plays"}
                                                    IconComponent={sortField === "plays" ? ArrowDownwardRoundedIcon : HeightRoundedIcon}
                                                    direction={sortDescending ? "desc" : "asc"}
                                                    onClick={sortByPlays}
                                                >
                                                    Total Plays
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell className="date">
                                                <TableSortLabel
                                                    active={sortField === "last_played"}
                                                    IconComponent={sortField === "last_played" ? ArrowDownwardRoundedIcon : HeightRoundedIcon}
                                                    direction={sortDescending ? "desc" : "asc"}
                                                    onClick={sortByLastPlayed}
                                                >
                                                    Last Played
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell className="views text-end">
                                                <TableSortLabel
                                                    active={sortField === "views"}
                                                    IconComponent={sortField === "views" ? ArrowDownwardRoundedIcon : HeightRoundedIcon}
                                                    direction={sortDescending ? "desc" : "asc"}
                                                    onClick={sortByViews}
                                                >
                                                    Page Views
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell className="date">
                                                <TableSortLabel
                                                    active={sortField === "last_viewed"}
                                                    IconComponent={sortField === "last_viewed" ? ArrowDownwardRoundedIcon : HeightRoundedIcon}
                                                    direction={sortDescending ? "desc" : "asc"}
                                                    onClick={sortByPageLastViewed}
                                                >
                                                    Page Viewed
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell className="options" sx={{ width: "40px", paddingLeft: 0, paddingRight: 0 }}>
                                                {post?.is_creator && canResendEmailForAll() && (
                                                    <IconButton onClick={(ev: any) => handleMoreOptionsAllRows(ev)}>
                                                        <MoreVertRoundedIcon />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                        </>
                                    </Desktop>
                                </TableRow>
                            </TableHead>
                        )}
                        <TableBody className="post-views-table-body">
                            {sortedViews.map((postView: IPostMetadataAttributes, i: number) => {
                                return (
                                    <>
                                        <Mobile key={i}>
                                            <>
                                                <TableRow key={i}>
                                                    <TableCell className="text-truncate ps-0 pe-0 pb-0">
                                                        <Tooltip title={postView.User?.email}>
                                                            <div className="d-flex">
                                                                <Avatar
                                                                    alt={postView.User?.name || postView.User?.email}
                                                                    sx={{
                                                                        width: 24,
                                                                        height: 24,
                                                                        fontSize: "12px",
                                                                        marginRight: "0.5rem",
                                                                        bgcolor: (postView as any).avatarBgColor
                                                                    }}
                                                                >
                                                                    {(postView.User?.name || postView.User?.email || "")[0]}
                                                                </Avatar>
                                                                <div className="break-word-pre-wrap limit-width" style={{ lineHeight: "24px" }}>
                                                                    {postView.User?.name || postView.User?.email || "-"}
                                                                </div>
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell className="text-center ps-0 pe-0 pb-0">{postView.views || "0"}</TableCell>
                                                    <TableCell className="text-center ps-0 pe-0 pb-0">{postView.plays || "0"}</TableCell>
                                                    <TableCell className="options ps-0 pe-0 pb-0">
                                                        {post?.is_creator && postView.user_id !== "unknown" && userEmailReceived(postView.User) && (
                                                            <IconButton onClick={(ev: any) => handleMoreOptionsForUser(ev, postView.user_id)}>
                                                                <MoreVertRoundedIcon />
                                                            </IconButton>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key={-i - 1}>
                                                    <TableCell colSpan={4} className="text-truncate pe-0 pt-0" style={{ paddingLeft: "calc(24px + 0.5rem)" }}>
                                                        {(postView.last_played || postView.last_viewed) && (
                                                            <div className="text-lighter text-wrap" style={{ lineHeight: "18px" }}>
                                                                {`${postView.last_played ? "Last played" : "Page viewed"}: ${formattedDateTimeSinceViewed(
                                                                    postView.last_played ? postView.last_played : postView.last_viewed
                                                                )}`}
                                                            </div>
                                                        )}
                                                        {!postView.last_played && !postView.last_viewed && <div className="text-lighter">Unviewed</div>}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        </Mobile>
                                        <Desktop key={-i - 1}>
                                            <TableRow>
                                                <TableCell className="text-truncate">
                                                    <Tooltip title={postView.User?.email}>
                                                        <div className="d-flex">
                                                            <Avatar
                                                                alt={postView.User?.name || postView.User?.email}
                                                                sx={{ width: 24, height: 24, fontSize: "12px", marginRight: "0.5rem", bgcolor: (postView as any).avatarBgColor }}
                                                            >
                                                                {(postView.User?.name || postView.User?.email || "")[0]}
                                                            </Avatar>
                                                            {postView.User?.name || postView.User?.email || "-"}
                                                        </div>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell className="text-center">{postView.plays || "0"}</TableCell>
                                                {postView.last_played && <TableCell className="date">{formattedDateTimeSinceViewed(postView.last_played)}</TableCell>}
                                                {!postView.last_played && (
                                                    <TableCell className="empty">
                                                        <span className="text-lighter fw-bold">Not played</span>
                                                    </TableCell>
                                                )}
                                                <TableCell className="text-center">{postView.views || "0"}</TableCell>
                                                {postView.last_viewed && <TableCell className="date">{formattedDateTimeSinceViewed(postView.last_viewed)}</TableCell>}
                                                {!postView.last_viewed && (
                                                    <TableCell className="empty">
                                                        <span className="text-lighter fw-bold">Not viewed</span>
                                                    </TableCell>
                                                )}
                                                <TableCell className="options">
                                                    {post?.is_creator && postView.user_id !== "unknown" && userEmailReceived(postView.User) && (
                                                        <IconButton onClick={(ev: any) => handleMoreOptionsForUser(ev, postView.user_id)}>
                                                            <MoreVertRoundedIcon />
                                                        </IconButton>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        </Desktop>
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {fetchingEmailStats && selectedMenu === ViewOptionsMenus.EMAIL_DETAILS && (
                <div className="p-5 w-100 text-center">
                    <CircularProgress size="0.7rem" className="me-2" />
                    Fetching ...
                </div>
            )}
            {!fetchingEmailStats && selectedMenu === ViewOptionsMenus.EMAIL_DETAILS && (emailTrackingInfo || []).length === 0 && (
                <div className="text-center p-5">No details available</div>
            )}
            {!fetchingEmailStats && selectedMenu === ViewOptionsMenus.EMAIL_DETAILS && (emailTrackingInfo || []).length > 0 && (
                <TableContainer sx={{ maxHeight: "400px", overflowX: "auto", overflowY: "auto" }}>
                    <Table
                        stickyHeader
                        size="medium"
                        sx={{
                            width: "100%",
                            maxWidth: "100%",
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                                padding: "8px"
                            }
                        }}
                        aria-labelledby="tableTitle"
                        className="post-views-table"
                    >
                        <TableHead className="post-views-table-head">
                            <TableRow>
                                <Mobile>
                                    <>
                                        <TableCell className="recipient p-0">
                                            <TableSortLabel
                                                active={emailSortField === "name"}
                                                IconComponent={emailSortField === "name" ? ArrowDownwardRoundedIcon : HeightRoundedIcon}
                                                direction={sortEmailDescending ? "desc" : "asc"}
                                                onClick={sortByName}
                                            >
                                                Recipient{allViews.length > 1 ? "s" : ""}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell className="date p-0">
                                            <TableSortLabel
                                                active={emailSortField === "delivered"}
                                                IconComponent={emailSortField === "delivered" ? ArrowDownwardRoundedIcon : HeightRoundedIcon}
                                                direction={sortEmailDescending ? "desc" : "asc"}
                                                onClick={sortByDelivered}
                                            >
                                                Delivered
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell className="options p-0">
                                            {post?.is_creator && canResendEmailForAll() && (
                                                <IconButton onClick={(ev: any) => handleMoreOptionsAllRows(ev)}>
                                                    <MoreVertRoundedIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </>
                                </Mobile>
                                <Desktop>
                                    <>
                                        <TableCell className="recipient">
                                            <TableSortLabel
                                                active={emailSortField === "name"}
                                                IconComponent={emailSortField === "name" ? ArrowDownwardRoundedIcon : HeightRoundedIcon}
                                                direction={sortEmailDescending ? "desc" : "asc"}
                                                onClick={sortByName}
                                            >
                                                Recipient{allViews.length > 1 ? "s" : ""}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                                active={emailSortField === "delivered"}
                                                IconComponent={emailSortField === "delivered" ? ArrowDownwardRoundedIcon : HeightRoundedIcon}
                                                direction={sortEmailDescending ? "desc" : "asc"}
                                                onClick={sortByDelivered}
                                            >
                                                Delivered
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell className="options" sx={{ width: "40px", paddingLeft: 0, paddingRight: 0 }}>
                                            {post?.is_creator && canResendEmailForAll() && (
                                                <IconButton onClick={(ev: any) => handleMoreOptionsAllRows(ev)}>
                                                    <MoreVertRoundedIcon />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </>
                                </Desktop>
                            </TableRow>
                        </TableHead>
                        <TableBody className="post-views-table-body">
                            {sortedEmailTrackingInfo.map((emailInfo: IEmailTrackingInfo, i: number) => {
                                return (
                                    <>
                                        <Mobile key={i}>
                                            <>
                                                <TableRow key={i}>
                                                    <TableCell className="text-truncate ps-0 pe-0 pb-0">
                                                        <Tooltip title={emailInfo.user?.email}>
                                                            <div className="d-flex">
                                                                <Avatar
                                                                    alt={emailInfo.user?.name || emailInfo.user?.email}
                                                                    sx={{
                                                                        width: 24,
                                                                        height: 24,
                                                                        fontSize: "12px",
                                                                        marginRight: "0.5rem",
                                                                        bgcolor: (emailInfo as any).avatarBgColor
                                                                    }}
                                                                >
                                                                    {(emailInfo.user?.name || emailInfo.user?.email || "")[0]}
                                                                </Avatar>
                                                                <div className="break-word-pre-wrap limit-width" style={{ lineHeight: "24px" }}>
                                                                    {emailInfo.user?.name || emailInfo.user?.email || "-"}
                                                                </div>
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell className={"text-center ps-0 pe-0 pb-0 " + (emailInfo.delivered ? "" : "text-disabled")}>
                                                        <div>{emailInfo.delivered ? "Y" : formattedStatus(emailInfo.tracking_status)}</div>
                                                    </TableCell>
                                                    <TableCell className="options ps-0 pe-0 pb-0">
                                                        {post?.is_creator && emailInfo.delivered && (
                                                            <IconButton onClick={(ev: any) => handleMoreOptionsForUser(ev, emailInfo.user.id || "")}>
                                                                <MoreVertRoundedIcon />
                                                            </IconButton>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow key={-i - 1}>
                                                    <TableCell
                                                        colSpan={3}
                                                        className={"text-truncate pe-0 " + (emailInfo.delivered ? "" : "text-disabled")}
                                                        style={{ paddingLeft: "calc(24px + 0.5rem)", paddingTop: "0" }}
                                                    >
                                                        {emailInfo.delivered
                                                            ? formattedDateTime(emailInfo.delivered_on, true)
                                                            : `${formattedStatus(emailInfo.tracking_status)} at ${emailInfo.failed_on_date}`}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        </Mobile>
                                        <Desktop key={-i - 1}>
                                            <TableRow>
                                                <TableCell className="text-truncate">
                                                    <Tooltip title={emailInfo.user?.email}>
                                                        <div className="d-flex">
                                                            <Avatar
                                                                alt={emailInfo.user?.name || emailInfo.user?.email}
                                                                sx={{ width: 24, height: 24, fontSize: "12px", marginRight: "0.5rem", bgcolor: (emailInfo as any).avatarBgColor }}
                                                            >
                                                                {(emailInfo.user?.name || emailInfo.user?.email || "")[0]}
                                                            </Avatar>
                                                            {emailInfo.user?.name || emailInfo.user?.email || "-"}
                                                        </div>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell className={emailInfo.delivered ? "" : "text-disabled"}>
                                                    {emailInfo.delivered
                                                        ? formattedDateTime(emailInfo.delivered_on, true)
                                                        : `${formattedStatus(emailInfo.tracking_status)} at ${emailInfo.failed_on_date}`}
                                                </TableCell>
                                                <TableCell className="options">
                                                    {post?.is_creator && emailInfo.delivered && (
                                                        <IconButton onClick={(ev: any) => handleMoreOptionsForUser(ev, emailInfo.user.id || "")}>
                                                            <MoreVertRoundedIcon />
                                                        </IconButton>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        </Desktop>
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <Menu
                anchorEl={anchorEl}
                id="view-menu"
                open={viewMenuOpen}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{ paper: menuProps }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <div className="pb-2 ps-3 pe-2 fst-italic">{resendEmailToUserId ? "" : "All "}Recipient Options</div>
                <MenuItem onClick={sendEmailToUser}>
                    <RefreshRoundedIcon className="me-2" />
                    Resend{resendEmailToUserId ? "" : " to All"}
                </MenuItem>
            </Menu>
        </>
    );
};

export default PostViews;
