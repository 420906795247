import { FC, useContext, useState } from "react";
import { CircularProgress, Divider } from "@mui/material";
import { useQuery } from "react-query";
import { IPostMetadataAttributes } from "@emovid/payloads-library";
import { isMobile } from "react-device-detect";
import { MobilePostsList } from "./MobileResponsesList";
import { IPostResponseAttributes } from "../../Interfaces/IPostResponseAttributes";
import { ParentResponsesListProps } from "./IPostsListProps";
import { PostsList } from "./ResponsesList";
import { getPostById, getViewsForPost, setVideoAndThumbnailUrlsForPost } from "../../Common/Helper";
import { AuthContext } from "../../Contexts/AuthContext";

const ParentResponsesList: FC<ParentResponsesListProps> = ({
    event,
    parentPostId,
    selectedPost,
    onPostSelected,
    isCreatorLoggedIn,
    onViewsClickedForPost,
    onNewThreadClickedForPost
}: ParentResponsesListProps) => {
    const authContext = useContext(AuthContext);
    const [parentThreadDeleted, setParentThreadDeleted] = useState<boolean>(false);
    const [fetchingThread, setFetchingThread] = useState<boolean>(true);
    const [creatorPosts, setCreatorPosts] = useState<IPostResponseAttributes[]>([]);

    useQuery({
        queryKey: ["parentPost"],
        refetchOnWindowFocus: false,
        retry: false,
        queryFn: () => getPostById(parentPostId!),
        onError: (error: any) => {
            console.log(error);
            if (error.message.indexOf("does not exist") >= 0) setParentThreadDeleted(true);
            setFetchingThread(false);
        },
        onSuccess: async (creatorPost: IPostResponseAttributes) => {
            let currentThreadMembers = [event.creator_id];
            (event.Members || []).forEach(member => currentThreadMembers.push(member.member_id || ""));
            if (creatorPost) {
                creatorPost.PostViews = [];
                if (authContext.user?.userId) {
                    let response: IPostMetadataAttributes[] = await getViewsForPost(creatorPost.id || "");
                    response.filter((postView: IPostMetadataAttributes) => postView.user_id !== authContext.user?.userId);
                    creatorPost.PostViews = response;
                }
                creatorPost.views_loaded = true;
                setVideoAndThumbnailUrlsForPost(creatorPost);
                setCreatorPosts([creatorPost]);
            }
            setFetchingThread(false);
        }
    });

    return (
        <>
            {fetchingThread && (
                <div className="text-center p-2">
                    <CircularProgress color="inherit" size="0.8rem" className="me-2" />
                    Fetching thread ...
                </div>
            )}
            {parentThreadDeleted && (
                <div id="parent-deleted">
                    <div className="private-video disabled text-center pt-3">
                        <i className="fa fa-eye-slash"></i>&nbsp; Video deleted
                    </div>
                    <Divider className="mt-3 mb-3 text-white">Responses</Divider>
                </div>
            )}
            {!isMobile && !fetchingThread && !parentThreadDeleted && (
                <div className="mb-3">
                    <PostsList
                        event={event}
                        eventPosts={creatorPosts}
                        selectedPost={selectedPost}
                        onPostSelected={onPostSelected}
                        isCreatorLoggedIn={isCreatorLoggedIn}
                        hideCreatorLabel={true}
                    />
                    <Divider className="mt-3 text-white">Responses</Divider>
                </div>
            )}
            {isMobile && !fetchingThread && !parentThreadDeleted && (
                <div className="mb-3">
                    <MobilePostsList
                        event={event}
                        eventPosts={creatorPosts}
                        isCreatorLoggedIn={isCreatorLoggedIn}
                        selectedPost={selectedPost}
                        onPostSelected={onPostSelected}
                        onViewsClickedForPost={onViewsClickedForPost}
                        onNewThreadClickedForPost={onNewThreadClickedForPost}
                        hideCreatorLabel={true}
                    />
                    <Divider className="mt-2 text-white">Responses</Divider>
                    <MobilePostsList
                        event={event}
                        eventPosts={[]}
                        isCreatorLoggedIn={isCreatorLoggedIn}
                        selectedPost={selectedPost}
                        onPostSelected={onPostSelected}
                        onViewsClickedForPost={onViewsClickedForPost}
                        onNewThreadClickedForPost={onNewThreadClickedForPost}
                    />
                </div>
            )}
        </>
    );
};

export default ParentResponsesList;
