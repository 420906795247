import { FC, ReactElement, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Backdrop, Badge, Box, CircularProgress, Dialog, Divider, IconButton, ListItemIcon, Menu, MenuItem, SwipeableDrawer, Tab, Tabs, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import LockIcon from "@mui/icons-material/Lock";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import { debounce } from "lodash";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import * as amplitude from "@amplitude/analytics-browser";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { IEventAttributes, IPostMetadataAttributes, IPostUrl, IPrivacySettingsAttributes, IReceivedPost, IUserPreferences } from "@emovid/payloads-library";
import {
    createResponse,
    deleteStorageItem,
    formattedTimeSince,
    getEventById,
    getMemberById,
    createdEventFromData,
    getViewsForPost,
    getPostById,
    CanCreatePrivateThreadForPost,
    notesHtmlForPost
} from "../../Common/Helper";
import { isEmovidDomainUser, updatePostById, getWelcomePosts } from "../../Common/Helper";
import { getPostsForEvent, getStorageItem, pushTagEvent, menuProps, updatePostPageViews, updatePostVideoViews, identifyUserSession, getUserDetails } from "../../Common/Helper";
import { setStorageItem, setVideoAndThumbnailUrlsForPost, generateSignedUrlForFileKey, updatePostViewedPercent, canEditSecuritySettings } from "../../Common/Helper";
import ToastService from "../../Common/ToastService";
import NavBar from "../Nav/NavBar";
import { IPostResponseAttributes } from "../../Interfaces/IPostResponseAttributes";
import { Paths, replaceEventIdInPath, replacePostIdInPath } from "../../Common/AppRoutes";
import Popup from "../../Common/Popup";
import VideoPlayer from "../../VideoLib/Components/VideoPlayer";
import { AuthContext } from "../../Contexts/AuthContext";
import Login from "../Login/Login";
import ResponsesList from "./ResponsesList";
import RecipientsList from "./RecipientsList";
import MobileResponsesList from "./MobileResponsesList";
import { displayNameForUser } from "../../Util/UserInfoMap";
import ReadMore from "../../Common/ReadMore";
import { DurationUpdateEvent } from "../../Interfaces/DurationUpdateEvent";
import { isWhitelistedUser } from "../../Common/GuardedRoute";
import HttpService from "../../Services/HttpService";
import PrivacySettingsList from "./PrivacySettingsList";
import UnauthorizeVideo from "./UnauthorizeVideo";
import ShareEventDialog from "../Dashboard/ShareEventDialog";
import ResponsesTabIcon from "../../Icons/ResponsesTabIcon";
import RecipientsTabIcon from "../../Icons/RecipientsTabIcon";
import PostViews from "../Dashboard/PostViews";
import { ICreatedEvents } from "../../Interfaces/ICreatedEvents";
import ConfirmDialog from "../../Common/ConfirmDialog";
import PrivacySettingsDialog from "../Dashboard/PrivacySettingsDialog";
import { CLOUDFRONT_URL } from "../../VideoLib/Common/Constants";
import ViewEmovidSkeleton from "./ViewEmovidSkeleton";
import CopyEmovidMenuButton from "../../Common/CopyEmovidMenuButton";
import WaitlistButton from "../../Common/WaitlistButton";
import { START_PRIVATE_THREAD_BUTTON_TEXT, SUPPORTED_LANGUAGES_OBJECTS } from "../../Common/Constants";
import SelectLanguage from "./SelectLanguage";
import EditTranscriptAndSummary from "./EditTranscriptAndSummary";
import { Desktop, Mobile } from "../../Common/Responsive";

const drawerHeadingHeight = 56;
const footerHeight = 70;
const drawerBleeding = drawerHeadingHeight + footerHeight;
export const translucentBackgroundColor = "rgba(0,0,0,0.5)";

export const StyledBox = styled(Box)(() => ({
    backgroundColor: translucentBackgroundColor
}));

export const Puller = styled(Box)(() => ({
    width: 30,
    height: 6,
    backgroundColor: "#fff",
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)"
}));

interface ViewEmovidProps {
    eventId?: string;
    postId?: string;
    previewMode?: boolean;
    summaryAndTranscriptUpdated?: (summary: string, transcript: string) => any;
}

interface TabItem {
    title: string;
    icon: ReactElement;
}

export enum ResponseRecipientDesktopMenu {
    RESPONSES = 0,
    RECIPIENTS = 1,
    PRIVACY_SETTINGS = 2
}

export enum ResponseRecipientMobileMenu {
    INFO = 0,
    RESPONSES = 1,
    RECIPIENTS = 2,
    PRIVACY_SETTINGS = 3
}

export enum PrivacySettingsState {
    SIGNED_IN_USERS_CAN_VIEW_POST = "SIGNED_IN_USERS_CAN_VIEW_POST",
    RECIPIENTS_CAN_VIEW_POST_LOGGED_IN = "RECIPIENTS_CAN_VIEW_POST_LOGGED_IN",
    RECIPIENTS_CAN_VIEW_POST_NON_LOGGED_IN = "RECIPIENTS_CAN_VIEW_POST_NON_LOGGED_IN"
}

export const EmailRecipientWhitelistUserStorageKey = `isUserWhitelisted`;
export const EmailRecipientUserDetailsStorageKey = "emailRecipientUserDetails";
export const WAITLIST_MESSAGE = "Sorry! You do not have access to full Emovid functionality yet.";
export const getTotalViews = (loggedinUserId: string, post: IPostResponseAttributes) => {
    let views = post?.unknown_views?.total?.views || 0;
    (post?.PostViews || []).forEach(postView => {
        if (postView.user_id !== loggedinUserId) views += postView?.views || 0;
    });
    return `${views} view${views !== 1 ? "s" : ""}`;
};

const ViewEmovid: FC<ViewEmovidProps> = (props: ViewEmovidProps) => {
    const responseRecipientTabs: TabItem[] = [
        { title: "Responses", icon: <ResponsesTabIcon /> },
        { title: "Recipients", icon: <RecipientsTabIcon /> }
    ];
    const mobileResponseRecipientTabs: TabItem[] = [
        { title: "Details", icon: <InfoRoundedIcon /> },
        { title: "Responses", icon: <ResponsesTabIcon /> },
        { title: "Recipients", icon: <RecipientsTabIcon /> }
    ];
    let { eventId } = useParams();
    eventId = eventId || props.eventId;
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const openDrawer = useRef<any>();
    const emailRecipientUserStorageKey = `respond-${eventId}`;
    const currentSelectedPostStorageKey = `selectedPost`;
    const shouldRedirectToRecordKey = `redirectToRecordAfterLogin`;
    const shouldRedirectToInboxKey = `redirectToInboxAfterLogin`;
    const shouldRedirectToCreateThreadKey = `redirectToRecordThreadAfterLogin`;
    const isPreviewMode: boolean = searchParams.get("preview") === "true" || props.previewMode || false;
    const [event, setEvent] = useState<IEventAttributes | null>(null);
    const [selectedMenu, setSelectedMenu] = useState<ResponseRecipientDesktopMenu | ResponseRecipientMobileMenu>(
        isMobile ? ResponseRecipientMobileMenu.INFO : ResponseRecipientDesktopMenu.RESPONSES
    );
    const [welcomePosts, setWelcomePosts] = useState<IReceivedPost[]>(JSON.parse(sessionStorage.getItem("welcomePosts") || "[]"));
    const [hideAuthenticitySeal, setHideAuthenticitySeal] = useState<boolean>(false);
    const [selectedPost, setSelectedPost] = useState<IPostResponseAttributes>();
    const [aiSummary, setAISummary] = useState<string>("");
    const [transcriptionText, setTranscriptionText] = useState<string>("");
    const [actionItems, setActionItems] = useState<string>("");
    const [postForViewsDialog, setPostForViewsDialog] = useState<IPostResponseAttributes>();
    const [selectedPostAsCreated, setSelectedPostAsCreated] = useState<ICreatedEvents>();
    const [eventPosts, setEventPosts] = useState<IPostResponseAttributes[]>([]);
    const [showBackButton, setShowBackButton] = useState<boolean>(false);
    const [showSignupToSaveDialog, setShowSignupToSaveDialog] = useState<boolean>(false);
    const [showTranscriptEditor, setShowTranscriptEditor] = useState<boolean>(false);
    const [showOriginalVideo, setShowOriginalVideo] = useState<boolean>(true);
    const [showNonWhitelistedLoginDialog, setShowNonWhitelistedLoginDialog] = useState<boolean>(false);
    const [showSignupToPostDialog, setShowSignupToPostDialog] = useState<boolean>(false);
    const [showLoginToCreateThreadDialog, setShowLoginToCreateThreadDialog] = useState<boolean>(false);
    const [translateTextInProgress, setTranslateTextInProgress] = useState<boolean>(false);
    const [selectedLanguageObject, setSelectedLanguageObject] = useState<{ key: string; value: string }>();
    const [showLoginDialogForPrivacySettings, setShowLoginDialogForPrivacySettings] = useState<boolean>(false);
    const [showLoginDialogForPrivateVideoCreator, setShowLoginDialogForPrivateVideoCreator] = useState<boolean>(false);
    const [playedVideoPostId, setPlayedVideoPostId] = useState<string>("");
    const [privacySettingsState, setPrivacySettingsState] = useState<PrivacySettingsState>();
    const [privacySettings, setPrivacySettings] = useState<IPrivacySettingsAttributes>();
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [showJoinWaitlistBtn, setShowJoinWaitlistBtn] = useState<boolean>(false);
    const [selectedPostIds, setSelectedPostIds] = useState<string[]>(location.state?.selectedPostIds || []);
    const [isEventContainsPrivatePosts, setIsEventContainsPrivatePosts] = useState<boolean>(false);
    const [isCreatorLoggedIn, setIsCreatorLoggedIn] = useState<boolean>(false);
    const [unreadResponsesCount, setUnreadResponsesCount] = useState<number>(0);
    const [showDeletePostConfirmation, setShowDeletePostConfirmation] = useState<boolean>(false);
    const [autoSaving, setAutoSaving] = useState<boolean>(false);
    const [securitySettingsPopupOpen, setSecuritySettingsPopupOpen] = useState<boolean>(false);
    const [isUrlContainsTranslate, setIsUrlContainsTranslate] = useState<boolean>(searchParams.get("translate") === "true");
    const [videoUrl, setVideoUrl] = useState<string>();
    const [hlsVideoUrl, setHLSVideoUrl] = useState<string>();
    const [showViewsDialog, setShowViewsDialog] = useState<boolean>(false);
    const [creatingThread, setCreatingThread] = useState<boolean>(false);
    const [autoplay, setAutoPlay] = useState<boolean>(false);
    const [showShareDialog, setShowShareDialog] = useState<boolean>(false);
    const [isNonSignedUpUser, setIsNonSignedUpUser] = useState<boolean>(false);
    const [creatingEmovid, setCreatingEmovid] = useState<boolean>(false);
    const [showGifInCopy, setShowGifInCopy] = useState<boolean>(false);
    const [playbackStartTime, setPlaybackStartTime] = useState<number>(0);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [playerDialogWidth, setPlayerDialogWidth] = useState(600);
    const postMenuOpen = Boolean(anchorEl);

    const toggleDrawer = (newOpen: boolean) => () => {
        setDrawerOpen(newOpen);
    };

    const { refetch } = useQuery({
        queryKey: ["event"],
        refetchOnWindowFocus: false,
        retry: false,
        queryFn: () => getEventById(eventId!),
        onError: (error: Error) => {
            const errMessage = Array.isArray(error.message) ? error.message[0] : error.message;
            ToastService.alertableError(errMessage, error);
            if (`${errMessage}`.toLowerCase().indexOf("network") < 0) navigate(Paths.myInbox);
        },
        onSuccess: async (data: IEventAttributes) => {
            if (data.deletedAt) {
                navigate(`${Paths.emovidDeleted}${searchParams.get("back_to") ? "?back_to=true" : ""}`);
                return;
            }
            setEvent(data);
            deleteStorageItem(EmailRecipientWhitelistUserStorageKey);
            if (searchParams.get("show_original")) setShowOriginalVideo(true);
            if (sessionStorage.getItem("autoplay")) {
                setAutoPlay(true);
                sessionStorage.removeItem("autoplay");
            }
            if (searchParams.get("member")) {
                let memberDetails = await getMemberById(searchParams.get("member") || "");
                setStorageItem(emailRecipientUserStorageKey, memberDetails.member_id);
                setStorageItem(EmailRecipientUserDetailsStorageKey, JSON.stringify(memberDetails.UserDetails));
                const whitelistResponse = await HttpService.getWithAPIKey(`/v1/whitelisted/status/${memberDetails.UserDetails.email}`);
                setStorageItem(EmailRecipientWhitelistUserStorageKey, whitelistResponse.is_whitelisted ? "true" : "false");
                identifyUserSession({ userId: memberDetails.member_id, email: memberDetails.UserDetails.email, name: memberDetails.UserDetails.name });
                let privacySettingsEnabled = false;
                if (data && data.CreatorPosts && data.CreatorPosts[0].PrivacySettings) {
                    const privacySettings = data.CreatorPosts[0].PrivacySettings;
                    if (privacySettings.signed_in_users_can_view_post || privacySettings.recipients_can_view_post) {
                        privacySettingsEnabled = true;
                    }
                }
                if (sessionStorage.getItem("try_now_signup_called") === "true") {
                    // do nothing
                } else if (!privacySettingsEnabled && authContext.user?.userId && memberDetails.member_id !== authContext.user?.userId) {
                    setTimeout(() => authContext.signOut(), 1000); // SE-504, email link user and logged in user do not match
                } // trigger only if privacy settings are not present
            } else if (searchParams.get("creator_response") === "true") {
                setStorageItem(emailRecipientUserStorageKey, data.creator_id);
                try {
                    let creatorDetails = await getUserDetails(data.creator_id);
                    setStorageItem(EmailRecipientUserDetailsStorageKey, JSON.stringify(creatorDetails));
                    const whitelistResponse = await HttpService.getWithAPIKey(`/v1/whitelisted/status/${creatorDetails.email}`);
                    setStorageItem(EmailRecipientWhitelistUserStorageKey, whitelistResponse.is_whitelisted ? "true" : "false");
                } catch (error) {
                    console.log(error);
                }
                if (data.CreatorPosts && data.CreatorPosts[0].PrivacySettings) {
                    const privacySettings = data.CreatorPosts[0].PrivacySettings;
                    if (privacySettings.keep_replies_private && !authContext.user?.userId) {
                        setShowLoginDialogForPrivateVideoCreator(true); // other privacy settings are handled by handlePrivacySettings method
                    }
                }
            }
            let user = JSON.parse(getStorageItem(EmailRecipientUserDetailsStorageKey) || "{}");
            setIsNonSignedUpUser(!(!!authContext.user?.userId || user?.is_active));
            if (searchParams.get("back_to")) setShowBackButton(true);
            if (searchParams.get("copy_gif")) setShowGifInCopy(true);
            if (getStorageItem(shouldRedirectToRecordKey)) handleLoginSuccess();
            if (getStorageItem(shouldRedirectToCreateThreadKey)) handleCreateThreadLoginSuccess();
            else if (getStorageItem(shouldRedirectToInboxKey)) {
                deleteStorageItem(shouldRedirectToInboxKey);
                navigate(Paths.myInbox);
            }
            setShowJoinWaitlistBtn(
                (authContext.user?.userId && !isWhitelistedUser(authContext)) ||
                    (!authContext.user?.userId && searchParams.get("member") && getStorageItem(EmailRecipientWhitelistUserStorageKey) !== "true") ||
                    (!authContext.user?.userId && searchParams.get("creator_response") && getStorageItem(EmailRecipientWhitelistUserStorageKey) !== "true") ||
                    (!authContext.user?.userId && !searchParams.get("member") && !searchParams.get("creator_response")) // shareable link case
            );
        }
    });

    useEffect(() => {
        if (!selectedPost) return; // only is selected post is set
        if ((welcomePosts || []).length === 0) {
            getWelcomePosts().then((data: { posts: IReceivedPost[] }) => {
                let postsToAdd: IReceivedPost[] = [];
                (data.posts || []).forEach((post: any) => {
                    if (!post.is_hidden) {
                        setVideoAndThumbnailUrlsForPost(post);
                        postsToAdd.push(post);
                    }
                });
                checkAndSetWelcomePost(postsToAdd, selectedPost);
                sessionStorage.setItem("welcomePosts", JSON.stringify(postsToAdd));
                setWelcomePosts(postsToAdd);
            });
        } else {
            checkAndSetWelcomePost(welcomePosts, selectedPost);
        }
    }, [selectedPost]);

    useEffect(() => {
        setSelectedPost(undefined);
        setVideoUrl(undefined);
        refetch();
    }, [authContext.user, refetch]);

    useEffect(() => {
        if (event) {
            handlePrivacySettings(event);
            getPostsForEmovid(event);
            markEventAsSent(event);
        }
    }, [event]);

    useEffect(() => {
        if (event && selectedPost && authContext.user?.userId) setSelectedPostAsCreated(createdEventFromData(authContext.user?.userId, event, selectedPost));
    }, [selectedPost, event, authContext.user?.userId]);

    useEffect(() => {
        eventPosts.forEach((eventPost: IPostResponseAttributes) => {
            if (eventPost.creator_id === authContext.user?.userId)
                getViewsForPost(eventPost.id || "")
                    .then((response: IPostMetadataAttributes[]) => {
                        eventPost.PostViews = response.filter((postView: IPostMetadataAttributes) => postView.user_id !== authContext.user?.userId);
                        eventPost.views_loaded = true;
                        if (selectedPost && selectedPost.id === eventPost.id) {
                            setSelectedPost(JSON.parse(JSON.stringify(eventPost)));
                            setVideoUrl(eventPost?.video_url || "");
                            setHLSVideoUrlFromPost(JSON.parse(JSON.stringify(eventPost)));
                        }
                    })
                    .catch(error => (eventPost.PostViews = []));
        });
    }, [eventPosts, authContext.user?.userId]);

    useEffect(() => {
        if (isMobile && !openDrawer.current?.opened && isUrlContainsTranslate) {
            setDrawerOpen(true);
            const selectLanguage = document.getElementById("language-select");
            if (selectLanguage) {
                selectLanguage.click();
                setIsUrlContainsTranslate(false);
                openDrawer.current.opened = true;
            }
        }
    });

    const checkAndSetWelcomePost = (posts: IReceivedPost[], postToCheck: IPostResponseAttributes) => {
        let isWelcomePostPresent = false;
        posts.forEach((post: any) => {
            if (postToCheck.id === post.id) isWelcomePostPresent = true;
        });
        setHideAuthenticitySeal(isWelcomePostPresent && (postToCheck.Creator?.email || "").indexOf("victor") < 0);
    };

    const setLanguage = (selectedPost: IPostResponseAttributes, memberUserPreferences?: IUserPreferences) => {
        const defaultLanguage = "Original";
        if (!selectedPost?.ai_summary || !selectedPost?.transcription_text) {
            return; // do not translate if AI summary and transcription text are not available
        }
        const userPreferenceLanguage = authContext?.user?.preferences?.language || memberUserPreferences?.language || "";
        // set language this order - user preference, language set by creator, original language
        const selectedLanguage = userPreferenceLanguage || defaultLanguage || "";
        let selectedLanguageObject = SUPPORTED_LANGUAGES_OBJECTS.find(language => language.key === selectedLanguage);
        setSelectedLanguageObject(selectedLanguageObject);
        if (selectedLanguageObject) translateText(selectedLanguageObject?.key, selectedPost);
        pushTagEvent("e_choose_emovid_translation", { event_id: eventId, post_id: selectedPost?.id, language: selectedLanguage });
    };

    const setAISummaryTranscriptActionItems = (aiSummary: string = "", transcriptionText: string = "", actionItemsText: string = "") => {
        setAISummary(aiSummary);
        setTranscriptionText(transcriptionText);
        setActionItems(actionItemsText);
    };

    const setHLSVideoUrlFromPost = (eventPost: IPostResponseAttributes) => {
        if (isMobile && eventPost.hls_metadata) {
            setHLSVideoUrl(CLOUDFRONT_URL + eventPost.hls_metadata?.metadata_playlist_key);
        } else setHLSVideoUrl("");
    };

    const getPostsForEmovid = async (eventData: IEventAttributes) => {
        try {
            let response: any = await getPostsForEvent(eventId!, searchParams.get("show_post") || props.postId || "", !!authContext.user);
            let posts: IPostResponseAttributes[] = response.posts;
            let deletedPosts: { id: string; deleted_at: Date }[] = response.deleted_posts || [];
            let selectedPost: IPostResponseAttributes | undefined = posts.find((post: IPostResponseAttributes) => post.is_creator);
            setIsEventContainsPrivatePosts(response.is_event_contains_private_posts);
            const creatorPost = posts.find((post: IPostResponseAttributes) => post.is_creator);
            setIsCreatorLoggedIn(creatorPost?.creator_id === authContext.user?.userId);
            for (let i = 0; i < posts.length; i++) {
                const post: any = posts[i];
                setVideoAndThumbnailUrlsForPost(post);
                post.Event = eventData;
                post.viewed_percent = 0;
                post.is_favorite = false;
                if (post.original_urls && (searchParams.get("show_original") || showOriginalVideo)) {
                    post.original_video_url = await generateSignedUrlForFileKey((post.original_urls || []).filter((url: IPostUrl) => url.url_type === "FILE")[0]?.url || "");
                    post.original_thumbnail_url = await generateSignedUrlForFileKey(
                        (post.original_urls || []).filter((url: IPostUrl) => url.url_type === "THUMBNAIL")[0]?.url || ""
                    );
                }
                (post.PostViews || []).forEach((postView: IPostMetadataAttributes) => {
                    post.viewed_percent = Math.max(post.viewed_percent || 0, postView.viewed_percent || 0);
                    if (postView.is_favorite) post.is_favorite = true;
                });
            }
            posts.sort((a, b) => new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf());
            posts.sort((a, b) => (b.is_creator ? 1 : 0) - (a.is_creator ? 1 : 0));
            let unreadPostsCount = 0;
            posts.forEach(post => {
                if ((((post.PostViews || [])[0] || {}).viewed_percent || 0) === 0) unreadPostsCount++;
            });
            setUnreadResponsesCount(unreadPostsCount);
            setEventPosts(posts);
            if (searchParams.get("show_post") || props.postId) {
                posts.forEach((post: IPostResponseAttributes) => {
                    if (post.id === (searchParams.get("show_post") || props.postId)) selectedPost = post;
                });
            }
            let postToShowIsDeleted = false;
            if (searchParams.get("show_post") || props.postId) {
                deletedPosts.forEach((deletedPost: { id: string; deleted_at: Date }) => {
                    if (deletedPost.id === (searchParams.get("show_post") || props.postId)) {
                        postToShowIsDeleted = true;
                        ToastService.userError("The response you're looking for has been deleted!");
                    }
                });
            }
            if (searchParams.get("show_post") && selectedPost?.id !== searchParams.get("show_post") && !postToShowIsDeleted)
                markPostAsSentIfDraft(searchParams.get("show_post") || "", eventData);
            if (!selectedPost) selectedPost = posts[0];
            if (!selectedPost) {
                selectedPost = (eventData?.Posts || [])[0] as IPostResponseAttributes;
                setVideoAndThumbnailUrlsForPost(selectedPost);
            }
            onPostSelected(selectedPost);
            if (eventData.parent_post_id && !isPreviewMode) setupParentThread(eventData, unreadPostsCount);
            setTimeout(() => document.getElementById(searchParams.get("show_post") || "")?.scrollIntoView({ block: "nearest", inline: "nearest", behavior: "smooth" }), 500);
        } catch (error: any) {
            ToastService.alertableError(error.message, error);
        }
    };

    const setupParentThread = async (eventData: IEventAttributes, unreadPostsCount: number) => {
        try {
            let parentThreadUnreadCount = 0;
            let postData: IPostResponseAttributes = await getPostById(eventData.parent_post_id!);
            if ((((postData.PostViews || [])[0] || {}).viewed_percent || 0) === 0) parentThreadUnreadCount++;
            setUnreadResponsesCount(parentThreadUnreadCount + unreadPostsCount);
        } catch (error: any) {
            if (error.message.indexOf("does not exist") >= 0) ToastService.warn("A video was deleted from this thread by the creator");
            else ToastService.alertableError(error.message, error);
        }
    };

    const markPostAsSentIfDraft = async (postId: string, eventData: IEventAttributes) => {
        if (isEmovidDomainUser(authContext.user?.email)) return;
        try {
            let postData: IPostResponseAttributes = await getPostById(postId);
            if (postData?.event_id === eventId && postData?.creator_id !== authContext.user?.userId && postData?.post_status !== "SENT" && !isPreviewMode) {
                await HttpService.putFormData(`/v1/post/${postId}/send`, null, true); // send post
                pushTagEvent("e_share_reply_sent", { event_id: postData?.event_id || "", post_id: postId });
                getPostsForEmovid(eventData);
            }
        } catch (error: any) {
            ToastService.alertableError(error.message, error);
        }
    };

    const markEventAsSent = (eventData = event) => {
        if (isEmovidDomainUser(authContext.user?.email)) return;
        if (eventData?.creator_id !== authContext.user?.userId && eventData?.event_status !== "SENT" && !isPreviewMode) {
            HttpService.putFormData(`/v1/event/update/${eventId}/status`, { status: "SENT" }, true); // update status to SENT
        }
    };

    const handlePrivacySettings = (event: IEventAttributes) => {
        setPrivacySettingsState(undefined);
        setPrivacySettings(undefined);
        if (event && event.CreatorPosts && event.CreatorPosts[0].PrivacySettings) {
            const privacySettings = event.CreatorPosts[0].PrivacySettings;
            if (privacySettings.signed_in_users_can_view_post || privacySettings.recipients_can_view_post || privacySettings.keep_replies_private) {
                setPrivacySettings(privacySettings);
            }
            if (privacySettings.signed_in_users_can_view_post && !authContext.user) {
                setShowLoginDialogForPrivacySettings(true);
                setPrivacySettingsState(PrivacySettingsState.SIGNED_IN_USERS_CAN_VIEW_POST);
            } else if (privacySettings.recipients_can_view_post && !authContext.user) {
                // if privacy settings is enabled and user is not logged in
                setShowLoginDialogForPrivacySettings(true);
                setPrivacySettingsState(PrivacySettingsState.RECIPIENTS_CAN_VIEW_POST_NON_LOGGED_IN);
            } else if (privacySettings.recipients_can_view_post && authContext.user) {
                // if privacy settings is enabled and user is logged in
                // check if logged in user is a member or creator
                const authUser = (event.Members || []).find(member => member.member_id === authContext.user?.userId);
                if (!authUser && authContext.user?.userId !== event.creator_id) {
                    setPrivacySettingsState(PrivacySettingsState.RECIPIENTS_CAN_VIEW_POST_LOGGED_IN);
                }
            }
        }
    };

    const onPostSelected = (post: IPostResponseAttributes) => {
        setPlaybackStartTime(0);
        if (post) {
            setSelectedPost(post);
            if (searchParams.get("member")) {
                getMemberById(searchParams.get("member") || "").then(memberDetails => {
                    setLanguage(post, memberDetails.UserDetails?.preferences);
                });
            } else {
                setLanguage(post);
            }
            setPlayerDialogWidth(getDialogVideoWidth(post));
            setAISummaryTranscriptActionItems(post.ai_summary, post.transcription_text, post.action_items);
            setVideoUrl(post?.video_url || "");
            setHLSVideoUrlFromPost(post);
            if (postViewsAllowed()) updatePostPageViews(post.id || "", getUserId());
            sessionStorage.setItem(currentSelectedPostStorageKey, (post || {}).id || "");
        }
        setDrawerOpen(false);
        pushTagEvent("e_video_impression", { event_id: eventId, post_id: post.id });
    };

    const isLanguageTranslated = () => {
        return selectedLanguageObject?.key !== "Original";
    };

    const translationByText = () => {
        if (isLanguageTranslated()) return <span className="font-light-color translation-set ps-2">[Translated]</span>;
        return "";
    };

    const navToRecordResponse = async () => {
        pushTagEvent("e_inbox_video_thread_reply_all", { event_id: eventId, post_id: selectedPost?.id });
        const emailUserId = getStorageItem(emailRecipientUserStorageKey);
        if (authContext.user?.userId || emailUserId) {
            const post = await createResponse(eventId!, authContext.user?.userId || emailUserId || "");
            navigate(replacePostIdInPath(Paths.recordResponse, post.post?.id!)); // allow sending response
        } else {
            setStorageItem(shouldRedirectToRecordKey, true);
            setShowSignupToPostDialog(true);
        }
    };

    const handleSignupToSaveLogin = () => {
        navigate(Paths.myInbox);
    };

    const onSummaryAndTranscriptChanged = (summary: string, transcript: string, actionItems: string) => {
        if (selectedPost?.id) {
            setAutoSaving(true);
            setAISummaryTranscriptActionItems(summary, transcript, actionItems);
            selectedPost.ai_summary = summary;
            selectedPost.transcription_text = transcript;
            updatePostById(selectedPost?.id, { ai_summary: summary, transcription_text: transcript, action_items: actionItems })
                .then(() => {
                    setAutoSaving(false);
                    props.summaryAndTranscriptUpdated && props.summaryAndTranscriptUpdated(summary, transcript);
                })
                .catch(() => setAutoSaving(false));
        }
    };

    const handleLoginSuccess = async () => {
        setShowNonWhitelistedLoginDialog(false);
        setShowSignupToPostDialog(false);
        setShowSignupToSaveDialog(false);
        if (authContext.user?.userId) {
            markEventAsSent();
            deleteStorageItem(shouldRedirectToRecordKey);
            const post = await createResponse(eventId!, authContext.user?.userId!);
            navigate(replacePostIdInPath(Paths.recordResponse, post.post?.id!));
        }
    };

    const handleLoginForPrivacySettings = async () => {
        setShowLoginDialogForPrivacySettings(false);
    };

    const handleLoginForPrivateReplies = () => {
        setShowLoginDialogForPrivateVideoCreator(false);
    };

    const tabChanged = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedMenu(newValue);
        if ((isMobile ? ResponseRecipientMobileMenu.RECIPIENTS : ResponseRecipientDesktopMenu.RECIPIENTS) === newValue)
            pushTagEvent("e_inbox_video_thread_recipients", { event_id: eventId });
    };

    const switchAccounts = () => {
        authContext.signOut();
        setShowLoginDialogForPrivacySettings(true);
    };

    const selectPreviousVideo = () => {
        let newIndex = selectedPostIndex() - 1;
        if (newIndex < 0) onPostSelected(eventPosts[eventPosts.length - 1]);
        else onPostSelected(eventPosts[newIndex]);
    };

    const selectNextVideo = () => {
        let newIndex = selectedPostIndex() + 1;
        if (newIndex > eventPosts.length - 1) onPostSelected(eventPosts[0]);
        else onPostSelected(eventPosts[newIndex]);
    };

    const selectedPostIndex = () => {
        for (let i = 1; i < eventPosts.length; i++) if (eventPosts[i].id === sessionStorage.getItem(currentSelectedPostStorageKey)) return i;
        return 0;
    };

    const onPlaybackStarted = () => {
        if (isMobile) {
            setSelectedMenu(ResponseRecipientMobileMenu.RESPONSES);
        }
        if (selectedPost?.id) {
            let identify = new amplitude.Identify();
            identify.add("video_play", 1);
            amplitude.identify(identify);
            pushTagEvent("e_video_play_started", {
                event_id: eventId,
                user_id: getUserId(),
                post_id: selectedPost.id
            });
        }
        if (selectedPost?.id && postViewsAllowed()) {
            updatePostVideoViews(selectedPost.id || "", getUserId());
            if (selectedPost.id !== playedVideoPostId) setPlayedVideoPostId(selectedPost.id);
        }
    };

    const postViewsAllowed = (): boolean => {
        if (authContext.user?.userId && isEmovidDomainUser(authContext.user?.email)) {
            let isRecipient = (event?.Members || []).filter(member => member.UserDetails?.email === authContext.user?.email).length > 0;
            if (event?.creator_id === authContext.user?.userId) isRecipient = true;
            return isRecipient;
        }
        return true;
    };

    const sharePost = () => {
        handleClose();
        setShowShareDialog(true);
    };

    const addToFavorites = () => {
        handleClose();
        pushTagEvent("e_video_favorite", { is_favorite: !selectedPost?.is_favorite, event_id: eventId, post_id: selectedPost?.id });
        HttpService.postFormData("/v1/dashboard/toggle/favorite", { post_id: selectedPost?.id })
            .then(data => {
                if (selectedPost) {
                    ToastService.success(selectedPost?.is_favorite ? "Removed from favorites" : "Added to favorites");
                    selectedPost.is_favorite = !selectedPost.is_favorite;
                }
            })
            .catch(error => ToastService.alertableError(error.message || "Unable to toggle favorite", error));
    };

    const copyEmovidClicked = async () => {
        handleClose();
        pushTagEvent("e_created_vids_copy", { post_id: selectedPost?.id, event_id: selectedPost?.event_id });
    };

    const onDeletePostConfirmation = () => {
        pushTagEvent("e_created_vids_delete", { post_id: selectedPost?.id, event_id: selectedPost?.event_id });
        HttpService.delete("/v1/dashboard/delete", { post_id: selectedPost?.id }, false)
            .then(data => {
                refetch();
                removePostIdFromSelected(selectedPost?.id || "");
                setShowDeletePostConfirmation(false);
            })
            .catch(error => {
                ToastService.alertableError(error.message || "Unable to delete post", error);
                setShowDeletePostConfirmation(false);
            });
    };

    const removePostIdFromSelected = (postId: string) => {
        let postIds = (selectedPostIds || []).slice();
        if (postIds.includes(postId)) postIds = postIds.filter(item => item !== postId);
        setSelectedPostIds(postIds);
    };

    const getUserId = (): string => {
        let userId = getStorageItem(emailRecipientUserStorageKey);
        if (authContext?.user) userId = authContext.user?.userId;
        return userId || "";
    };

    const getUserEmail = (): string => {
        if (searchParams.get("member")) {
            let user = JSON.parse(getStorageItem(EmailRecipientUserDetailsStorageKey) || "{}");
            if (authContext?.user?.userId) user = authContext.user;
            return user?.email || "";
        }
        return "";
    };

    const timeUpdateCallback = debounce((postId: string, userId: string, videoViewedPercent: number) => updatePostViewedPercent(postId, userId, videoViewedPercent), 300);

    const onTimeUpdate = (event: DurationUpdateEvent) => {
        const selectedPostId = sessionStorage.getItem(currentSelectedPostStorageKey);
        const videoViewedPercent = Math.round((event.currentTime * 100.0) / event.duration); // update viewed duration every 300 milliseconds
        timeUpdateCallback.cancel();
        if (selectedPostId && videoViewedPercent > 0 && postViewsAllowed()) {
            timeUpdateCallback(selectedPostId || "", getUserId(), videoViewedPercent);
        }
        if (videoViewedPercent === 100) {
            pushTagEvent("e_video_play_ended", {
                event_id: eventId,
                post_id: selectedPostId,
                user_id: getUserId(),
                video_length: event.duration
            });
        } else {
            pushTagEvent("e_video_play_progress", {
                event_id: eventId,
                post_id: selectedPostId,
                user_id: getUserId(),
                video_length: event.duration,
                video_progress: videoViewedPercent
            });
        }
    };

    const getDialogVideoWidth = (post: IPostResponseAttributes): number => {
        let dimensions = post?.urls?.filter(url => url.url_type === "FILE")[0]?.metadata?.dimensions || "480x480";
        let width = parseInt(dimensions.split("x")[0]);
        let height = parseInt(dimensions.split("x")[1]);
        return width > height ? 900 : 400;
    };

    const scrollToTranscript = () => {
        if (["transcript", "notes"].includes(searchParams.get("scroll_to") || "")) {
            if (isMobile) {
                setSelectedMenu(ResponseRecipientMobileMenu.INFO);
                setDrawerOpen(true);
            } else {
                setTimeout(() => {
                    let scrollToElement = document.getElementById("transcript");
                    if (searchParams.get("scroll_to") === "notes") scrollToElement = document.getElementById("notes");
                    let viewportOffset = scrollToElement?.getBoundingClientRect();
                    window.scrollTo({ top: viewportOffset?.top || 0, behavior: "smooth" });
                }, 500);
            }
        }
    };

    const viewsClickedForPost = (post: IPostResponseAttributes) => {
        setShowViewsDialog(true);
        setPostForViewsDialog(post);
        pushTagEvent("e_view_emovids_views_badge", { post_id: post?.id, event_id: post?.event_id });
    };

    const handleCreateThreadLoginSuccess = async () => {
        setShowLoginToCreateThreadDialog(false);
        let parentPostData = JSON.parse(getStorageItem(shouldRedirectToCreateThreadKey) || "{}");
        if (authContext.user?.userId && parentPostData.id) {
            startNewThreadFromPost(parentPostData);
            deleteStorageItem(shouldRedirectToCreateThreadKey);
        }
    };

    const startNewThreadFromPost = async (parentPost: IPostResponseAttributes) => {
        handleClose();
        const emailUserId = getStorageItem(emailRecipientUserStorageKey);
        if (!authContext.user?.userId && !emailUserId) {
            setShowLoginToCreateThreadDialog(true);
            setStorageItem(shouldRedirectToCreateThreadKey, JSON.stringify(parentPost));
            return;
        }
        setCreatingThread(true);
        try {
            let eventData = await HttpService.postFormData(
                `/v1/event/create`,
                {
                    creator_id: authContext.user?.userId || emailUserId,
                    permissions: { is_public: false, is_active: true, is_creator_a_contributor: true },
                    parent_post_id: parentPost.id, // link with parent thread
                    title: event?.title
                },
                false,
                true
            );
            let creatorPostId = eventData.event.CreatorPosts[0].id;
            if (event && event.CreatorPosts && event.CreatorPosts[0].PrivacySettings) {
                let privacySettings = event.CreatorPosts[0].PrivacySettings;
                await HttpService.putFormData(
                    `/v1/post/${creatorPostId}/privacy/settings`,
                    {
                        recipients_can_view_post: privacySettings.recipients_can_view_post,
                        signed_in_users_can_view_post: privacySettings.signed_in_users_can_view_post,
                        keep_replies_private: false // privacySettings.keep_replies_private
                    },
                    true,
                    true
                );
            }
            await HttpService.putFormData(
                `/v1/event/${eventData.event.id}/member/add`,
                { UserDetails: { email: parentPost.Creator?.email }, member_type: "TO", added_by_id: authContext?.user?.userId, added_in_post_id: creatorPostId },
                true
            );
            setCreatingThread(false);
            pushTagEvent("e_created_vids_thread", { parent_post_id: parentPost.id, post_id: creatorPostId, event_id: eventData?.id });
            navigate(replaceEventIdInPath(Paths.recordEmovid, eventData.event.id));
        } catch (error: any) {
            setCreatingThread(false);
            ToastService.alertableError(error.message || "Error creating new Emovid", error);
        }
    };

    const mobileVideoPlayerHeight = () => {
        return window.innerHeight - drawerBleeding - 90;
    };

    const toggleViewedStatus = () => {
        handleClose();
        pushTagEvent("e_btn_view_emovid_toggle_viewed", { event_id: event?.id, post_id: selectedPost?.id! });
        HttpService.postFormData("/v1/dashboard/toggle/view", { post_id: selectedPost?.id })
            .then(data => {
                if (selectedPost) {
                    let wasViewed = (selectedPost.viewed_percent || 0) > 0;
                    selectedPost.is_viewed = !wasViewed;
                    selectedPost.viewed_percent = wasViewed ? 0 : 100;
                    let newCount = unreadResponsesCount + (wasViewed ? 1 : -1);
                    setUnreadResponsesCount(Math.max(0, newCount));
                    onPostSelected(selectedPost);
                }
            })
            .catch(error => ToastService.alertableError(error.message || "Unable to toggle view", error));
    };

    const deletePost = () => {
        handleClose();
        setShowDeletePostConfirmation(true);
    };

    const backBtnClicked = () => {
        const selectedFilter = searchParams.get("selected_filter") || "";
        navigate(
            searchParams.get("back_to") === "resources"
                ? Paths.resources
                : searchParams.get("back_to") === "created_videos"
                ? Paths.createdVideos
                : selectedFilter
                ? `${Paths.myInbox}?selected_filter=${selectedFilter}`
                : Paths.myInbox,
            {
                state: { selectedPostIds: selectedPostIds }
            }
        );
    };

    const backToStep3BtnClicked = () => {
        if (selectedPost?.is_creator) navigate(replaceEventIdInPath(Paths.preview, eventId || ""));
        else navigate(replacePostIdInPath(Paths.previewResponse, selectedPost?.id || ""));
    };

    const handleMoreOptionsClick = (ev: React.MouseEvent<HTMLElement>) => {
        if (isPreviewMode) ToastService.info("Video options are not available in preview");
        else setAnchorEl(ev.currentTarget);
    };

    const editTranscriptClicked = () => {
        if (isDesktop || isTablet) setShowTranscriptEditor(true);
        else ToastService.info("This feature is available on Desktop and Tablets only");
    };

    const translateText = async (language: string, selectedPost: IPostResponseAttributes) => {
        if (!selectedPost) {
            return;
        }
        const selectedLanguageObject = SUPPORTED_LANGUAGES_OBJECTS.find(languageObject => languageObject.key === language);
        if (!selectedLanguageObject) {
            return;
        }
        setSelectedLanguageObject(selectedLanguageObject);
        // don't translate if the language is original language
        if (selectedLanguageObject?.key === "Original") {
            setAISummaryTranscriptActionItems(selectedPost?.ai_summary, selectedPost?.transcription_text, selectedPost?.action_items);
            return;
        }
        setTranslateTextInProgress(true);
        try {
            const summaryTranslationResponse = await HttpService.postFormData(
                `/v1/translate/text`,
                { text: selectedPost?.ai_summary || "", language: selectedLanguageObject.key },
                false,
                true
            );
            const transcriptionTextTranslationResponse = await HttpService.postFormData(
                `/v1/translate/text`,
                { text: selectedPost?.transcription_text || "", language: selectedLanguageObject.key },
                false,
                true
            );
            const actionItemsTranslationResponse = await HttpService.postFormData(
                `/v1/translate/text`,
                { text: selectedPost?.action_items || "", language: selectedLanguageObject.key },
                false,
                true
            );
            setAISummaryTranscriptActionItems(
                summaryTranslationResponse?.translation,
                transcriptionTextTranslationResponse?.translation,
                actionItemsTranslationResponse?.translation
            );
            setTranslateTextInProgress(false);
        } catch (error: any) {
            setTranslateTextInProgress(false);
            setAISummaryTranscriptActionItems(selectedPost?.ai_summary, selectedPost?.transcription_text, selectedPost?.action_items);
            ToastService.alertableError(error.message || "Unable to translate", error);
        }
    };

    const isRecipientsTabDisabled = () => {
        return (isEventContainsPrivatePosts || privacySettings?.keep_replies_private) && (!authContext.user || !isCreatorLoggedIn);
    };

    const canCreatePrivateThread = (): boolean => {
        if (privacySettings?.keep_replies_private && (!authContext.user || authContext.user.userId !== event?.creator_id)) return false;
        return CanCreatePrivateThreadForPost(event, selectedPost, authContext);
    };

    const privateThreadButtonTooltip = () => {
        if (selectedPost?.Creator?.id !== event?.Creator?.email) return `Send a private video to ${displayNameForUser(selectedPost?.Creator!)} (${selectedPost?.Creator?.email})`;
        return `Reply privately to ${displayNameForUser(selectedPost?.Creator!)} (${selectedPost?.Creator?.email})`;
    };

    const replyButtonText = () => {
        if (privacySettings && privacySettings?.keep_replies_private && !isCreatorLoggedIn) return "Private Reply"; // private emovid's recipient viewing emovid
        if ((event?.Members || []).length > 1) return "Reply All";
        // following scenarios are for 1 recipient only (excluding creator)
        const emailUserId = getStorageItem(emailRecipientUserStorageKey) || authContext.user?.userId;
        if (![event?.creator_id, ((event?.Members || [])[0] || {}).member_id].includes(emailUserId)) return "Reply All"; // user is neither creator nor recipient
        return "Reply";
    };

    const replyButtonTooltip = () => {
        if (privacySettings && privacySettings?.keep_replies_private && !isCreatorLoggedIn)
            return `Reply privately to ${displayNameForUser(event?.Creator!)} (${event?.Creator?.email})`; // private emovid's recipient viewing emovid
        if ((event?.Members || []).length > 1) return `Reply to all ${(event?.Members || []).length} Recipients`;
        // following scenarios are for 1 recipient only (excluding creator)
        const emailUserId = getStorageItem(emailRecipientUserStorageKey) || authContext.user?.userId;
        if (!!emailUserId && ![event?.creator_id, ((event?.Members || [])[0] || {}).member_id].includes(emailUserId)) return "Reply to all"; // user is neither creator nor recipient
        if (event?.creator_id === emailUserId && !!((event?.Members || [])[0] || {}).UserDetails)
            return `Reply to ${displayNameForUser(((event?.Members || [])[0] || {}).UserDetails!)} (${(event?.Members || [])[0].UserDetails?.email})`; // user is creator
        if (((event?.Members || [])[0] || {}).member_id === emailUserId) return `Reply to ${displayNameForUser(event?.Creator!)} (${event?.Creator?.email})`; // user is the only recipient
        return "Reply with Video";
    };

    const isSmallScreen = () => {
        return window.innerWidth < 380;
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!selectedPost) {
        return (
            <>
                {!props.eventId && <NavBar theme="dark" />}
                <ViewEmovidSkeleton />
            </>
        );
    }

    if (privacySettingsState) {
        return (
            <>
                {!props.eventId && <NavBar theme="dark" />}
                <UnauthorizeVideo privacySettingsState={privacySettingsState} switchAccounts={switchAccounts} showLoginPopup={() => setShowLoginDialogForPrivacySettings(true)} />
                <Popup isOpen={showLoginDialogForPrivacySettings} onClose={() => setShowLoginDialogForPrivacySettings(false)} className="login-popup">
                    <br></br>
                    <Login
                        defaultEmail={getUserEmail()}
                        handleClose={handleLoginForPrivacySettings}
                        title="This Emovid thread requires an account to access. Please log in"
                    ></Login>
                </Popup>
            </>
        );
    }

    return (
        <>
            {!props.eventId && <NavBar theme="dark" disableSignup={true} />}
            <Desktop handleMediaQueryChange={() => setDrawerOpen(false)}>
                <>
                    <div className="container mt-3 d-flex flex-row-reverse">
                        <div className="plain-white-tabs" style={privacySettings ? { width: "271px", minWidth: "271px" } : { width: "220px", minWidth: "220px" }}></div>
                        <div className="w-100 pe-2 d-flex justify-content-between">
                            <div className="pb-2 pt-2 h5">{event?.title}</div>
                            {isWhitelistedUser(authContext) && (
                                <IconButton onClick={handleMoreOptionsClick} className="ms-auto">
                                    <MoreVertRoundedIcon />
                                </IconButton>
                            )}
                        </div>
                    </div>
                    <div className={"container d-flex flex-row-reverse " + (isPreviewMode ? "" : "mb-5")}>
                        <div className="plain-white-tabs" style={privacySettings ? { width: "271px", minWidth: "271px" } : { width: "220px", minWidth: "220px" }}>
                            <div className="emovid-tabs">
                                <Tabs value={selectedMenu} onChange={tabChanged}>
                                    {responseRecipientTabs.map((tabItem: TabItem, i: number) => (
                                        <Tab
                                            className={i === ResponseRecipientDesktopMenu.RECIPIENTS && isRecipientsTabDisabled() ? "disabled" : ""}
                                            disabled={i === ResponseRecipientDesktopMenu.RECIPIENTS && isRecipientsTabDisabled()}
                                            label={
                                                <Tooltip title={tabItem.title}>
                                                    <Badge
                                                        color={i === ResponseRecipientDesktopMenu.RESPONSES ? "error" : "info"}
                                                        badgeContent={
                                                            i === ResponseRecipientDesktopMenu.RESPONSES
                                                                ? unreadResponsesCount
                                                                : i === ResponseRecipientDesktopMenu.RECIPIENTS && !isRecipientsTabDisabled()
                                                                ? (event?.Members || []).length
                                                                : 0
                                                        }
                                                        max={9}
                                                    >
                                                        <div className="tab-icon-container">{tabItem.icon}</div>
                                                    </Badge>
                                                </Tooltip>
                                            }
                                            key={i}
                                        />
                                    ))}
                                    {privacySettings && (
                                        <Tab
                                            label={
                                                <Tooltip title="Privacy Settings">
                                                    <div className="tab-icon-container">
                                                        <LockIcon />
                                                    </div>
                                                </Tooltip>
                                            }
                                        />
                                    )}
                                </Tabs>
                            </div>
                            <div className="desktop-sidebar-container">
                                {event && (
                                    <ResponsesList
                                        event={event}
                                        isPreviewMode={isPreviewMode}
                                        isVisible={selectedMenu === ResponseRecipientDesktopMenu.RESPONSES}
                                        eventPosts={eventPosts}
                                        selectedPost={selectedPost}
                                        onPostSelected={onPostSelected}
                                        isCreatorLoggedIn={isCreatorLoggedIn}
                                        parentPostId={event?.parent_post_id}
                                    />
                                )}
                                {selectedMenu === ResponseRecipientDesktopMenu.RECIPIENTS && (
                                    <div className="ps-2 pt-3">
                                        <RecipientsList creator={event?.Creator!} members={event?.Members || []} />
                                    </div>
                                )}
                                {selectedMenu === ResponseRecipientDesktopMenu.PRIVACY_SETTINGS && privacySettings && (
                                    <div className="ps-2 pt-3">
                                        <PrivacySettingsList privacySettings={privacySettings} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div id="video-view-container" className="w-100 pe-2">
                            <div className="w-100 view-page-video-container">
                                <VideoPlayer
                                    // autoplay={autoplay}
                                    fluid={false}
                                    customFullscreenButton={true}
                                    url={videoUrl}
                                    hlsUrl={hlsVideoUrl}
                                    thumbnail={selectedPost?.thumbnail_url}
                                    playbackProgressPercent={selectedPost.viewed_percent || 0}
                                    showCustomControls={true}
                                    playbackStartTime={playbackStartTime}
                                    authenticityType={!hideAuthenticitySeal ? selectedPost?.authenticity_type : null}
                                    onReady={scrollToTranscript}
                                    onPreviousVideo={selectPreviousVideo}
                                    onNextVideo={selectNextVideo}
                                    onPlaybackStarted={onPlaybackStarted}
                                    onTimeUpdate={onTimeUpdate}
                                    showTryNow={isNonSignedUpUser}
                                    initialPlayerDialogWidth={playerDialogWidth}
                                    uuid={selectedPost?.id}
                                />
                            </div>
                            <div className="row position-relative font-light-color mt-2 mb-2">
                                <div className="col d-flex">{displayNameForUser(selectedPost.Creator!)}</div>
                                <div className="d-flex col justify-content-end">
                                    {authContext.user?.userId === selectedPost.creator_id && (
                                        <>
                                            {selectedPost && !selectedPost.views_loaded && (
                                                <span>
                                                    <CircularProgress size="0.7rem" className="me-2" />
                                                    Fetching views...&nbsp;
                                                </span>
                                            )}
                                            {selectedPost && selectedPost.views_loaded && (
                                                <div>
                                                    <button className="btn btn-link font-light-color pt-0 pb-0 border-0" onClick={() => viewsClickedForPost(selectedPost)}>
                                                        <u>{getTotalViews(authContext.user?.userId || "", selectedPost)}</u>
                                                    </button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    <span style={{ lineHeight: "28px" }}>{formattedTimeSince(selectedPost?.sent_at || "")}</span>
                                </div>
                            </div>
                            {(aiSummary || transcriptionText) && (
                                <>
                                    <Divider />
                                    <div className="d-flex mb-2 position-relative justify-content-between">
                                        <div className="text-small position-absolute text-center" style={{ top: "10px", left: 0, right: 0 }}>
                                            * AI can sometimes make mistakes. Video is 100% accurate.
                                        </div>
                                        {selectedLanguageObject && (
                                            <div className="d-flex">
                                                <Tooltip title="Translate Summary and Transcript">
                                                    <i className="m-2 fa-solid fa-earth-americas"></i>
                                                </Tooltip>
                                                <SelectLanguage
                                                    defaultOpen={isUrlContainsTranslate}
                                                    onSelectLanguage={translateText}
                                                    selectedLanguageObject={selectedLanguageObject}
                                                    selectedPost={selectedPost}
                                                />
                                            </div>
                                        )}
                                        {selectedPost.creator_id === authContext.user?.userId && (
                                            <div className="btn btn-link font-light-color text-decoration-underline pe-0" style={{ zIndex: "10" }} onClick={editTranscriptClicked}>
                                                Edit
                                            </div>
                                        )}
                                    </div>
                                    {translateTextInProgress && (
                                        <div className="text-center mt-4">
                                            <CircularProgress className="ms-auto mb-2 me-2" size="2rem" />
                                        </div>
                                    )}
                                </>
                            )}
                            <div className="ai-text-container pb-5 mx-3">
                                {!translateTextInProgress && (
                                    <>
                                        <div className="pt-2 mb-4">
                                            <div className="pb-0 mb-1">
                                                <span className="h5">AI Summary</span>
                                                {translationByText()}
                                            </div>
                                            <div
                                                className={
                                                    "white-space-wrap editor-container font-light-color " +
                                                    (selectedLanguageObject?.key === "Arabic" ? "rtl" : "") +
                                                    (!aiSummary ? " fst-italic " : "")
                                                }
                                            >
                                                {aiSummary || "- Summary not available -"}
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-1">
                                                <span className="h5">AI Requests / Action Items</span>
                                                {translationByText()}
                                            </div>
                                            <div
                                                className={
                                                    "white-space-wrap editor-container font-light-color " +
                                                    (selectedLanguageObject?.key === "Arabic" ? "rtl" : "") +
                                                    (!actionItems ? " fst-italic " : "")
                                                }
                                            >
                                                {actionItems || "- None identified -"}
                                            </div>
                                        </div>
                                        {selectedPost.note && (
                                            <div id="notes" className="mb-4 no-margin-para">
                                                <span className="h5">Sender Notes</span>
                                                <div className="editor-container font-light-color" dangerouslySetInnerHTML={{ __html: notesHtmlForPost(selectedPost) }}></div>
                                            </div>
                                        )}
                                        <div id="transcript">
                                            <div className="pb-0 mb-1 d-flex justify-content-between align-items-center">
                                                <div>
                                                    <span className="h5">AI Transcript</span>
                                                    {translationByText()}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "white-space-wrap editor-container font-light-color " +
                                                    (selectedLanguageObject?.key === "Arabic" ? "rtl" : "") +
                                                    (!transcriptionText ? " fst-italic " : "")
                                                }
                                            >
                                                {transcriptionText || "- Transcript not available -"}
                                            </div>
                                        </div>
                                    </>
                                )}
                                {showOriginalVideo && selectedPost && (selectedPost as any).original_video_url && <div className="h5 pb-0 mb-2 mt-3">Original Recording:</div>}
                                {showOriginalVideo && selectedPost && (selectedPost as any).original_video_url && (
                                    <div className="w-100 view-page-video-container">
                                        <VideoPlayer
                                            fluid={false}
                                            hideFullscreenOption={true}
                                            url={(selectedPost as any).original_video_url}
                                            thumbnail={(selectedPost as any).original_thumbnail_url}
                                            showCustomControls={true}
                                            onPreviousVideo={selectPreviousVideo}
                                            onNextVideo={selectNextVideo}
                                            onPlaybackStarted={onPlaybackStarted}
                                            onTimeUpdate={onTimeUpdate}
                                            showTryNow={isNonSignedUpUser}
                                        />
                                    </div>
                                )}
                                {showOriginalVideo && selectedPost && ((selectedPost as any).persona_info || {}).driver_image && (
                                    <div className="h5 pb-0 mb-2 mt-3">Persona Image:</div>
                                )}
                                {showOriginalVideo && selectedPost && ((selectedPost as any).persona_info || {}).driver_image && (
                                    <div className="w-100 view-page-video-container">
                                        <img className="d-block m-auto" src={(selectedPost as any).persona_info.driver_image.url} alt="Driver" style={{ maxHeight: "400px" }} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            </Desktop>
            <Mobile>
                <div id="view-video-player-mobile" className="position-relative view-video-mobile-container">
                    <VideoPlayer
                        height={`${mobileVideoPlayerHeight()}px`}
                        fluid={false}
                        url={videoUrl}
                        hlsUrl={hlsVideoUrl}
                        thumbnail={selectedPost?.thumbnail_url}
                        playbackProgressPercent={selectedPost.viewed_percent || 0}
                        controlBarBottomMargin={drawerHeadingHeight}
                        showCustomControls={true}
                        authenticityType={!hideAuthenticitySeal ? selectedPost?.authenticity_type : null}
                        onReady={scrollToTranscript}
                        onPreviousVideo={selectPreviousVideo}
                        onNextVideo={selectNextVideo}
                        onPlaybackStarted={onPlaybackStarted}
                        onTimeUpdate={onTimeUpdate}
                        showTryNow={isNonSignedUpUser}
                        uuid={selectedPost?.id}
                    />
                    <div className="w-100 position-absolute p-3 pe-0" style={{ top: "0px", backgroundColor: translucentBackgroundColor }}>
                        {isPreviewMode && isTablet && <div className="text-center w-100 h4">PREVIEW</div>}
                        <div className="d-flex w-100">
                            <ReadMore className="h5 w-100" text={event?.title || ""} />
                            {isWhitelistedUser(authContext) && (
                                <IconButton onClick={handleMoreOptionsClick}>
                                    <MoreVertRoundedIcon />
                                </IconButton>
                            )}
                        </div>
                    </div>
                    <SwipeableDrawer
                        anchor="bottom"
                        className="preview-drawer"
                        open={drawerOpen}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                        onClick={() => !drawerOpen && setDrawerOpen(true)}
                        swipeAreaWidth={selectedMenu === ResponseRecipientMobileMenu.INFO && !drawerOpen && aiSummary ? drawerBleeding + 30 : drawerBleeding}
                        disableSwipeToOpen={false}
                        ModalProps={{
                            container: document.getElementById("view-video-player-mobile"),
                            keepMounted: true
                        }}
                    >
                        <StyledBox
                            sx={{
                                position: "absolute",
                                top: selectedMenu === ResponseRecipientMobileMenu.INFO && !drawerOpen && aiSummary ? -(drawerBleeding + 30) : -drawerBleeding,
                                borderTopLeftRadius: 30,
                                borderTopRightRadius: 30,
                                visibility: "visible",
                                pointerEvents: "all", // both click and swipe to open currently do not work: https://github.com/mui/material-ui/issues/30805
                                right: 0,
                                left: 0
                            }}
                        >
                            <div className="w-100 plain-white-tabs emovid-tabs mt-2">
                                <Tabs className="drawer-tabs" centered value={selectedMenu} onChange={tabChanged}>
                                    {mobileResponseRecipientTabs.map((tabItem: TabItem, i: number) => (
                                        <Tab
                                            className={i === ResponseRecipientMobileMenu.RECIPIENTS && isRecipientsTabDisabled() ? "disabled" : ""}
                                            disabled={i === ResponseRecipientMobileMenu.RECIPIENTS && isRecipientsTabDisabled()}
                                            label={
                                                <Tooltip title={tabItem.title}>
                                                    <Badge
                                                        color={i === ResponseRecipientMobileMenu.RESPONSES ? "error" : "info"}
                                                        badgeContent={
                                                            i === ResponseRecipientMobileMenu.RESPONSES
                                                                ? unreadResponsesCount
                                                                : i === ResponseRecipientMobileMenu.RECIPIENTS && !isEventContainsPrivatePosts
                                                                ? (event?.Members || []).length
                                                                : 0
                                                        }
                                                        max={9}
                                                    >
                                                        <div className="tab-icon-container">{tabItem.icon}</div>
                                                    </Badge>
                                                </Tooltip>
                                            }
                                            key={i}
                                        />
                                    ))}
                                    {privacySettings && (
                                        <Tab
                                            label={
                                                <Tooltip title="Privacy Settings">
                                                    <div className="tab-icon-container">
                                                        <LockIcon />
                                                    </div>
                                                </Tooltip>
                                            }
                                        />
                                    )}
                                </Tabs>
                            </div>
                            {selectedMenu === ResponseRecipientMobileMenu.INFO && !drawerOpen && aiSummary && (
                                <div className="p-3 pb-1 pt-1 mb-0 ellipsis">
                                    <span className="h6">AI Summary: </span>
                                    <span className="font-light-color">{aiSummary}</span>
                                </div>
                            )}
                        </StyledBox>
                        <StyledBox
                            sx={{
                                overflow: "visible",
                                position: "relative",
                                marginTop: `${-footerHeight}px`
                            }}
                        >
                            <div className="close-drawer-btn top" style={{ opacity: drawerOpen ? 1 : 0, transition: "opacity 250ms" }} onClick={() => setDrawerOpen(false)}>
                                <CloseRoundedIcon />
                            </div>
                            <div className="plain-white-tabs w-100 position-relative" style={{ height: "50vh" }}>
                                {selectedMenu === ResponseRecipientMobileMenu.INFO && (
                                    <div className="p-3 overflow-auto position-relative" style={{ maxHeight: `calc(50vh - ${footerHeight}px)` }} ref={openDrawer}>
                                        <div className="d-flex justify-content-between font-light-color pt-2 pb-2">
                                            <div>{displayNameForUser(selectedPost.Creator!)}</div>
                                            <div>{formattedTimeSince(selectedPost.sent_at || "")}</div>
                                        </div>
                                        {(aiSummary || transcriptionText) && (
                                            <>
                                                <Divider />
                                                <div className="text-center text-small mt-1">* AI can sometimes make mistakes. Video is 100% accurate.</div>
                                                <div className="mb-3 mt-2 d-flex justify-content-between">
                                                    {selectedLanguageObject && (
                                                        <div className="d-flex">
                                                            <Tooltip title="Translate Summary and Transcript">
                                                                <i className="m-2 fa-solid fa-earth-americas"></i>
                                                            </Tooltip>
                                                            <SelectLanguage
                                                                defaultOpen={isUrlContainsTranslate}
                                                                onSelectLanguage={translateText}
                                                                selectedLanguageObject={selectedLanguageObject}
                                                                selectedPost={selectedPost}
                                                            />
                                                        </div>
                                                    )}
                                                    {selectedPost.creator_id === authContext.user?.userId && (
                                                        <div className="btn btn-link font-light-color text-decoration-underline pe-0" onClick={editTranscriptClicked}>
                                                            Edit
                                                        </div>
                                                    )}
                                                </div>
                                                {translateTextInProgress && (
                                                    <div className="text-center mt-4">
                                                        <CircularProgress className="ms-auto mb-2 me-2" size="2rem" />
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        {!translateTextInProgress && (
                                            <>
                                                <div className="pb-2 mb-0">
                                                    <div className="pb-0 mb-1">
                                                        <span className="h5">AI Summary</span>
                                                        {translationByText()}
                                                    </div>
                                                    <div
                                                        className={
                                                            "white-space-wrap font-light-color " +
                                                            (selectedLanguageObject?.key === "Arabic" ? "rtl" : "") +
                                                            (!aiSummary ? " fst-italic " : "")
                                                        }
                                                    >
                                                        {aiSummary || "- Summary not available -"}
                                                    </div>
                                                </div>
                                                <div className="pb-2 pt-2 mb-0">
                                                    <div className="pb-0 mb-1">
                                                        <span className="h5">AI Requests / Action Items</span>
                                                        {translationByText()}
                                                    </div>
                                                    <div
                                                        className={
                                                            "white-space-wrap font-light-color " +
                                                            (selectedLanguageObject?.key === "Arabic" ? "rtl" : "") +
                                                            (!actionItems ? " fst-italic " : "")
                                                        }
                                                    >
                                                        {actionItems || "- None identified -"}
                                                    </div>
                                                </div>
                                                {selectedPost.note && (
                                                    <div className="pb-2 pt-2 mb-0 no-margin-para">
                                                        <span className="h5">Sender Notes</span>
                                                        <span className="font-light-color" dangerouslySetInnerHTML={{ __html: notesHtmlForPost(selectedPost) }}></span>
                                                    </div>
                                                )}
                                                <div className="pb-2 pt-2 mb-0">
                                                    <div className="pb-0">
                                                        <span className="h5">AI Transcript</span>
                                                        {translationByText()}
                                                    </div>
                                                    <div
                                                        className={
                                                            "white-space-wrap font-light-color " +
                                                            (selectedLanguageObject?.key === "Arabic" ? "rtl" : "") +
                                                            (!transcriptionText ? " fst-italic " : "")
                                                        }
                                                    >
                                                        {transcriptionText || "- Transcript not available -"}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {showJoinWaitlistBtn && <div className="w-100 fw-bold text-center">Try sending your own.</div>}
                                    </div>
                                )}
                                {event && (
                                    <div
                                        className="p-2 overflow-auto position-relative"
                                        style={{ maxHeight: `calc(50vh - ${footerHeight}px)`, display: selectedMenu === ResponseRecipientMobileMenu.RESPONSES ? "block" : "none" }}
                                    >
                                        <MobileResponsesList
                                            event={event}
                                            eventPosts={eventPosts}
                                            selectedPost={selectedPost}
                                            onPostSelected={onPostSelected}
                                            isPreviewMode={isPreviewMode}
                                            isCreatorLoggedIn={isCreatorLoggedIn}
                                            parentPostId={event?.parent_post_id}
                                            onViewsClickedForPost={(post: IPostResponseAttributes) => viewsClickedForPost(post)}
                                            onNewThreadClickedForPost={(post: IPostResponseAttributes) => startNewThreadFromPost(post)}
                                        />
                                    </div>
                                )}
                                {selectedMenu === ResponseRecipientMobileMenu.RECIPIENTS && (
                                    <div className="p-3 overflow-auto position-relative" style={{ maxHeight: `calc(50vh - ${footerHeight}px)` }}>
                                        <RecipientsList creator={event?.Creator!} members={event?.Members || []} />
                                    </div>
                                )}
                                {selectedMenu === ResponseRecipientMobileMenu.PRIVACY_SETTINGS && privacySettings && (
                                    <div className="p-3 overflow-auto position-relative" style={{ maxHeight: `calc(50vh - ${footerHeight}px)` }}>
                                        <PrivacySettingsList privacySettings={privacySettings} />
                                    </div>
                                )}
                            </div>
                        </StyledBox>
                    </SwipeableDrawer>
                </div>
            </Mobile>
            <Dialog onClose={() => setShowViewsDialog(false)} open={showViewsDialog} fullWidth={isMobile} maxWidth={false}>
                {showViewsDialog && (
                    <div
                        className="created-emovids-views-dialog"
                        style={{ minWidth: isMobile ? "320px" : "750px", maxWidth: "100%", maxHeight: "90%", width: isMobile ? "" : "min-content" }}
                    >
                        <div className="w-100 pe-4">{postForViewsDialog?.title || `${postForViewsDialog?.is_creator ? "" : "Re: "}${postForViewsDialog?.Event?.title}`}</div>
                        <IconButton className="text-white position-absolute" style={{ top: "5px", right: "5px" }} onClick={() => setShowViewsDialog(false)}>
                            <i className="fas fa-times"></i>
                        </IconButton>
                        {selectedPostAsCreated && <PostViews post={selectedPostAsCreated} creator={event?.Creator} views={postForViewsDialog?.PostViews || []} />}
                    </div>
                )}
            </Dialog>
            <div className={[!props.eventId ? "fixed-bottom" : "bottom-bar", isPreviewMode ? "d-none" : ""].join(" ")}>
                <div className="container p-3 pe-2 ps-2">
                    <div className="w-100 d-flex justify-content-between flex-row-reverse">
                        <div className="d-flex">
                            {canCreatePrivateThread() && (
                                <Desktop>
                                    <div className="d-flex">
                                        <Tooltip title={privateThreadButtonTooltip()} componentsProps={{ tooltip: { sx: { textAlign: "center" } } }}>
                                            <button className="btn btn-outline-light ms-2" onClick={() => startNewThreadFromPost(selectedPost)}>
                                                {START_PRIVATE_THREAD_BUTTON_TEXT}
                                            </button>
                                        </Tooltip>
                                    </div>
                                </Desktop>
                            )}
                            <Tooltip title={replyButtonTooltip()} componentsProps={{ tooltip: { sx: { textAlign: "center" } } }}>
                                <button className="btn btn-primary ms-2" style={isSmallScreen() ? { fontSize: "14px" } : {}} onClick={navToRecordResponse}>
                                    {replyButtonText()}
                                </button>
                            </Tooltip>
                        </div>
                        {canCreatePrivateThread() && (
                            <Mobile>
                                <div className="d-flex">
                                    <Tooltip title={privateThreadButtonTooltip()} componentsProps={{ tooltip: { sx: { textAlign: "center" } } }}>
                                        <button
                                            className="btn btn-outline-light ms-2"
                                            style={isSmallScreen() ? { fontSize: "14px" } : {}}
                                            onClick={() => startNewThreadFromPost(selectedPost)}
                                        >
                                            {START_PRIVATE_THREAD_BUTTON_TEXT}
                                        </button>
                                    </Tooltip>
                                </div>
                            </Mobile>
                        )}
                        {((showBackButton && isWhitelistedUser(authContext)) || showJoinWaitlistBtn || !isMobile) && (
                            <div className="d-flex" style={{ minWidth: "20px" }}>
                                {showBackButton && isWhitelistedUser(authContext) && (
                                    <Tooltip title="Go to Dashboard">
                                        <button className="btn btn-outline-light gray-btn me-4" onClick={backBtnClicked}>
                                            <Desktop>Back</Desktop>
                                            <Mobile>
                                                <ArrowBackIosNewRoundedIcon />
                                            </Mobile>
                                        </button>
                                    </Tooltip>
                                )}
                                {showJoinWaitlistBtn && <WaitlistButton className="btn-outline-light me-4" text="Join the Waitlist"></WaitlistButton>}
                                <Desktop>
                                    <div className="mt-2">Video messaging for business is finally here</div>
                                </Desktop>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {isPreviewMode && isMobile && (
                <div className={[!props.eventId ? "fixed-bottom" : "bottom-bar"].join(" ")}>
                    <div className="container p-3 pe-0 ps-0">
                        <div className="w-100 d-flex justify-content-center">
                            <Tooltip title="Go back to Step-3 to send Emovid">
                                <button className="btn btn-primary" onClick={backToStep3BtnClicked}>
                                    Close Preview
                                </button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            )}
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={postMenuOpen}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{ paper: menuProps }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <div className="pb-2 ps-3 fst-italic">Video Options</div>
                <MenuItem onClick={sharePost}>
                    <ListItemIcon>
                        <ShareIcon fontSize="small" />
                    </ListItemIcon>
                    Share Link
                </MenuItem>
                <MenuItem onClick={addToFavorites}>
                    <ListItemIcon>
                        <StarRoundedIcon fontSize="small" />
                    </ListItemIcon>
                    {selectedPost?.is_favorite ? "Remove From Favorites" : "Add To Favorites"}
                </MenuItem>
                {authContext.user?.userId === selectedPost?.creator_id && selectedPostAsCreated && (
                    <CopyEmovidMenuButton postForMenu={selectedPostAsCreated} onClick={copyEmovidClicked} onLoadingChange={(isLoading: boolean) => setCreatingEmovid(isLoading)} />
                )}
                {authContext.user?.userId && selectedPost && authContext.user?.userId !== selectedPost?.creator_id && (
                    <MenuItem onClick={toggleViewedStatus}>
                        <ListItemIcon>
                            <VisibilityIcon fontSize="small" />
                        </ListItemIcon>
                        {(selectedPost?.viewed_percent || 0) > 0 ? "Mark As Unviewed" : "Mark As Viewed"}
                    </MenuItem>
                )}
                {authContext.user?.userId === event?.creator_id && selectedPostAsCreated && canEditSecuritySettings(selectedPostAsCreated) && (
                    <MenuItem onClick={() => setSecuritySettingsPopupOpen(true)}>
                        <ListItemIcon>
                            <LockIcon fontSize="small" />
                        </ListItemIcon>
                        Edit Security Settings
                    </MenuItem>
                )}
                {authContext.user?.userId === selectedPost.creator_id && (
                    <MenuItem onClick={deletePost}>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        Delete
                    </MenuItem>
                )}
            </Menu>
            {selectedPostAsCreated && (
                <PrivacySettingsDialog post={selectedPostAsCreated} open={securitySettingsPopupOpen} onClose={() => setSecuritySettingsPopupOpen(false)} onSuccess={refetch} />
            )}
            <ShareEventDialog
                title={event?.title}
                emovidId={event?.id}
                postId={selectedPost?.id}
                show={showShareDialog}
                userId={getUserId()}
                creatorName={selectedPost?.Creator?.name || selectedPost?.Creator?.email.split("@")[0]}
                isSecureEvent={privacySettings?.recipients_can_view_post || privacySettings?.signed_in_users_can_view_post}
                emailThumbnailUrl={selectedPost?.email_thumbnail_url}
                gifUrl={showGifInCopy ? selectedPost?.gif_url : ""}
                duration={selectedPost?.urls?.filter(url => url.url_type === "FILE")[0]?.metadata?.duration}
                onCancelClick={() => setShowShareDialog(false)}
                aiSummary={selectedPost?.ai_summary}
                notes={selectedPost?.note}
            ></ShareEventDialog>
            <ConfirmDialog
                show={showDeletePostConfirmation}
                message="Are you sure you want to delete this post?"
                onConfirmClick={onDeletePostConfirmation}
                onCancelClick={() => setShowDeletePostConfirmation(false)}
            />
            <Popup isOpen={showSignupToSaveDialog} onClose={() => setShowSignupToSaveDialog(false)} className="login-popup">
                <Login handleClose={handleSignupToSaveLogin} title="Please login to save this video"></Login>
            </Popup>
            <Popup isOpen={showSignupToPostDialog} onClose={() => setShowSignupToPostDialog(false)} className="login-popup">
                <Login handleClose={handleLoginSuccess} title="To reply please..." dividerText="OR, continue with e-mail" disableSignup={true}></Login>
            </Popup>
            <Popup isOpen={showLoginToCreateThreadDialog} onClose={() => setShowLoginToCreateThreadDialog(false)} className="login-popup">
                <Login handleClose={handleCreateThreadLoginSuccess} title="To create a thread please..." dividerText="OR, continue with e-mail" disableSignup={true}></Login>
            </Popup>
            <Popup className="login-popup" isOpen={showNonWhitelistedLoginDialog} onClose={() => setShowNonWhitelistedLoginDialog(false)}>
                <Login handleClose={handleLoginSuccess} title="Sorry! This functionality is restricted.<br>Please join the waitlist for early access."></Login>
            </Popup>
            <Popup isOpen={showLoginDialogForPrivateVideoCreator} onClose={() => setShowLoginDialogForPrivateVideoCreator(false)} className="login-popup">
                <Login defaultEmail={getUserEmail()} handleClose={handleLoginForPrivateReplies} title="This is a private thread. Please log in to see the replies"></Login>
            </Popup>
            {showTranscriptEditor && (
                <EditTranscriptAndSummary
                    open={showTranscriptEditor}
                    transcript={transcriptionText}
                    summary={aiSummary}
                    actionItems={actionItems}
                    autoSaving={autoSaving}
                    onSave={onSummaryAndTranscriptChanged}
                    onClose={() => setShowTranscriptEditor(false)}
                />
            )}
            <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={creatingThread}>
                <CircularProgress color="inherit" /> &nbsp;Starting a new conversation ...
            </Backdrop>
            <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={creatingEmovid}>
                <CircularProgress color="inherit" /> &nbsp;Making a copy ...
            </Backdrop>
        </>
    );
};

export default ViewEmovid;
