import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import { Avatar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router";
import { determineNavType, menuProps, formattedTimeSaved } from "../../Common/Helper";
import { AuthContext } from "../../Contexts/AuthContext";
import CreateEmovidButton from "../../Common/CreateEmovidButton";
import { Paths } from "../../Common/AppRoutes";
import { isWhitelistedUser } from "../../Common/GuardedRoute";
import NotificationsIconButton from "./NotificationsIconButton";
import { NavType } from "./NavBar";
import Popup from "../../Common/Popup";
import Login from "../Login/Login";
import CreatedVideosIcon from "../../Icons/CreatedVideosIcon";
import MoviIcon from "../../Icons/MoviIcon";
import ContactSalesIcon from "../../Icons/ContactSalesIcon";
import { ITimeSavedData } from "../../Interfaces/ITimeSavedData";
import AnimatedTextBlurbs from "../../VideoLib/Common/AnimatedTextBlurbs";

export const NavLinksContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;

interface NavLinksProps {
    showDashboardView?: boolean;
    hideCreateEmovidButton?: boolean;
    hideCreateEmovidButtonInMenu?: boolean;
    disableSignup?: boolean;
    timeSavedData?: ITimeSavedData;
    showTimeSaved?: boolean;
}

const NavLinks: FC<NavLinksProps> = (props: NavLinksProps) => {
    const labelForTime = (timeMinutes: number) => {
        return timeMinutes > 1440 ? "DAY : HRS" : "HRS : MIN";
    };
    const timeSavedDurationLabels = ["All time", "Last 7 days"];
    const timeSavedLabels = [labelForTime(props.timeSavedData?.total_time_saved || 0), labelForTime(props.timeSavedData?.weekly_time_saved || 0)];
    const timeSavedValues = [formattedTimeSaved(props.timeSavedData?.total_time_saved), formattedTimeSaved(props.timeSavedData?.weekly_time_saved)];
    const authContext = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [navType, setNavType] = useState(NavType.default);
    const [isLoginOpen, setLoginOpen] = useState<boolean>(false);
    const [disableBackdropDismiss, setDisableBackdropDismiss] = useState<boolean>(false);

    useEffect(() => {
        setNavType(determineNavType(location.pathname));
    }, [location, authContext.user]);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const dashboardClicked = () => {
        handleClose();
        navigate(Paths.dashboard);
    };

    const myAccountClicked = () => {
        handleClose();
        navigate(Paths.myAccount);
    };

    const getSupportClicked = () => {
        handleClose();
        navigate(Paths.getSupport);
    };

    const sendFeedbackClicked = () => {
        handleClose();
        navigate(Paths.sendFeedback);
    };

    const usageGuideClicked = () => {
        handleClose();
        navigate(Paths.usageGuide);
    };

    const logoutClicked = () => {
        handleClose();
        authContext.signOut();
        navigate(Paths.landing);
    };

    const createdVideosClicked = () => {
        handleClose();
        navigate(Paths.createdVideos);
    };

    const useCasesClicked = () => {
        handleClose();
        navigate(Paths.useCases);
    };

    const salesClicked = () => {
        handleClose();
        navigate(Paths.contactSales);
    };

    const supportClicked = () => {
        handleClose();
        navigate(Paths.getSupport);
    };

    const signInClicked = () => {
        localStorage.setItem("waitlist_signup", "true");
        setLoginOpen(true);
    };

    const loginComplete = (reload = true) => {
        setLoginOpen(false);
        setDisableBackdropDismiss(false);
        localStorage.setItem("waitlist_signup", "true");
        if (sessionStorage.getItem("promo_code") && !isWhitelistedUser(authContext)) {
            navigate(Paths.landing);
        }
        if (reload) window.location.reload();
    };

    const showExtraLinks = (): boolean => {
        if (window.location.pathname.indexOf("/edit-video") >= 0 || window.location.pathname.indexOf("/record") >= 0 || window.location.pathname.indexOf("/preview") >= 0)
            return false;
        return !authContext.user;
    };

    const showNonDismissableLoginDialog = () => {
        setDisableBackdropDismiss(true);
        setLoginOpen(true);
    };

    return (
        <NavLinksContainer>
            <Fragment>
                {navType === NavType.default && (
                    <Box sx={{ display: "flex", alignItems: "center", textAlign: "center", position: "relative" }}>
                        {props.timeSavedData && props.showTimeSaved && (
                            <table className="position-absolute" style={{ left: 0, transform: "translateX(-110%)", minWidth: "160px" }}>
                                <tbody>
                                    <tr>
                                        <td className="pt-0 pb-0 text-right text-small fw-bold">TIME SAVED</td>
                                        <td className="pt-0 pb-0 ps-2 pe-2 text-small fw-bold">
                                            {labelForTime(props.timeSavedData?.total_time_saved) === labelForTime(props.timeSavedData?.weekly_time_saved) && (
                                                <>{labelForTime(props.timeSavedData?.weekly_time_saved)}</>
                                            )}
                                            {labelForTime(props.timeSavedData?.total_time_saved) !== labelForTime(props.timeSavedData?.weekly_time_saved) && (
                                                <AnimatedTextBlurbs containerClasses="d-block" textElementClasses="text-right" items={timeSavedLabels} duration={10000} />
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pt-1 pb-0">
                                            <AnimatedTextBlurbs containerClasses="d-block" textElementClasses="text-right" items={timeSavedDurationLabels} duration={10000} />
                                        </td>
                                        <td className="pt-1 pb-0 ps-2 pe-2">
                                            <AnimatedTextBlurbs containerClasses="d-block" textElementClasses="time-saving digital" items={timeSavedValues} duration={10000} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                        {showExtraLinks() && (
                            <button className="btn btn-link svg-shadow" onClick={useCasesClicked}>
                                Use Cases
                            </button>
                        )}
                        {showExtraLinks() && (
                            <button className="btn btn-link svg-shadow" onClick={usageGuideClicked}>
                                Usage Guide
                            </button>
                        )}
                        {showExtraLinks() && (
                            <button className="btn btn-link svg-shadow" onClick={salesClicked}>
                                Sales
                            </button>
                        )}
                        {showExtraLinks() && (
                            <button className="btn btn-link svg-shadow" onClick={supportClicked}>
                                Support
                            </button>
                        )}
                        {!authContext.user?.userId && (
                            <button className="btn btn-link svg-shadow" onClick={signInClicked}>
                                Sign In
                            </button>
                        )}
                        {authContext.user && (
                            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                                {isWhitelistedUser(authContext) && !props.hideCreateEmovidButton && <CreateEmovidButton location="nav_bar" />}
                                {isWhitelistedUser(authContext) && <NotificationsIconButton />}
                                <Tooltip title={(authContext.user?.name || authContext.user?.email.split("@")[0]) + " (" + authContext.user?.email + ")"}>
                                    <IconButton className="p-0 ms-2 user-avatar" onClick={handleClick}>
                                        <Avatar alt={authContext.user?.name || authContext.user?.email} src={authContext.user.profileImageUrl || "/static/images/avatar/3.jpg"} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                )}
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={menuOpen}
                    onClose={handleClose}
                    onClick={handleClose}
                    slotProps={{ paper: menuProps }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                    {isWhitelistedUser(authContext) && !props.hideCreateEmovidButtonInMenu && (
                        <CreateEmovidButton onSuccess={handleClose} isMenuButton={true} buttonText="Create New Video" location="nav_menu"></CreateEmovidButton>
                    )}
                    {isWhitelistedUser(authContext) && (
                        <MenuItem onClick={dashboardClicked}>
                            <ListItemIcon>
                                <DashboardIcon fontSize="small" />
                            </ListItemIcon>
                            My Inbox
                        </MenuItem>
                    )}
                    {isWhitelistedUser(authContext) && (
                        <MenuItem onClick={createdVideosClicked}>
                            <ListItemIcon>
                                <CreatedVideosIcon style={{ width: "1.2rem", height: "1.2rem" }} />
                            </ListItemIcon>
                            Created Videos
                        </MenuItem>
                    )}
                    <MenuItem onClick={usageGuideClicked}>
                        <ListItemIcon>
                            <MoviIcon width="24px" />
                        </ListItemIcon>
                        Usage Guide
                    </MenuItem>
                    {isWhitelistedUser(authContext) && (
                        <MenuItem onClick={myAccountClicked}>
                            <ListItemIcon>
                                <ManageAccountsRoundedIcon fontSize="small" />
                            </ListItemIcon>
                            My Account
                        </MenuItem>
                    )}
                    {isWhitelistedUser(authContext) && <Divider className="ms-3 me-3" />}
                    {authContext.user?.userId && (
                        <MenuItem onClick={getSupportClicked}>
                            <ListItemIcon>
                                <HelpRoundedIcon fontSize="small" />
                            </ListItemIcon>
                            Get Support
                        </MenuItem>
                    )}
                    {authContext.user?.userId && (
                        <MenuItem onClick={sendFeedbackClicked}>
                            <ListItemIcon>
                                <ThumbUpAltRoundedIcon fontSize="small" />
                            </ListItemIcon>
                            Send Feedback
                        </MenuItem>
                    )}
                    <MenuItem onClick={salesClicked}>
                        <ListItemIcon>
                            <ContactSalesIcon />
                        </ListItemIcon>
                        Contact Sales
                    </MenuItem>
                    <MenuItem onClick={logoutClicked}>
                        <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
            </Fragment>
            <Popup isOpen={isLoginOpen} disableBackdropDismiss={disableBackdropDismiss} onClose={() => setLoginOpen(false)} className="login-popup">
                <Login
                    title={disableBackdropDismiss ? "Please login to continue" : ""}
                    disableCloseBtn={disableBackdropDismiss}
                    handleClose={loginComplete}
                    disableSignup={props.disableSignup !== undefined ? props.disableSignup : true}
                />
            </Popup>
            <button
                id="session-expiry-hidden-login-btn"
                className="position-absolute"
                style={{ top: "-50000px", left: "-50000px" }}
                onClick={showNonDismissableLoginDialog}
            ></button>
        </NavLinksContainer>
    );
};

export default NavLinks;
