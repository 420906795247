import { FC } from "react";
import { Divider } from "@mui/material";
import { IPrivacySettingsAttributes } from "@emovid/payloads-library";

interface PrivacySettingsListProps {
    privacySettings: IPrivacySettingsAttributes;
}

const PrivacySettingsList: FC<PrivacySettingsListProps> = ({ privacySettings }: PrivacySettingsListProps) => {
    return (
        <>
            <Divider className="text-white">Privacy & Security</Divider>
            <div className="mt-3">
                <ul>
                    {privacySettings.signed_in_users_can_view_post && <li>Login Required</li>}
                    {privacySettings.recipients_can_view_post && <li>Recipients Only</li>}
                    {privacySettings.keep_replies_private && <li>Bcc Mode (Private)</li>}
                </ul>
            </div>
        </>
    );
};

export default PrivacySettingsList;
