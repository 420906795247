import { FC, useContext, useEffect, useState } from "react";
import { AuthContext } from "../Contexts/AuthContext";
import { isWhitelistedUser } from "../Common/GuardedRoute";
import { pushTagEvent } from "./Helper";

interface WaitlistButtonProps {
    userData?: any;
    text?: string;
    userEmail?: string;
    className?: string;
}

const WaitlistButton: FC<WaitlistButtonProps> = ({ className, userData, userEmail, text }: WaitlistButtonProps) => {
    const authContext = useContext(AuthContext);
    const [email, setEmail] = useState<string>(userEmail || "");
    const [referrer, setReferrer] = useState<string>("");

    useEffect(() => {
        if ((authContext.user && !isWhitelistedUser(authContext)) || userData) {
            let email = (authContext.user || {}).email || "";
            if ((userData || {}).email && !email) {
                email = userData.email;
            }
            setEmail(email);
            setReferrer(localStorage.getItem("prefinery_referrer") || "");
        }
    }, [authContext, userData]);

    useEffect(() => {
        if (userEmail) setEmail(userEmail);
    }, [userEmail]);

    const handleButtonClick = () => {
        pushTagEvent("e_waitlist_signup_show", { email, location: "landing" });
    };

    return (
        <button
            className={"prefinery-form-cta btn " + (className || "btn-primary text-white mb-3 px-3")}
            data-prefinery-prefill-email={email}
            data-prefinery-prefill-referrer={referrer}
            onClick={handleButtonClick}
        >
            {text || (authContext?.user?.userId ? "You are on the waitlist" : "Join our Waitlist")}
        </button>
    );
};

export default WaitlistButton;
