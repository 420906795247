import { AutoLinkPlugin } from "./LexicalAutoLinkPlugin";

export const URL_MATCHER =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{1,}|www\.[a-zA-Z0-9]+\.[^\s]{1,})/;

export const EMAIL_MATCHER = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

const MATCHERS = [
    (text: string) => {
        const match = URL_MATCHER.exec(text);
        return (
            match && {
                index: match.index,
                length: match[0].length,
                text: match[0],
                url: match[0]
            }
        );
    },
    (text: string) => {
        const match = EMAIL_MATCHER.exec(text);
        return (
            match && {
                index: match.index,
                length: match[0].length,
                text: match[0],
                url: `mailto:${match[0]}`
            }
        );
    }
];

export default function PlaygroundAutoLinkPlugin() {
    return <AutoLinkPlugin matchers={MATCHERS} />;
}
