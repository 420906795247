import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import styled from "styled-components";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} arrow enterTouchDelay={0} />)(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "white",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 280,
        border: "1px solid #dadde9"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "white",
        fontSize: "large",
        "&::before": {
            backgroundColor: "white",
            border: "1px solid #dadde9"
        }
    }
}));
