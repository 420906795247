import { Dialog, DialogTitle, DialogActions, IconButton } from "@mui/material";

interface ConfirmDialogProps {
    show: boolean;
    title?: string;
    message?: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    onConfirmClick: Function;
    onCancelClick: Function;
    showCloseButton?: boolean;
    hideCancelButton?: boolean;
    hideConfirmButton?: boolean;
    centeredMessage?: boolean;
    onClose?: Function;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
    const { show, title, message, cancelButtonText, confirmButtonText, onConfirmClick, onCancelClick, showCloseButton, hideCancelButton, hideConfirmButton, onClose } = props;
    return (
        <Dialog open={show || false} maxWidth="sm" fullWidth>
            <DialogTitle>{title || "Confirm"}</DialogTitle>
            {showCloseButton && (
                <IconButton className="text-white position-absolute" style={{ top: "5px", right: "5px" }} onClick={() => onClose && onClose()}>
                    <i className="fas fa-times"></i>
                </IconButton>
            )}
            <p
                className={"dialog-message ps-4 pe-4 " + (props.centeredMessage ? "text-center" : "")}
                dangerouslySetInnerHTML={{ __html: message || "Are you sure you want to proceed?" }}
            ></p>
            <DialogActions className={"ps-4 pe-4 " + (hideConfirmButton ? "justify-content-around" : "justify-content-between")}>
                {!hideCancelButton && (
                    <button className="cancel-button btn btn-outline-secondary" onClick={() => onCancelClick()}>
                        {cancelButtonText || "Cancel"}
                    </button>
                )}
                {!hideConfirmButton && (
                    <button className="confirm-button btn btn-primary" onClick={() => onConfirmClick()}>
                        {confirmButtonText || "Confirm"}
                    </button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
