import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from "axios";
import * as crypto from "crypto-js";
import { API_KEY, BACKEND_URL, ENCRYPTION_KEY } from "../Common/Constants";

const EXCLUDE_ROUTES = [
    "/v1/ssr/info",
    "/v1/thumbnail/upload",
    "/v1/thumbnail/encoded/upload",
    "/v1/persona/create",
    "/v1/user/profile/image/upload",
    "/v1/reset-password/validate",
    "/v1/reset-password/update",
    "/v1/upload/thumbnail",
    "/v1/generate/sign/url",
    "/v1/thumbnail/generate",
    "/v1/video/progress",
    "/v1/generate/sign/info",
    "/v1/user/background/upload",
    "/v1/video/chunk/upload",
    "/v1/video/chunk/combine",
    "/v1/video/upload/initiate",
    "/v1/video/generate/urls",
    "/v1/video/upload/complete",
    "/v1/video/upload/abort"
];
const EXCLUDE_SESSION_ERROR_ROUTES = ["/v1/dashboard/time/saved", "/v1/post/unviewed/count", "/v1/siteverify/token", "/v1/dashboard/posts/welcome"];
export const PLUS = "+";
export const REPLACE_VALUE = "xMl3Jk";
export const REGEX = /xMl3Jk/g;
export const PLUS_REGEX = "\\+";
export const GLOBAL_FLAG_REGEX = "g";

export default class HttpService {
    static encryptionKey = ENCRYPTION_KEY;

    static handleResponse(url: string, response: AxiosResponse, encryptionKey: any, axiosError: AxiosError | null = null): any {
        if (axiosError) {
            if (axiosError.code === "ERR_NETWORK") {
                console.log("No network found!", url);
                return {
                    status: 500,
                    message: "No network found! Please connect your device to the internet."
                };
            } else {
                const decryptedResponse: any = this.decryptResponse(url, response, encryptionKey);
                const errorMessage = decryptedResponse ? (decryptedResponse.data ? decryptedResponse.data.message : "") : "";

                return {
                    status: decryptedResponse.status,
                    message: errorMessage
                };
            }
        } else {
            const decryptedResponse: any = this.decryptResponse(url, response, encryptionKey);
            return decryptedResponse.data;
        }
    }

    static async get(path: string): Promise<any> {
        try {
            if (localStorage.getItem("userData")) {
                const userData = JSON.parse(localStorage.getItem("userData") || "");
                const headers = {
                    "x-access-token": userData.token
                };
                const request = this.encryptRequest("get", BACKEND_URL + path, headers, this.encryptionKey);
                const response = await axios(request);
                return Promise.resolve(this.handleResponse(path, response, this.encryptionKey));
            } else {
                if (!EXCLUDE_SESSION_ERROR_ROUTES.includes(path)) document.getElementById("session-expiry-hidden-login-btn")?.click();
                return Promise.reject({ message: "Your session has expired. Please login again to continue." });
            }
        } catch (error: any) {
            if (error?.response?.status === 403 && !EXCLUDE_SESSION_ERROR_ROUTES.includes(path)) {
                document.getElementById("session-expiry-hidden-login-btn")?.click();
                return Promise.reject({ message: "Your session has expired. Please login again to continue." });
            } else return Promise.reject(this.handleResponse(path, error.response, this.encryptionKey, error));
        }
    }

    static async getWithAPIKey(path: string): Promise<any> {
        try {
            const headers = {
                authorization: `Basic ${API_KEY}`
            };
            const request = this.encryptRequest("get", BACKEND_URL + path, headers, this.encryptionKey);
            const response = await axios(request);
            return Promise.resolve(this.handleResponse(path, response, this.encryptionKey));
        } catch (error: any) {
            if (error?.response?.status === 403 && !EXCLUDE_SESSION_ERROR_ROUTES.includes(path)) {
                document.getElementById("session-expiry-hidden-login-btn")?.click();
                return Promise.reject({ message: "Your session has expired. Please login again to continue." });
            } else return Promise.reject(this.handleResponse(path, error.response, this.encryptionKey, error));
        }
    }

    static async getWithCustomHeaders(path: string, customHeaders: any): Promise<any> {
        try {
            const request = this.encryptRequest("get", BACKEND_URL + path, customHeaders, this.encryptionKey);
            const response = await axios(request);
            return Promise.resolve({
                data: response.data,
                headers: response.headers
            });
        } catch (error: any) {
            if (error?.response?.status === 403 && !EXCLUDE_SESSION_ERROR_ROUTES.includes(path)) {
                document.getElementById("session-expiry-hidden-login-btn")?.click();
                return Promise.reject({ message: "Your session has expired. Please login again to continue." });
            } else return Promise.reject(this.handleResponse(path, error.response, this.encryptionKey, error));
        }
    }

    static async postFormData(path: string, formData: any, isMultipartFormRequest?: boolean, isAPiKey = false): Promise<any> {
        try {
            let headers: any;
            if (isMultipartFormRequest) {
                headers = {
                    "content-type": "multipart/form-data"
                };
            }
            if (localStorage.getItem("userData")) {
                const userData = JSON.parse(localStorage.getItem("userData") || "")!;
                headers = {
                    "content-type": isMultipartFormRequest ? "multipart/form-data" : "application/json",
                    "x-access-token": userData.token
                };
            }
            if (isAPiKey) {
                headers = {
                    authorization: `Basic ${API_KEY}`
                };
            }
            const request = this.encryptRequest("post", BACKEND_URL + path, headers, this.encryptionKey, formData);
            const response = await axios(request);
            return Promise.resolve(this.handleResponse(path, response, this.encryptionKey));
        } catch (error: any) {
            if (error?.response?.status === 403 && !EXCLUDE_SESSION_ERROR_ROUTES.includes(path)) {
                document.getElementById("session-expiry-hidden-login-btn")?.click();
                return Promise.reject({ message: "Your session has expired. Please login again to continue." });
            } else return Promise.reject(this.handleResponse(path, error.response, this.encryptionKey, error));
        }
    }

    static async putFormData(path: string, formData: any, isAPiKey = false, customHeaders?: any): Promise<any> {
        try {
            let headers: any;
            if (localStorage.getItem("userData")) {
                const userData = JSON.parse(localStorage.getItem("userData") || "")!;
                headers = {
                    "content-type": "application/json",
                    "x-access-token": userData.token
                };
            }
            if (isAPiKey) {
                headers = {
                    authorization: `Basic ${API_KEY}`
                };
            }
            if (customHeaders) {
                headers = {
                    ...headers,
                    ...customHeaders
                };
            }
            const request = this.encryptRequest("put", BACKEND_URL + path, headers, this.encryptionKey, formData);
            const response = await axios(request);
            return Promise.resolve(this.handleResponse(path, response, this.encryptionKey));
        } catch (error: any) {
            if (error?.response?.status === 403 && !EXCLUDE_SESSION_ERROR_ROUTES.includes(path)) {
                document.getElementById("session-expiry-hidden-login-btn")?.click();
                return Promise.reject({ message: "Your session has expired. Please login again to continue." });
            } else return Promise.reject(this.handleResponse(path, error.response, this.encryptionKey, error));
        }
    }

    static async delete(path: string, data: any = null, isAPiKey = false): Promise<any> {
        try {
            let headers;
            if (localStorage.getItem("userData")) {
                const userData = JSON.parse(localStorage.getItem("userData") || "");
                headers = {
                    "x-access-token": userData.token
                };
            }
            if (isAPiKey) {
                headers = {
                    authorization: `Basic ${API_KEY}`
                };
            }
            const request = this.encryptRequest("delete", BACKEND_URL + path, headers, this.encryptionKey, data);
            const response = await axios(request);
            return Promise.resolve(this.handleResponse(path, response, this.encryptionKey));
        } catch (error: any) {
            if (error?.response?.status === 403 && !EXCLUDE_SESSION_ERROR_ROUTES.includes(path)) {
                document.getElementById("session-expiry-hidden-login-btn")?.click();
                return Promise.reject({ message: "Your session has expired. Please login again to continue." });
            } else return Promise.reject(this.handleResponse(path, error.response, this.encryptionKey, error));
        }
    }

    static encryptRequest(method: Method, url: string, authHeaders: any, encryptionKey: any, body?: any): AxiosRequestConfig {
        const request: AxiosRequestConfig = {
            method,
            url,
            headers: {
                "Content-Type": "text/plain",
                Accept: "text/plain"
            }
        };
        let encryptedHeaders;
        if (authHeaders) {
            encryptedHeaders = crypto.AES.encrypt(JSON.stringify(authHeaders), encryptionKey!).toString().replace(new RegExp(PLUS_REGEX, GLOBAL_FLAG_REGEX), REPLACE_VALUE);
        }
        if (encryptionKey && !EXCLUDE_ROUTES.find(route => url.indexOf(route) >= 0) && !(body instanceof FormData)) {
            if (body) {
                const encryptedBody = crypto.AES.encrypt(JSON.stringify(body), encryptionKey).toString().replace(new RegExp(PLUS_REGEX, GLOBAL_FLAG_REGEX), REPLACE_VALUE);
                request.data = encryptedBody;
            }
            if (encryptedHeaders) {
                request.headers = {
                    "x-headers": encryptedHeaders,
                    ...request.headers
                };
            }
        } else if (encryptionKey && !EXCLUDE_ROUTES.find(route => url.indexOf(route) >= 0) && body instanceof FormData) {
            if (encryptedHeaders) {
                request.headers = {
                    "x-headers": encryptedHeaders,
                    ...request.headers
                };
            }
            request.data = body;
        } else {
            request.headers = {
                ...authHeaders
            };
            request.data = body;
        }
        return request;
    }

    static decryptResponse(url: string, response: AxiosResponse, encryptionKey: string): AxiosResponse {
        const decryptedResponse: AxiosResponse = {
            ...response,
            data: response
        };
        if (response && response.data !== "" && typeof response.data !== "object" && !EXCLUDE_ROUTES.find(route => url.indexOf(route) >= 0) && encryptionKey) {
            if (response.data.includes("Malformed UTF-8 data")) {
                // encryption failed so reloading the page
                window.location.reload();
            }
            let decryptedBody = crypto.AES.decrypt((response.data as string).replace(REGEX, PLUS), encryptionKey).toString(crypto.enc.Utf8);
            decryptedBody = JSON.parse(decryptedBody);
            decryptedResponse.data = decryptedBody;
        }
        return decryptedResponse;
    }
}
