import { toast } from "react-toastify";
import { logError, pushTagEvent } from "./Helper";

class ToastService {
    static lastError = "";
    static success(text: string, duration?: number) {
        toast.success(text, {
            position: "top-center",
            autoClose: duration || 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    static alertableError(text: string, error?: any, duration?: number) {
        if (text && ToastService.lastError === text) {
            ToastService.lastError = "";
            return;
        }
        toast.error(text, {
            position: "top-center",
            autoClose: duration || 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => (ToastService.lastError = "")
        });
        if (text.indexOf("Your session has expired") < 0) logError(text, error);
        ToastService.lastError = text;
    }

    static userError(text: string, duration?: number) {
        toast.error(text, {
            position: "top-center",
            autoClose: duration || 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
        console.warn(text);
        pushTagEvent("userError", { message: text });
    }

    static info(text: string, duration?: number) {
        toast.info(text, {
            position: "top-center",
            autoClose: duration || 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    static warn(text: string, duration?: number) {
        toast.warn(text, {
            position: "top-center",
            autoClose: duration || 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }
}

export default ToastService;
