import { MouseEventHandler, useState, FC } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { TextNode } from "lexical";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import SaveIcon from "@mui/icons-material/Save";
import Tooltip from "@mui/material/Tooltip";
import { debounce } from "lodash";
import ToolbarPlugin from "./plugins/Toolbar";
import HtmlPlugin from "./plugins/Html";
import CodeHighlightPlugin from "./plugins/CodeHighlight";
import UserActivityPlugin from "./plugins/UserActivity";
import AutoLinkPlugin from "./plugins/AutoLink";
import { ContentPastePlugin } from "./plugins/ContentPastePlugin";
import EditorTheme from "./themes/EditorTheme";
import { ExtendedTextNode } from "./CustomNodes/ExtendedTextNode";
import { ExtendedListItemNode } from "./CustomNodes/ExtendedListItemNode";

interface ToolbarProps {
    showTopDivider?: boolean;
    hideStrikeThrough?: boolean;
    hideInlineCode?: boolean;
    hideHyperlink?: boolean;
    hideBlockQuote?: boolean;
    hideBlockCode?: boolean;
    hideBlockOptions?: boolean;
}

interface EditorProps {
    autoFocus?: boolean;
    placeholder?: string;
    defaultValue?: string;
    innerEditorClasses?: string;
    onChange?: Function;
    onUserActivity?: Function;
    onSaveClicked?: MouseEventHandler<HTMLButtonElement>;
    loading?: boolean;
    hideToolbar?: boolean;
    disabled?: boolean;
    toolbarOptions?: ToolbarProps;
    hideFontColorOptions?: boolean;
}

function Placeholder(props: EditorProps) {
    return <div className="editor-placeholder">{props.placeholder || "Enter text here"}</div>;
}

let dialogOpen = false;

const Editor: FC<EditorProps> = (props: EditorProps) => {
    const editorId = `editor-input`;
    const editorConfig: any = {
        theme: EditorTheme,
        onError(error: any) {
            throw error;
        },
        nodes: [
            ExtendedTextNode,
            { replace: TextNode, with: (node: TextNode) => new ExtendedTextNode(node.__text) },
            TextNode,
            HeadingNode,
            ListNode,
            ExtendedListItemNode,
            { replace: ListItemNode, with: (node: ListItemNode) => new ExtendedListItemNode(node.__value, node.__checked, node.__key) },
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode
        ]
    };
    const [userIsEditing, setUserIsEditing] = useState<boolean>(false);

    const handleHtmlChanged = (html: string) => {
        if (props.onChange) props.onChange(html);
    };

    const debounceUserActivity = debounce(() => {
        if (document.activeElement?.id === editorId || dialogOpen) handleUserActivity();
        else setUserIsEditing(false);
    }, 4000);

    const handleUserActivity = () => {
        setUserIsEditing(true);
        debounceUserActivity.cancel();
        debounceUserActivity();
    };

    return (
        <div className="input-editable">
            <LexicalComposer initialConfig={editorConfig}>
                <div className="editor-container">
                    <div className={"editor-inner " + (props.innerEditorClasses || "")}>
                        <RichTextPlugin
                            contentEditable={<ContentEditable id={editorId} className="editor-input" />}
                            placeholder={<Placeholder placeholder={props.placeholder} />}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        <HtmlPlugin disabled={props.disabled} onHtmlChanged={(html: string) => handleHtmlChanged(html)} initialHtml={props.defaultValue} />
                        <HistoryPlugin />
                        {props.autoFocus && <AutoFocusPlugin />}
                        <CodeHighlightPlugin />
                        <ListPlugin />
                        <UserActivityPlugin
                            onUserInteraction={() => {
                                props.onUserActivity && props.onUserActivity();
                                handleUserActivity();
                            }}
                        />
                        <ContentPastePlugin />
                        <LinkPlugin />
                        <AutoLinkPlugin />
                    </div>
                    {!props.hideToolbar && (
                        <div
                            className={"editor-toolbar-container " + ((props.toolbarOptions || {}).showTopDivider ? "top-divider" : "")}
                            style={{ maxHeight: userIsEditing ? "90px" : "0" }}
                        >
                            <ToolbarPlugin
                                loading={props.loading}
                                onVisibilityChanged={(visibility: boolean) => (dialogOpen = visibility)}
                                hideFontColorOptions={props.hideFontColorOptions}
                                disabled={props.disabled}
                                options={props.toolbarOptions}
                            />
                        </div>
                    )}
                </div>
            </LexicalComposer>
            {props.onSaveClicked && (
                <div className="position-absolute" style={{ bottom: "10px", right: "10px" }}>
                    <Tooltip title="Save">
                        <button className="btn btn-link p-0" disabled={props.loading} onClick={props.onSaveClicked}>
                            <SaveIcon />
                        </button>
                    </Tooltip>
                </div>
            )}
        </div>
    );
};

export default Editor;
