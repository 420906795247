import { useEffect, useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, TextField, Tooltip } from "@mui/material";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import FindReplaceRoundedIcon from "@mui/icons-material/FindReplaceRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import CloseIcon from "@mui/icons-material/Close";
import { debounce } from "lodash";

export interface IEditTranscriptAndSummaryProps {
    open: boolean;
    transcript: string;
    summary: string;
    actionItems: string;
    autoSaving?: boolean;
    onSave: (summary: string, transcript: string, actionItems: string) => any;
    onClose: () => any;
}

const EditTranscriptAndSummary = (props: IEditTranscriptAndSummaryProps) => {
    const searchMatchClass = "search-match";
    const currentMatchClass = "current-match";
    const [editingHistory, setEditingHistory] = useState<{ summary: string; transcript: string; actionItems: string; currentMatchIndex: number }[]>([
        { summary: props.summary, transcript: props.transcript, actionItems: props.actionItems, currentMatchIndex: 0 }
    ]);
    const [currentHistoryIndex, setCurrentHistoryIndex] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>("");
    const [replaceText, setReplaceText] = useState<string>("");
    const [aiSummary, setAISummary] = useState<string>(props.summary);
    const [editedAiSummary, setEditedAISummary] = useState<string>(props.summary);
    const [transcriptionText, setTranscriptionText] = useState<string>(props.transcript);
    const [editedTranscriptionText, setEditedTranscriptionText] = useState<string>(props.transcript);
    const [actionItems, setActionItems] = useState<string>(props.actionItems);
    const [editedActionItems, setEditedActionItems] = useState<string>(props.actionItems);
    const [numMatches, setNumMatches] = useState<number>(0);
    const [numAiSummaryMatches, setNumAiSummaryMatches] = useState<number>(0);
    const [numActionItemMatches, setNumActionItemMatches] = useState<number>(0);
    const [currentMatchIndex, setCurrentMatchIndex] = useState<number>(0);
    const [searchOptionsVisible, setSearchOptionsVisible] = useState<boolean>(false);
    const [matchCase, setMatchCase] = useState<boolean>(true);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [replaceWholeWords, setReplaceWholeWords] = useState<boolean>(false);

    const onFocus = () => setIsEditing(true);
    const onBlur = () => {
        setAISummary(editedAiSummary);
        setTranscriptionText(editedTranscriptionText);
        setActionItems(editedActionItems);
        setIsEditing(false);
    };

    const debouncePostUpdate = debounce((newSummary, newTranscript, newActionItems, newMatchIndex) => {
        setEditedAISummary(newSummary);
        setEditedTranscriptionText(newTranscript);
        setEditedActionItems(newActionItems);
        setEditingHistory([
            ...editingHistory.slice(0, currentHistoryIndex + 1),
            { summary: newSummary, transcript: newTranscript, actionItems: newActionItems, currentMatchIndex: newMatchIndex }
        ]);
        setCurrentHistoryIndex(currentHistoryIndex + 1);
        props.onSave && props.onSave(plainTextWithoutLineBreaks(newSummary), plainTextWithoutLineBreaks(newTranscript), plainTextWithoutLineBreaks(newActionItems));
    }, 200);

    useEffect(() => {
        let matchingElements = document.querySelectorAll("." + searchMatchClass);
        matchingElements.forEach(el => el.classList.remove(currentMatchClass));
        if (matchingElements[currentMatchIndex]) {
            matchingElements[currentMatchIndex].classList.add(currentMatchClass);
            matchingElements[currentMatchIndex].scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [currentMatchIndex, numMatches]);

    useEffect(() => {
        setupSearchUi();
    }, [searchText, matchCase, replaceWholeWords, aiSummary, transcriptionText, actionItems]);

    useEffect(() => {
        if (!isEditing) setContentFromHistory();
    }, [currentHistoryIndex]);

    const searchMatchReplacer = (match: string, offset: number, str: string) => {
        return `<span class="${searchMatchClass}">${match}</span>`;
    };

    const aiSummaryHtml = (): string => {
        if (searchText) return aiSummary.replace(new RegExp(searchTextRegex(), searchMatchFlags()), searchMatchReplacer);
        return aiSummary;
    };

    const aiTranscriptHtml = (): string => {
        if (searchText) return transcriptionText.replace(new RegExp(searchTextRegex(), searchMatchFlags()), searchMatchReplacer);
        return transcriptionText;
    };

    const actionItemsHtml = (): string => {
        if (searchText) return actionItems.replace(new RegExp(searchTextRegex(), searchMatchFlags()), searchMatchReplacer);
        return actionItems;
    };

    const onSummaryEdited = (e: React.FormEvent<HTMLDivElement>) => {
        updatePost(plainTextWithLineBreaksPreserved(e.currentTarget.innerHTML) || "", transcriptionText, actionItems);
    };

    const onTranscriptEdited = (e: React.FormEvent<HTMLDivElement>) => {
        updatePost(aiSummary, plainTextWithLineBreaksPreserved(e.currentTarget.innerHTML) || "", actionItems);
    };

    const onActionItemsEdited = (e: React.FormEvent<HTMLDivElement>) => {
        updatePost(aiSummary, transcriptionText, plainTextWithLineBreaksPreserved(e.currentTarget.innerHTML) || "");
    };

    const handleSearchTextChange = (event: any) => {
        setSearchText(event.target.value || "");
    };

    const setupSearchUi = () => {
        let totalMatches = 0;
        if (searchText.length > 0) {
            let aiSummaryMatches = (aiSummary.match(new RegExp(searchTextRegex(), searchMatchFlags())) || []).length;
            setNumAiSummaryMatches(aiSummaryMatches);
            let numActionItemMatches = (actionItems.match(new RegExp(searchTextRegex(), searchMatchFlags())) || []).length;
            setNumActionItemMatches(aiSummaryMatches + numActionItemMatches);
            let numAiTranscriptMatches = (transcriptionText.match(new RegExp(searchTextRegex(), searchMatchFlags())) || []).length;
            setNumMatches(aiSummaryMatches + numAiTranscriptMatches + numActionItemMatches);
            totalMatches = aiSummaryMatches + numAiTranscriptMatches + numActionItemMatches;
        } else setNumMatches(0);
        if (currentMatchIndex >= totalMatches) setCurrentMatchIndex(0);
    };

    const handleReplaceTextChange = (event: any) => {
        setReplaceText(event.target.value);
    };

    const previousMatchClicked = () => {
        setCurrentMatchIndex(currentMatchIndex <= 0 ? numMatches - 1 : currentMatchIndex - 1);
    };

    const nextMatchClicked = () => {
        setCurrentMatchIndex(currentMatchIndex >= numMatches - 1 ? 0 : currentMatchIndex + 1);
    };

    const replaceCurrentMatch = () => {
        let totalAiSummaryMatches = 0;
        let totalActionItemMatches = 0;
        let totalTranscriptMatches = 0;
        let newAiSummary = aiSummary.replace(new RegExp(searchTextRegex(), searchMatchFlags()), match => {
            totalAiSummaryMatches++;
            if (totalAiSummaryMatches === currentMatchIndex + 1) return replaceText;
            return match;
        });
        let newActionItems = actionItems.replace(new RegExp(searchTextRegex(), searchMatchFlags()), match => {
            totalActionItemMatches++;
            if (totalActionItemMatches === currentMatchIndex + 1 - numAiSummaryMatches) return replaceText;
            return match;
        });
        let newTranscript = transcriptionText.replace(new RegExp(searchTextRegex(), searchMatchFlags()), match => {
            totalTranscriptMatches++;
            console.log(totalTranscriptMatches, numActionItemMatches, currentMatchIndex);
            if (totalTranscriptMatches === currentMatchIndex + 1 - numActionItemMatches) return replaceText;
            return match;
        });
        updatePost(newAiSummary, newTranscript, newActionItems);
    };

    const replaceAllMatches = () => {
        let newAiSummary = aiSummary.replace(new RegExp(searchTextRegex(), searchMatchFlags()), replaceText);
        let newTranscript = transcriptionText.replace(new RegExp(searchTextRegex(), searchMatchFlags()), replaceText);
        let newActionItems = actionItems.replace(new RegExp(searchTextRegex(), searchMatchFlags()), replaceText);
        updatePost(newAiSummary, newTranscript, newActionItems);
    };

    const updatePost = (newAiSummary: string, newTranscript: string, newActionItems: string) => {
        debouncePostUpdate.cancel();
        debouncePostUpdate(newAiSummary, newTranscript, newActionItems, currentMatchIndex);
    };

    const undoClicked = () => {
        saveWithNewHistoryIndex(Math.max(0, currentHistoryIndex - 1));
    };

    const redoClicked = () => {
        saveWithNewHistoryIndex(Math.min(editingHistory.length - 1, currentHistoryIndex + 1));
    };

    const saveWithNewHistoryIndex = (newHistoryIndex: number) => {
        let currHistory = editingHistory[newHistoryIndex];
        setCurrentHistoryIndex(newHistoryIndex);
        props.onSave &&
            props.onSave(plainTextWithoutLineBreaks(currHistory.summary), plainTextWithoutLineBreaks(currHistory.transcript), plainTextWithoutLineBreaks(currHistory.actionItems));
    };

    const setContentFromHistory = () => {
        setAISummary(editingHistory[currentHistoryIndex].summary);
        setTranscriptionText(editingHistory[currentHistoryIndex].transcript);
        setActionItems(editingHistory[currentHistoryIndex].actionItems);
        setCurrentMatchIndex(editingHistory[currentHistoryIndex].currentMatchIndex);
    };

    const hideSearchOptions = () => {
        setSearchOptionsVisible(!searchOptionsVisible);
        setReplaceText("");
        setSearchText("");
    };

    const plainTextWithLineBreaksPreserved = (html: string) => {
        let tmp = document.createElement("div");
        tmp.innerHTML = html.split("<br>").join("{br}").split("</br>").join("{br}"); // encode break tag and remove all other html tags
        return tmp.innerText.split("{br}").join("\n");
    };

    const plainTextWithoutLineBreaks = (html: string) => {
        let tmp = document.createElement("div");
        tmp.innerHTML = html.split("<br>").join("{br}").split("</br>").join("{br}"); // encode break tag and remove all other html tags
        return tmp.innerText.split("{br}").join("\n");
    };

    const searchTextRegex = () => {
        return replaceWholeWords ? `\\b${searchText}\\b` : searchText;
    };

    const searchMatchFlags = () => {
        return matchCase ? "g" : "gi";
    };

    return (
        <Dialog
            open={props.open}
            className="more-popup"
            maxWidth={false}
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={() => props.onClose && props.onClose()}
        >
            <DialogTitle className="text-dark w-100 p-4 text-center">
                <span className="text-white h2">Edit AI Summary and Transcript</span>
                <IconButton className="close-icon text-white" aria-label="Close" onClick={() => props.onClose && props.onClose()} style={{ top: "20px" }}>
                    <CloseIcon sx={{ fontSize: 30 }} />
                </IconButton>
            </DialogTitle>
            <Divider className="w-100" />
            <DialogContent>
                <div className="w-100 overflow-auto" style={{ maxHeight: "100%" }}>
                    <h4>AI Summary:</h4>
                    <div
                        className="w-100 pt-2 pb-3 font-light-color white-space-wrap"
                        contentEditable
                        aria-placeholder="Enter summary ..."
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onInput={e => onSummaryEdited(e)}
                        dangerouslySetInnerHTML={{ __html: aiSummaryHtml() }}
                    ></div>
                    <h4>Action Items:</h4>
                    <div
                        className="w-100 pt-2 pb-3 font-light-color white-space-wrap"
                        contentEditable
                        aria-placeholder="Enter action items ..."
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onInput={e => onActionItemsEdited(e)}
                        dangerouslySetInnerHTML={{ __html: actionItemsHtml() }}
                    ></div>
                    <h4>AI Transcript:</h4>
                    <div
                        className="w-100 pt-2 pb-3 font-light-color white-space-wrap"
                        contentEditable
                        aria-placeholder="Enter transcript ..."
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onInput={e => onTranscriptEdited(e)}
                        dangerouslySetInnerHTML={{ __html: aiTranscriptHtml() }}
                    ></div>
                </div>
            </DialogContent>
            <Divider className="w-100" />
            <DialogActions className="no-min-width pb-2">
                <div className="position-relative w-100">
                    {props.autoSaving && (
                        <div className="position-absolute" style={{ top: "15px", left: "25px", fontSize: "0.7rem", background: "black", padding: "4px 8px", borderRadius: "4px" }}>
                            <CircularProgress size="0.7rem" className="me-1" />
                            Autosaving
                        </div>
                    )}
                    <div className="p-2 d-flex justify-content-center">
                        <Tooltip title="Undo change">
                            <IconButton className={"me-3 " + (currentHistoryIndex === 0 ? "disabled" : "")} disabled={currentHistoryIndex === 0} onClick={undoClicked}>
                                <svg
                                    style={{ width: "1.25rem", height: "1.25rem" }}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="toolbar-button-icon"
                                >
                                    <title>Undo</title>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"></path>
                                </svg>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Redo change">
                            <IconButton
                                className={"me-3 " + (currentHistoryIndex >= editingHistory.length - 1 ? "disabled" : "")}
                                disabled={currentHistoryIndex >= editingHistory.length - 1}
                                onClick={redoClicked}
                            >
                                <svg
                                    style={{ width: "1.25rem", height: "1.25rem" }}
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="toolbar-button-icon"
                                >
                                    <title>Redo</title>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3"></path>
                                </svg>
                            </IconButton>
                        </Tooltip>
                        <Divider orientation="vertical" flexItem />
                        <Tooltip title={searchOptionsVisible ? "Hide Find & Replace options" : "Show Find & Replace options"}>
                            <IconButton className="ms-3" onClick={() => setSearchOptionsVisible(!searchOptionsVisible)}>
                                <FindReplaceRoundedIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    {searchOptionsVisible && (
                        <Tooltip title="Hide Find & Replace options">
                            <div className="close-icon text-white" onClick={hideSearchOptions}>
                                <ExpandMoreRoundedIcon sx={{ fontSize: "2rem", marginTop: "5px" }} />
                            </div>
                        </Tooltip>
                    )}
                    <div className="w-100 d-flex overflow-hidden" style={{ height: searchOptionsVisible ? "100%" : 0 }}>
                        <div className="px-5 pt-2 pb-2" style={{ width: "50%", minWidth: "300px" }}>
                            <div className="position-relative">
                                <TextField value={searchText} onChange={handleSearchTextChange} id="find-text" className="w-100" label="Find in text" variant="outlined" />
                                {searchText.length > 0 && numMatches > 0 && (
                                    <div className="position-absolute font-light-color" style={{ right: "20px", top: "15px" }}>
                                        {currentMatchIndex + 1} of {numMatches}
                                    </div>
                                )}
                                {searchText.length > 0 && numMatches === 0 && (
                                    <div className="position-absolute font-light-color" style={{ right: "20px", top: "15px" }}>
                                        No matches found!
                                    </div>
                                )}
                            </div>
                            <div className="d-flex">
                                <Button className="d-flex mt-2 text-white" onClick={() => setMatchCase(!matchCase)}>
                                    <div className="me-2">
                                        {matchCase && <img src="/images/checked.svg" alt="open" style={{ width: "20px" }} />}
                                        {!matchCase && <img src="/images/unchecked.svg" alt="open" style={{ width: "20px" }} />}
                                    </div>
                                    <span>Match case</span>
                                </Button>
                                <div className="ms-auto pt-3">
                                    <Tooltip title={!searchText ? "" : "Previous match"}>
                                        <button
                                            className={"btn btn-outline-light gray-btn me-4 " + (!searchText ? "disabled" : "")}
                                            disabled={!searchText}
                                            onClick={previousMatchClicked}
                                        >
                                            <KeyboardArrowUpRoundedIcon />
                                        </button>
                                    </Tooltip>
                                    <Tooltip title={!searchText ? "" : "Next match"}>
                                        <button className={"btn btn-outline-light gray-btn " + (!searchText ? "disabled" : "")} disabled={!searchText} onClick={nextMatchClicked}>
                                            <KeyboardArrowDownRoundedIcon />
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                            <Button className="d-flex mt-2 text-white" onClick={() => setReplaceWholeWords(!replaceWholeWords)}>
                                <div className="me-2">
                                    {replaceWholeWords && <img src="/images/checked.svg" alt="open" style={{ width: "20px" }} />}
                                    {!replaceWholeWords && <img src="/images/unchecked.svg" alt="open" style={{ width: "20px" }} />}
                                </div>
                                <span>Replace whole words only</span>
                            </Button>
                        </div>
                        <Divider orientation="vertical" flexItem />
                        <div className="px-5 pt-2 pb-2" style={{ width: "50%", minWidth: "300px" }}>
                            <TextField value={replaceText} onChange={handleReplaceTextChange} id="replace-text" className="w-100" label="Replace with" variant="outlined" />
                            <div className="mt-3 d-flex w-100">
                                <Tooltip title={!searchText || !replaceText ? "" : "Replace current match"}>
                                    <button
                                        className={"btn btn-outline-light gray-btn me-4 ms-auto " + (!searchText || !replaceText ? "disabled" : "")}
                                        disabled={!searchText || !replaceText}
                                        onClick={replaceCurrentMatch}
                                    >
                                        Replace
                                    </button>
                                </Tooltip>
                                <Tooltip title={!searchText || !replaceText ? "" : "Replace all matches"}>
                                    <button
                                        className={"btn btn-outline-light gray-btn " + (!searchText || !replaceText ? "disabled" : "")}
                                        disabled={!searchText || !replaceText}
                                        onClick={replaceAllMatches}
                                    >
                                        Replace All
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default EditTranscriptAndSummary;
