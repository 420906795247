import { FC } from "react";

interface MoviIconProps {
    width: string;
}

const MoviIcon: FC<MoviIconProps> = (props: MoviIconProps) => {
    return (
        <svg version="1.1" id="Layer_1" x="0px" y="0px" style={{ width: props.width }} viewBox="527.5 1333 224.3 224.1">
            <g>
                <path
                    className="st0"
                    d="M737.8,1380.3H541.6c-7.8,0-14.1,6.3-14.1,14.1v148.6c0,7.8,6.3,14.1,14.1,14.1h196.1c7.8,0,14.1-6.3,14.1-14.1v-148.6   C751.8,1386.6,745.5,1380.3,737.8,1380.3z M727.8,1524.9c0,4.2-3.4,7.6-7.6,7.6H557.3c-4.2,0-7.6-3.4-7.6-7.6v-112.8   c0-4.2,3.4-7.6,7.6-7.6h162.9c4.2,0,7.6,3.4,7.6,7.6V1524.9z"
                />
                <g id="Eye_L">
                    <ellipse className="st0" cx="595.8" cy="1460" rx="34.9" ry="38" />
                    <ellipse className="st1" cx="610.4" cy="1448.9" rx="8.1" ry="10" />
                </g>
                <g id="Eye_R">
                    <ellipse className="st0" cx="689.3" cy="1460.4" rx="34.9" ry="38" />
                    <ellipse className="st1" cx="673.9" cy="1450.2" rx="8.1" ry="10" />
                </g>
                <path
                    className="st2"
                    d="M667.5,1525.7h-58.3c-1.6,0-2.9-1.3-2.9-2.9v-8.6c0-1.6,1.3-2.9,2.9-2.9h58.3c1.6,0,2.9,1.3,2.9,2.9v8.6   C670.4,1524.4,669.1,1525.7,667.5,1525.7z"
                />
                <rect x="613.8" y="1516.1" className="st3" width="48.4" height="5.5" />
                <g id="Ornimant">
                    <polygon className="st0" points="648.7,1387.6 629.7,1387.6 632.7,1361.4 646.1,1361.4   " />
                </g>
                <g>
                    <g id="buttons">
                        <rect x="584.1" y="1629.6" className="st3" width="31.6" height="28.1" />
                        <rect x="626.4" y="1629.6" className="st3" width="31.6" height="28.1" />
                        <rect x="663.7" y="1629.6" className="st3" width="31.6" height="28.1" />
                    </g>
                    <g id="Panel">
                        <rect x="618.7" y="1574.1" className="st0" width="42" height="42" />
                        <g>
                            <ellipse className="st0" cx="659.8" cy="1595.9" rx="12.8" ry="13.1" />
                        </g>
                    </g>
                </g>
            </g>
            <g id="Mouth_2">
                <g>
                    <path
                        className="st0"
                        d="M681.5,1511.2c-24.9,18.1-58.7,18.1-83.6,0c2.4-3.4,4.9-6.7,7.3-10.1c20.6,14.9,48.4,14.9,69,0    C676.6,1504.5,679.1,1507.8,681.5,1511.2z"
                    />
                </g>
            </g>
            <circle className="st0" cx="639.7" cy="1360.5" r="15.5" />
            <rect className="st0" x="635.5" y="1333" width="9" height="9" />
            <g className="st0">
                <rect x="656.4" y="1344.5" transform="matrix(0.5 0.866 -0.866 0.5 1498.6979 102.142)" width="9" height="9" />
                <rect x="614.5" y="1344.8" transform="matrix(0.5 -0.866 0.866 0.5 -858.9312 1210.6772)" width="9" height="8.8" />
            </g>
            <rect x="528" y="1333" className="st5" width="224" height="224" />
        </svg>
    );
};

export default MoviIcon;
