import { FC, useState } from "react";
import Collapse from "@mui/material/Collapse";

interface ReadMoreProps {
    text: string;
    className?: string;
}

const ReadMore: FC<ReadMoreProps> = ({ text, className }: ReadMoreProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const toggleTextExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <Collapse in={expanded} collapsedSize={24} className={className}>
            <div className={className + (expanded ? "" : " text-truncate ")} onClick={toggleTextExpanded}>
                {text}
            </div>
        </Collapse>
    );
};

export default ReadMore;
