import { FC } from "react";

interface CopyIconProps {
    className?: string;
    style?: Object;
}

const CopyIcon: FC<CopyIconProps> = (props: CopyIconProps) => {
    return (
        <svg className={props.className} style={props.style} width="20" height="23" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="13.8499" height="13.6512" rx="1.5" fill="white" stroke="white" />
            <g filter="url(#filter0_d_3042_392)">
                <mask id="path-2-inside-1_3042_392" fill="white">
                    <path d="M5.1499 6.34882C5.1499 5.24425 6.04533 4.34882 7.1499 4.34882H17.9998C19.1044 4.34882 19.9998 5.24425 19.9998 6.34882V17C19.9998 18.1045 19.1044 19 17.9998 19H7.1499C6.04533 19 5.1499 18.1045 5.1499 17V6.34882Z" />
                </mask>
                <path
                    d="M5.1499 6.34882C5.1499 5.24425 6.04533 4.34882 7.1499 4.34882H17.9998C19.1044 4.34882 19.9998 5.24425 19.9998 6.34882V17C19.9998 18.1045 19.1044 19 17.9998 19H7.1499C6.04533 19 5.1499 18.1045 5.1499 17V6.34882Z"
                    fill="white"
                />
                <path
                    d="M4.1499 6.34882C4.1499 4.69196 5.49305 3.34882 7.1499 3.34882H16.9998C18.6567 3.34882 19.9998 4.69196 19.9998 6.34882C19.9998 5.79653 19.1044 5.34882 17.9998 5.34882H7.1499C6.59762 5.34882 6.1499 5.79653 6.1499 6.34882H4.1499ZM19.9998 19H5.1499H19.9998ZM7.1499 19C5.49305 19 4.1499 17.6568 4.1499 16V6.34882C4.1499 4.69196 5.49305 3.34882 7.1499 3.34882V5.34882C6.59762 5.34882 6.1499 5.79653 6.1499 6.34882V17C6.1499 18.1045 6.59762 19 7.1499 19ZM19.9998 4.34882V19V4.34882Z"
                    fill="#666666"
                    mask="url(#path-2-inside-1_3042_392)"
                />
            </g>
            <defs>
                <filter id="filter0_d_3042_392" x="1.1499" y="4.34882" width="22.8501" height="22.6511" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3042_392" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3042_392" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

export default CopyIcon;
