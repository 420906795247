const loadStylesheet = url => {
    var link = document.createElement("link");
    link.setAttribute("rel", "stylesheet");
    link.setAttribute("type", "text/css");
    link.setAttribute("href", url);
    document.head.appendChild(link);
};

export const loadFonts = () => {
    loadStylesheet("https://fonts.cdnfonts.com/css/gotham-extra-light");
    loadStylesheet("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");
};

export const exitPlayerFullScreen = player => {
    if (player && player.isFullscreen()) player.exitFullscreen();
};
