import { useLayoutEffect } from "react";
import { mergeRegister } from "@lexical/utils";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { CLICK_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND, KEY_DOWN_COMMAND, DRAGOVER_COMMAND } from "lexical";

interface UserActivityPluginProps {
    onUserInteraction: () => void;
}

const UserActivityPlugin = ({ onUserInteraction }: UserActivityPluginProps) => {
    const [editor] = useLexicalComposerContext();

    useLayoutEffect(() => {
        const commandListener = () => {
            onUserInteraction();
            return true;
        };
        return mergeRegister(
            editor.registerCommand(FOCUS_COMMAND, commandListener, COMMAND_PRIORITY_LOW),
            editor.registerCommand(CLICK_COMMAND, commandListener, COMMAND_PRIORITY_LOW),
            editor.registerCommand(KEY_DOWN_COMMAND, commandListener, COMMAND_PRIORITY_LOW),
            editor.registerCommand(DRAGOVER_COMMAND, commandListener, COMMAND_PRIORITY_LOW)
        ); // register commands that should keep the toolbar visible
    }, [editor, onUserInteraction]);

    return null;
};

export default UserActivityPlugin;
