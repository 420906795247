import { Dialog, DialogContent } from "@mui/material";
import { FC } from "react";

interface PopupProps {
    isOpen: boolean;
    children: React.ReactNode;
    onClose: any;
    className?: string;
    disableBackdropDismiss?: boolean;
}

const Popup: FC<PopupProps> = (props: PopupProps) => {
    return (
        <div className="popup">
            <Dialog
                className={props.className}
                open={props.isOpen}
                maxWidth={false}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClose={(event, reason) => {
                    if (props.disableBackdropDismiss && ["backdropClick", "escapeKeyDown"].includes(reason)) {
                        // do nothing
                    } else props.onClose && props.onClose();
                }}
            >
                <DialogContent>{props.children}</DialogContent>
            </Dialog>
        </div>
    );
};

export default Popup;
