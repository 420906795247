import React from "react";
import videojs from "video.js";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import Player from "./Player";
import { Provider } from "../Common/Provider";
import { exitPlayerFullScreen } from "../Common/Helper";
import ResizableDialogVideoPlayer from "./ResizableDialogVideoPlayer";
import CustomFullScreenToggle from "./CustomFullScreenToggle";
import PlaybackRateMenuButtonDisableTap from "./PlaybackRateButtonTabDisable";

videojs.registerComponent("PlaybackRateMenuButtonDisableTap", PlaybackRateMenuButtonDisableTap);
videojs.registerComponent("CustomFullScreenToggle", CustomFullScreenToggle);

class VideoPlayer extends React.Component {
    state = {
        playerRef: React.createRef(),
        reloadVideo: false,
        showVideoResizeableDialog: false,
        dialogPlaybackStartTime: 0,
        reloadThumbnail: false
    };
    constructor(props) {
        super(props);
        this.isHEVCCodec = props.isHEVCCodec;
        this.disableJumpBtns = props.disablejumpbtns === "true";
        this.onSetVideoDimensions = props.onSetVideoDimensions;
        this.provider = props.provider || Provider.AWS;
        this.videoPlayIndex = 0;
        this.videoJsOptions = {
            bigPlayButton: !props.showCustomControls,
            fluid: props.fluid,
            responsive: props.responsive,
            fill: props.fill,
            controls: true,
            preload: "auto",
            poster: props.thumbnail,
            src: props.hlsUrl || props.url,
            html5: {
                nativeTextTracks: false,
                nativeAudioTracks: false,
                nativeVideoTracks: false,
                vhs: {
                    overrideNative: true
                }
            },
            controlBar: {
                children: props.showCustomControls
                    ? [
                          isMobile ? "" : "PlayToggle",
                          "CurrentTimeDisplay",
                          "ProgressControl",
                          "PlaybackRateMenuButtonDisableTap",
                          props.hideFullscreenOption ? "" : props.customFullscreenButton ? "CustomFullScreenToggle" : "FullscreenToggle"
                      ]
                    : [
                          "PlayToggle",
                          "CurrentTimeDisplay",
                          "TimeDivider",
                          "DurationDisplay",
                          "ProgressControl",
                          "VolumePanel",
                          props.hideFullscreenOption ? "" : props.customFullscreenButton ? "CustomFullScreenToggle" : "FullscreenToggle"
                      ]
            },
            liveui: true,
            playbackRates: [1, 1.5, 2],
            errorDisplay: false,
            disableJumpBtns: this.disableJumpBtns || props.showCustomControls,
            autoplay: props.autoplay
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.thumbnail !== this.props.thumbnail) {
            this.videoJsOptions.poster = this.props.thumbnail;
            this.setState({ reloadThumbnail: true });
        }
        // prevent (new blob urls) or (aws_signed_urls for same key but different signature params) from reloading videoPlayer
        if (this.props.url.indexOf("blob:") !== 0 && prevProps.url.split("?")[0] !== this.props.url.split("?")[0]) {
            this.videoJsOptions.src = this.props.hlsUrl || this.props.url;
            this.setState({ reloadVideo: true });
        }
    }

    componentWillUnmount() {
        if (this.state.playerRef.current) {
            this.state.playerRef.current.dispose();
        }
    }

    resetReload = () => {
        this.setState({ reloadThumbnail: false, reloadVideo: false });
    };

    fallbackToNormalVideo = player => {
        const playerRef = player;
        playerRef.current = player;
        playerRef.current.src([
            {
                src: this.props.url,
                type: "video/mp4"
            }
        ]);
        this.setState({ playerRef });
    };

    handlePlayerReady = player => {
        const playerRef = player;
        playerRef.current = player;
        playerRef.current.src([
            {
                src: this.props.hlsUrl || this.props.url,
                type: this.props.hlsUrl ? "application/vnd.apple.mpegurl" : "video/mp4",
                withCredentials: true
            }
        ]);
        this.setState({ playerRef });
    };

    handlePlayNext = player => {
        const playerRef = player;
        playerRef.current = player;
        if (this.videoPlayIndex < this.videoUrls.length - 1) {
            this.videoPlayIndex++;
            playerRef.current.src([
                {
                    src: this.props.hlsUrl || this.props.url,
                    type: this.props.hlsUrl ? "application/vnd.apple.mpegurl" : "video/mp4",
                    withCredentials: true
                }
            ]);
            playerRef.current.play();
        } else if (this.props.fullscreenOnStart) {
            exitPlayerFullScreen(player); // no more videos left to play so exit full screen if option enabled
        }
    };

    handlePlayerDataLoaded = () => {
        this.props.onReady && this.props.onReady();
    };

    onPlaybackStarted = () => {
        this.props.onPlaybackStarted && this.props.onPlaybackStarted();
    };

    onPlaybackEnded = () => {
        this.props.onPlaybackEnded && this.props.onPlaybackEnded();
    };

    onCustomFullscreenToggle = currPlayerTime => {
        this.setState({ dialogPlaybackStartTime: currPlayerTime, showVideoResizeableDialog: true });
    };

    render() {
        return (
            <div className="w-100">
                <Player
                    draftTrackingData={this.props.draftTrackingData}
                    height={this.props.height}
                    maxHeight={this.props.maxHeight}
                    options={this.videoJsOptions}
                    isHEVCCodec={this.props.isHEVCCodec}
                    onReady={this.handlePlayerReady}
                    onLoadedData={this.handlePlayerDataLoaded}
                    mode={this.props.mode}
                    showTransparentPlayPause={this.props.showTransparentPlayPause}
                    fullscreenOnStart={this.props.fullscreenOnStart}
                    hideMarketingMessage={this.props.hideMarketingMessage}
                    playMultiple={this.props.playMultiple}
                    playNext={this.handlePlayNext}
                    onSetVideoDimensions={this.onSetVideoDimensions}
                    onPlaybackStarted={this.onPlaybackStarted}
                    onPlaybackEnded={this.onPlaybackEnded}
                    reloadThumbnail={this.state.reloadThumbnail}
                    reloadVideo={this.state.reloadVideo}
                    resetReload={this.resetReload}
                    controlBarBottomMargin={this.props.controlBarBottomMargin}
                    showCustomControls={this.props.showCustomControls}
                    hlsUrl={this.props.hlsUrl}
                    fallbackToNormalVideo={this.fallbackToNormalVideo}
                    onPreviousVideo={this.props.onPreviousVideo}
                    onNextVideo={this.props.onNextVideo}
                    onTimeUpdate={this.props.onTimeUpdate}
                    disableSkipBtns={this.props.disableSkipBtns}
                    authenticityType={this.props.authenticityType}
                    playbackStartTime={this.props.playbackStartTime}
                    playbackProgressPercent={this.props.playbackProgressPercent}
                    onCustomFullscreenToggle={this.onCustomFullscreenToggle}
                    showTryNow={this.props.showTryNow}
                    setVideoData={this.props.setVideoData}
                    uuid={this.props.uuid}
                />
                <ResizableDialogVideoPlayer
                    open={this.state.showVideoResizeableDialog}
                    videoUrl={this.props.url}
                    hlsUrl={this.props.hlsUrl}
                    thumbnail={this.props.thumbnail}
                    reloadThumbnail={this.state.reloadThumbnail}
                    playbackStartTime={this.state.dialogPlaybackStartTime || this.props.playbackStartTime}
                    authenticityType={this.props.authenticityType}
                    onPreviousVideo={this.props.onPreviousVideo}
                    onNextVideo={this.props.onNextVideo}
                    onPlaybackStarted={this.onPlaybackStarted}
                    onTimeUpdate={this.props.onTimeUpdate}
                    showTryNow={this.props.showTryNow}
                    onCustomFullscreenToggle={this.props.onCustomFullscreenToggle}
                    initialWidth={this.props.initialPlayerDialogWidth || (isDesktop || isTablet ? 600 : 400)}
                    uuid={this.props.uuid + "-dialog"}
                    onClose={() => this.setState({ showVideoResizeableDialog: false })}
                />
            </div>
        );
    }
}

export default VideoPlayer;
