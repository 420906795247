import { FC, useEffect, useState } from "react";
import { isAndroid } from "react-device-detect";
import { Box, Dialog, DialogTitle, IconButton, LinearProgress } from "@mui/material";
import { PostStatus } from "@emovid/payloads-library";
import { Close } from "@mui/icons-material";
import copy from "copy-to-clipboard";
import ToastService from "../../Common/ToastService";
import { Paths, replaceEventIdInPath } from "../../Common/AppRoutes";
import HttpService from "../../Services/HttpService";
import { getHTMLForSharing, postShared, pushTagEvent, updatePostById } from "../../Common/Helper";
import ShareInfo from "../CreateEmovid/ShareInfo";
import ShareIconsStrip from "../CreateEmovid/ShareIconsStrip";

export interface CopyOptionProps {
    id: string;
    title: string;
    icon: string;
    copy_message: string;
    show_option: boolean;
    copy_for_email: boolean;
}
export const SHARE_OPTIONS: CopyOptionProps[] = [
    { id: "email", title: "Copy Link to Share via Email", icon: "/icons/email.svg", copy_message: "Copied Link for Email", show_option: !isAndroid, copy_for_email: true },
    { id: "link", title: "Copy link (works for most messaging apps)", icon: "/icons/link.svg", copy_message: "Copied Link", show_option: true, copy_for_email: false },
    { id: "slack", title: "Copy Link to Share via Slack", icon: "/icons/slack.svg", copy_message: "Copied Link for Slack", show_option: true, copy_for_email: false },
    { id: "linkedin", title: "Copy Link to Share via LinkedIn", icon: "/icons/linkedin.svg", copy_message: "Copied Link for LinkedIn", show_option: true, copy_for_email: false },
    {
        id: "twitter",
        title: "Copy Link to Share via X (Twitter)",
        icon: "/icons/twitter.svg",
        copy_message: "Copied Link for X (Twitter)",
        show_option: true,
        copy_for_email: false
    },
    { id: "facebook", title: "Copy Link to Share via Facebook", icon: "/icons/facebook.svg", copy_message: "Copied Link for Facebook", show_option: true, copy_for_email: false }
];
interface ShareEventDialogProps {
    show: boolean;
    title?: string;
    emovidId?: string;
    postId?: string;
    userId?: string;
    creatorName?: string;
    emailThumbnailUrl?: string;
    gifUrl?: string;
    duration?: number;
    isSecureEvent?: boolean;
    onCopy?: Function;
    updateStatusOnCopy?: boolean;
    onCancelClick: Function;
    aiSummary?: string;
    notes?: string;
}

const ShareEventDialog: FC<ShareEventDialogProps> = ({
    emovidId,
    postId,
    userId,
    show,
    isSecureEvent,
    creatorName,
    gifUrl,
    emailThumbnailUrl,
    duration,
    onCancelClick,
    updateStatusOnCopy,
    onCopy,
    aiSummary,
    notes
}: ShareEventDialogProps) => {
    const [copyInProgress, setCopyInProgress] = useState<boolean>(false);
    const [eventUrl, setEventUrl] = useState<string>("");

    useEffect(() => {
        const url = emovidViewPath();
        if (url && show) generateShortURL(url);
    }, [emovidId, postId, show]);

    const emovidViewPath = (queryParams: any = {}): string => {
        if (postId) queryParams["show_post"] = postId;
        if (emovidId) return `${window.location.origin}${replaceEventIdInPath(Paths.viewEmovid, emovidId)}?${new URLSearchParams(queryParams).toString()}`;
        return "";
    };

    const generateShortURL = async (url: string) => {
        try {
            const shortUrlResponse = await HttpService.postFormData(`/v1/post/short/url`, { url }, false, true);
            setEventUrl(shortUrlResponse.short_url);
        } catch (error) {
            console.error("Error generating short url: ", error);
            setEventUrl(emovidViewPath());
        }
    };

    const copyHTML = async () => {
        setCopyInProgress(true);
        if (emailThumbnailUrl) {
            try {
                window.ClipboardItem
                    ? await navigator.clipboard.write([new ClipboardItem({ "text/html": getHTML(gifUrl || emailThumbnailUrl) })])
                    : copy((await getHTML(gifUrl || emailThumbnailUrl)) as string, { format: "text/html" });
                if (postId && !!updateStatusOnCopy) await updatePostById(postId, { post_status: PostStatus.DRAFT_SHARABLE_LINK });
                onCopy && onCopy();
                if (postId && userId) postShared(userId, postId, duration || 0, "email");
                ToastService.success("Copied for Email!");
            } catch (error) {
                console.log("Error: ", error);
            }
            shareLinkCopyEvent(true, SHARE_OPTIONS[0].id);
        } else copyText(SHARE_OPTIONS[1]);
        setCopyInProgress(false);
    };

    const copyText = async (option: CopyOptionProps) => {
        setCopyInProgress(true);
        try {
            window.ClipboardItem
                ? await navigator.clipboard.write([new ClipboardItem({ "text/plain": new Blob([`${eventUrl}`], { type: "text/plain" }) })])
                : copy(eventUrl, { format: "text/plain" });
            if (postId && !!updateStatusOnCopy) await updatePostById(postId, { post_status: PostStatus.DRAFT_SHARABLE_LINK });
            if (!!updateStatusOnCopy) onCopy && onCopy();
            if (postId && userId) postShared(userId, postId, duration || 0, option.id);
            ToastService.success(option.copy_message);
        } catch (error) {
            console.log("Error: ", error);
        }
        setCopyInProgress(false);
        try {
            shareLinkCopyEvent(false, option.id);
        } catch (error) {
            console.error("Failed to track share link copy event:", error);
        }
    };

    const shareLinkCopyEvent = (asHtml: boolean, linkType: string) => {
        pushTagEvent("e_share_link_copied", {
            event_id: emovidId,
            post_id: postId,
            link_type: linkType,
            as_html: asHtml
        });
    };

    const getHTML = async (url: any) => {
        const html = await getHTMLForSharing(
            url,
            isSecureEvent || false,
            eventUrl,
            emovidViewPath({ scroll_to: "transcript" }),
            `${duration || 0}`,
            aiSummary || "",
            creatorName || "",
            notes ? emovidViewPath({ scroll_to: "notes" }) : ""
        );
        return window.ClipboardItem ? new Blob([html], { type: "text/html" }) : html;
    };

    return (
        <Dialog className="dialog-m-2" onClose={() => onCancelClick()} open={show} fullWidth>
            <div className="created-emovids-video-dialog ps-0 pe-0">
                <DialogTitle className="w-100 text-center">
                    Copy Shareable Links <ShareInfo />
                </DialogTitle>
                <Box position="absolute" top={5} right={5}>
                    <IconButton onClick={() => onCancelClick()}>
                        <Close />
                    </IconButton>
                </Box>
                <div className="w-100">
                    <div className={"w-100 " + (copyInProgress ? "" : "opacity-0")}>
                        <LinearProgress />
                    </div>
                    <ShareIconsStrip transcriptAvailable={true} disabled={copyInProgress} onCopyHTML={copyHTML} onCopyText={copyText} maxWidth="100%" />
                    {eventUrl && (
                        <div className="text-truncate text-center p-2 m-auto label" style={{ maxWidth: "300px" }}>
                            {eventUrl}
                        </div>
                    )}
                </div>
            </div>
        </Dialog>
    );
};

export default ShareEventDialog;
