export const GENERATE_THUMBNAIL_API_URL = "/extract-thumbnails";
export const MOV_CONVERSION_API_URL = "/v1/convert/video";
export const SIGNED_URL = "/v1/generate/sign/url";
export const GENERATE_THUMBNAIL_URL = "/v1/thumbnail/generate";
export const GENERATE_SIGN_INFO = "/v1/generate/sign/info";
export const VIDEO_DELETE_API_URL = "/v1/video";
export const VIDEO_THETA_TRANSCODE_URL = "/v1/theta/transcode/video";
export const INITIATE_MULTIPART_VIDEO_UPLOAD = "/v1/video/upload/initiate";
export const GENERATE_MULTIPART_UPLOAD_SIGNED_URL = "/v1/video/generate/urls";
export const COMPLETE_MULTIPART_VIDEO_UPLOAD = "/v1/video/upload/complete";
export const ABORT_MULTIPART_VIDEO_UPLOAD = "/v1/video/upload/abort";
export const VIDEO_CHUNK_UPLOAD = "/v1/video/chunk/upload";
export const VIDEO_CHUNK_COMBINE = "/v1/video/chunk/combine";
export const AWS_BUCKET_PUBLIC = "https://vidinvite-public.s3.us-west-1.amazonaws.com/";
export const CONVERT_THETA_VIDEOS_API_URL = "/convert-theta-videos";
export const GENERATE_THUMBNAIL_PROVIDER_API_URL = "/extract-thumbnails-with-provider";
export const GET_THETA_VIDEO_URL = "/v1/theta/video";
export const CLOUDFRONT_URL = "https://cdn.emovid.com/";
export const RECORDING_DURATION_WARNING_MESSAGE = "Recording duration is limited to 2 minutes!";
export const MAX_RECORD_DURATION_MINS = 2;
export const PROCESSED_LABEL = "We got your video.";
export const PROCESSING_PERSONA_WAITING_TEXT_BLURBS = [
    "On your mark ...",
    "Ready for the wild ride ...",
    "You didn't know you could record in your Pajamas, did you? ",
    "Now, you can send videos wherever you are ...",
    "Messy hair today? We've got you covered.",
    "You're going to look amazing.",
    "Forgot your hair color? We've got you colored.",
    "Best use of AI ever.",
    "We call this magic: Pajama Mode.",
    "Are you ready to see some magic?",
    "Thanks so much for waiting.",
    "Your patience is about to pay off."
];
export const NO_AUDIO_DETECT_TIME_IN_SEC = 5;
export const COMMON_VIDEO_BACKGROUNDS = [
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/animated_1_landscape.mp4",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/animated_2_landscape.mp4",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/animated_3_landscape.mp4"
];
export const COMMON_IMAGE_BACKGROUNDS = [
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/image_background_1.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/image_background_2.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/image_background_3.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/image_background_4.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/image_background_5.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/image_background_6.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/image_background_6.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/image_background_8.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/image_background_9.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/image_background_10.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/image_background_11.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/image_background_12.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_1.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_2.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_3.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_4.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_5.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_6.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_7.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_8.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_9.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_10.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_11.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_12.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_13.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_14.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_15.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_16.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_17.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_18.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_19.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_20.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_21.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_22.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_23.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_24.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_25.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_26.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_27.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_28.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_29.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_30.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_31.jpg",
    "https://d109vobnb8vsqr.cloudfront.net/common-background-images/ai_32.jpg"
];
export const BREAKPOINT_COLUMNS = {
    default: 3,
    1000: 3,
    700: 2,
    500: 1
};
