import { FC } from "react";
import { Skeleton } from "@mui/material";
import { isMobile } from "react-device-detect";

interface ViewEmovidSkeletonProps {}

const ViewEmovidSkeleton: FC<ViewEmovidSkeletonProps> = (props: ViewEmovidSkeletonProps) => {
    return (
        <>
            {!isMobile && (
                <div className="container mt-4 pt-3 d-flex">
                    <div className="w-100 pe-2 d-flex justify-content-between">
                        <Skeleton variant="rounded" height={40} width={600} animation="wave" />
                        <Skeleton variant="circular" height={40} width={40} animation="wave" />
                    </div>
                    <div className="d-flex ps-4" style={{ width: "220px", minWidth: "220px" }}>
                        <Skeleton variant="circular" height={40} width={40} animation="wave" />
                        <Skeleton className="ms-3" variant="circular" height={40} width={40} animation="wave" />
                    </div>
                </div>
            )}
            {!isMobile && (
                <div className="container mt-4 d-flex">
                    <div className="w-100 pe-2">
                        <Skeleton variant="rounded" height={350} width="100%" animation="wave" />
                        <div className="w-100 pe-2 mt-3 d-flex justify-content-between">
                            <Skeleton variant="rounded" height={40} width={600} animation="wave" />
                            <Skeleton variant="rounded" height={40} width={80} animation="wave" />
                        </div>
                    </div>
                    <div className="ps-4" style={{ width: "220px", minWidth: "220px" }}>
                        <Skeleton variant="rounded" height={100} width="100%" animation="wave" />
                        <Skeleton className="mt-3" variant="rounded" height={100} width="100%" animation="wave" />
                        <Skeleton className="mt-3" variant="rounded" height={100} width="100%" animation="wave" />
                    </div>
                </div>
            )}
            {isMobile && (
                <div className="container mt-4">
                    <Skeleton variant="rounded" height={40} width="100%" animation="wave" />
                    <Skeleton className="mt-3" variant="rounded" height={350} width="100%" animation="wave" />
                </div>
            )}
            {isMobile && (
                <div className="fixed-bottom p-3 pe-2 ps-2">
                    <div className="d-flex justify-content-around">
                        <Skeleton variant="rounded" height={40} width={80} animation="wave" />
                        <Skeleton variant="rounded" height={40} width={80} animation="wave" />
                    </div>
                </div>
            )}
        </>
    );
};

export default ViewEmovidSkeleton;
