import { FC, useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { PermissionType } from "@emovid/payloads-library";
import { displayNameForUser } from "../../Util/UserInfoMap";
import { IPostResponseAttributes } from "../../Interfaces/IPostResponseAttributes";
import { PostsListProps } from "./IPostsListProps";
import ParentResponsesList from "./ParentResponsesList";

export const PostsList: FC<PostsListProps> = ({ eventPosts, isCreatorLoggedIn, selectedPost, onPostSelected, parentPostId, hideCreatorLabel }: PostsListProps) => {
    useEffect(() => {
        let selectedPostElement = document.getElementById(selectedPost.id || "");
        if (selectedPostElement) selectedPostElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, [selectedPost]);

    return (
        <>
            {(eventPosts || []).map((post: IPostResponseAttributes, i: number) => {
                return (
                    <div
                        key={i}
                        className={"video-preview" + (post.is_creator ? "" : " mt-3 ") + (selectedPost && post.id === selectedPost.id ? " selected " : "")}
                        id={post.id}
                        onClick={() => onPostSelected(post)}
                    >
                        <div
                            className={"view-page-video-thumbnail" + (!post.thumbnail_url && !post.email_thumbnail_url ? " no-image " : "")}
                            style={{ backgroundImage: `url(${post.thumbnail_url || post.email_thumbnail_url || "/images/email-play-overlay.png"})` }}
                        ></div>
                        {isCreatorLoggedIn && post.permission_type === PermissionType.PRIVATE && (
                            <div className="position-absolute private-video">
                                <i className="fa fa-eye"></i>&nbsp; Private Reply
                            </div>
                        )}
                        <div className="sender-label position-absolute">
                            {displayNameForUser(post.Creator!)}
                            {post.is_creator && !parentPostId && !hideCreatorLabel ? " (Creator) " : ""}
                        </div>
                        <div className="video-viewed-progress-container" style={{ bottom: "-3px" }}>
                            <div className="video-viewed-progress-bar" style={{ width: `${post.viewed_percent || 0}%` }}></div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

const ResponsesList: FC<PostsListProps> = ({ event, isVisible, eventPosts, selectedPost, onPostSelected, isCreatorLoggedIn, parentPostId, isPreviewMode }: PostsListProps) => {
    const [creatorPosts, setCreatorPosts] = useState<IPostResponseAttributes[]>([]);
    const [recipientPosts, setRecipientPosts] = useState<IPostResponseAttributes[]>([]);

    useEffect(() => {
        let creatorPostsArr: IPostResponseAttributes[] = [];
        let recipientPostsArr: IPostResponseAttributes[] = [];
        eventPosts.forEach((post: IPostResponseAttributes) => {
            if (isPreviewMode) {
                if (post.id === selectedPost.id) recipientPostsArr.push(post);
            } else {
                if (post.is_creator) creatorPostsArr.push(post);
                else recipientPostsArr.push(post);
            }
        });
        setCreatorPosts(creatorPostsArr);
        setRecipientPosts(recipientPostsArr);
    }, [eventPosts]);

    return (
        <div className="response-previews-container ps-2 pe-3" style={isVisible ? {} : { display: "none" }}>
            {parentPostId && !isPreviewMode && (
                <ParentResponsesList event={event} parentPostId={parentPostId} selectedPost={selectedPost} onPostSelected={onPostSelected} isCreatorLoggedIn={isCreatorLoggedIn} />
            )}
            <PostsList
                event={event}
                parentPostId={parentPostId}
                eventPosts={creatorPosts}
                selectedPost={selectedPost}
                onPostSelected={onPostSelected}
                isCreatorLoggedIn={isCreatorLoggedIn}
            />
            {!parentPostId && !isPreviewMode && <Divider className="mt-3 text-white">Responses</Divider>}
            <PostsList
                event={event}
                parentPostId={parentPostId}
                eventPosts={recipientPosts}
                selectedPost={selectedPost}
                onPostSelected={onPostSelected}
                isCreatorLoggedIn={isCreatorLoggedIn}
            />
            {recipientPosts.length < 1 && !parentPostId && <div className="p-3 text-center">No responses yet!</div>}
        </div>
    );
};

export default ResponsesList;
